import React from 'react';
import PageHeader from 'view/components/PageHeader';
import { StyledContainer } from './styled';
import Typography from '@mui/material/Typography';
import useUser from 'hooks/user';
import { black20, primary } from 'view/theme/colors';
import Box from '@mui/material/Box';
import { listWithdrawals } from 'services/api/withdrawals';
import WithdrawalsList from 'view/pages/Balance/components/WithdrawalsList';
import Grid from '@mui/material/Grid';
import { formatCurrency } from 'tools';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import Accordion from 'view/pages/AdminUsers/components/Accordion';
import CardWithdrawForm from 'view/pages/Balance/components/CardWithdrawForm';
import IBANWithdrawForm from 'view/pages/Balance/components/IBANWithdrawForm';
import WhitepayWithdrawForm from 'view/pages/Balance/components/WhitepayWithdrawForm';
import { Currency, PaymentMethodsCurrencyMap } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';

export default function BalancePage() {
  const { setErrorMessage } = useAuth();
  const { user, withdrawalsData, setWithdrawalsData } = useUser();
  const { t } = useTranslation();

  const fetchNextWithdrawals = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const withdrawalsData = await listWithdrawals(nextLink);
        setWithdrawalsData(withdrawalsData);
      } catch (err) {
        setErrorMessage(t('Error while loading more withdrawals'));
      }
    }
  };

  if (Object.keys(user).length === 0) {
    return null;
  }

  return (
    <StyledContainer component="main">
      <PageHeader title={t('Balance')} />
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          <Box display="flex" flexDirection="column" gap={2} mb={2}>
            {Object.keys(user.balance).map((key, index) => {
              const paymentMethod = key as PaymentMethodTypes;

              const getChildren = () => {
                if (paymentMethod === PaymentMethodTypes.WHITEPAY) {
                  return <WhitepayWithdrawForm />;
                }
                return <CardWithdrawForm />;
              };

              return (
                <Accordion
                  key={key}
                  defaultExpanded={index === 0}
                  disableGutters={true}
                  square={true}
                  summary={
                    <Typography sx={{ fontSize: 36, lineHeight: 1.5 }}>
                      {PAYMENT_METHOD_MEDIA_MAP[paymentMethod].shortLabel}
                      <Typography
                        component="span"
                        sx={{ fontSize: 36, color: primary }}
                        ml={1}
                      >
                        {formatCurrency(
                          user.balance[paymentMethod],
                          PaymentMethodsCurrencyMap[paymentMethod],
                        )}
                      </Typography>
                    </Typography>
                  }
                  children={getChildren()}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h2" mb={4}>
            {t('Recent withdrawal operations')}
          </Typography>
          <Box>
            <WithdrawalsList
              withdrawals={withdrawalsData?.items}
              fetchNextWithdrawals={() =>
                fetchNextWithdrawals(withdrawalsData?.links?.next)
              }
              allWithdrawalsLoaded={!!withdrawalsData?.links?.next}
            />
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
