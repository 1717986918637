import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import {
  BaseSelectProps,
  FilledSelectProps,
  SxProps,
  Theme,
} from '@mui/material';

export const StyledInput = styled(TextField)`
  & .MuiOutlinedInput-root,
  & .MuiInputBase-root,
  & .MuiInputLabel-root {
    font-size: 0.875rem;
    font-weight: 500;
  }

  & .MuiOutlinedInput-root {
    border-radius: 0.5rem;
  }

  & input[type='number']::-webkit-outer-spin-button,
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type='number'] {
    -moz-appearance: textfield;
  }
`;

type FormInputProps = {
  id: string;
  label?: string;
  placeholder?: string;
  value?: string | ReactNode;
  error?: boolean;
  select?: boolean;
  inputProps?: object;
  helperText?: React.ReactElement | string;
  variant?: 'filled' | 'outlined' | 'standard';
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  disabled?: boolean;
  children?: React.ReactElement | ReactNode;
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  SelectProps?: Partial<FilledSelectProps & BaseSelectProps>;
  inputRef?: React.Ref<any>;
  size?: 'small' | 'medium';
  sx?: SxProps<Theme>;
};

export default function Input({
  variant = 'outlined',
  inputProps,
  label,
  id,
  value,
  error,
  helperText,
  placeholder,
  type,
  disabled,
  fullWidth = true,
  startAdornment,
  endAdornment,
  onChange,
  inputRef,
  children,
  size,
  select = false,
  sx = {},
  SelectProps,
  ...props
}: FormInputProps) {
  return (
    <StyledInput
      id={id}
      name={id}
      type={type}
      variant={variant}
      inputProps={inputProps}
      margin="none"
      select={select}
      error={error}
      helperText={helperText}
      fullWidth={fullWidth}
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      value={value}
      onChange={onChange}
      inputRef={inputRef}
      SelectProps={SelectProps}
      size={size}
      sx={{
        ...sx,
        // '& .MuiInputBase-root': { height: small ? '3rem' : '3.5rem' },
      }}
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : undefined,
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : undefined,
      }}
      {...props}
    >
      {children}
    </StyledInput>
  );
}
