import React from 'react';
import { StyledContainer } from 'view/pages/Home/styled';
import PageHeader from 'view/components/PageHeader';
import Link from 'view/components/Link';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import CopyButton from 'view/components/CopyButton';
import Box from '@mui/material/Box';

export default function ContactsPage() {
  const { t } = useTranslation();

  return (
    <StyledContainer component="main">
      <PageHeader title={t('Contacts')} />
      <Typography variant="body1" mb={2}>
        ФОП Богуцький Андрій Ігорович.
      </Typography>

      <Typography variant="body1" mb={2}>
        ІПН: 3527516237.
      </Typography>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        gap={1}
        mb={2}
      >
        <Typography>Р/р отримувача: UA463220010000026008340144236</Typography>
        <CopyButton value="UA463220010000026008340144236" />
      </Box>

      <Typography variant="body1" mb={2}>
        Адреса: 33024, Україна, Рівненська область, Рівненський район, місто
        Рівне, вул. Олександра Храпаченка, будинок 18.
      </Typography>

      <Typography variant="body1" mb={2}>
        Телефон: +38 (098) 485 54 03
      </Typography>

      <Typography variant="body1" mb={2}>
        Електронна пошта:{' '}
        <Link to="mailto:2donate.me@gmail.com" label="2donate.me@gmail.com" />
      </Typography>
    </StyledContainer>
  );
}
