import React, { ReactElement, ReactNode } from 'react';
import { Controller, Control } from 'react-hook-form';
import Box from '@mui/material/Box';
import Input from 'view/components/Input';
import InputMask from 'react-input-mask';
import { BaseSelectProps, FilledSelectProps } from '@mui/material';

type FormInputProps = {
  label: string;
  name: string;
  error?: boolean;
  placeholder?: string;
  inputProps?: object;
  mask?: string;
  helperText?: ReactElement | string;
  control: Control<any, any>;
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  startAdornment?: ReactElement;
  endAdornment?: ReactElement;
  children?: ReactNode;
  SelectProps?: Partial<FilledSelectProps & BaseSelectProps>;
  mb?: number;
  mt?: number;
  sx?: any;
  select?: boolean;
  disabled?: boolean;
};

const FormInput = ({
  label,
  name,
  error,
  helperText,
  inputProps,
  mask = '',
  control,
  placeholder,
  type,
  fullWidth = true,
  startAdornment,
  endAdornment,
  children,
  SelectProps,
  disabled,
  mt,
  mb,
  sx,
  select,
}: FormInputProps) => (
  <Controller
    name={name}
    control={control}
    defaultValue=""
    render={({ field }) => (
      <Box mb={mb} mt={mt} sx={sx}>
        {mask ? (
          <InputMask
            mask={mask}
            value={field.value}
            maskChar=" "
            onChange={field.onChange}
            onBlur={field.onBlur}
          >
            {/*@ts-ignore*/}
            {(inputProps) => (
              <Input
                {...inputProps}
                type={type}
                error={error}
                helperText={helperText}
                fullWidth={fullWidth}
                inputProps={inputProps}
                disabled={disabled}
                id={name}
                placeholder={placeholder}
                select={select}
                label={label}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                children={children}
              />
            )}
          </InputMask>
        ) : (
          <Input
            type={type}
            error={error}
            helperText={helperText}
            fullWidth={fullWidth}
            inputProps={inputProps}
            SelectProps={SelectProps}
            id={name}
            disabled={disabled}
            placeholder={placeholder}
            select={select}
            label={label}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            children={children}
            {...field}
          />
        )}
      </Box>
    )}
  />
);

export default FormInput;
