export const APP_PATH = '/';

export const ADMIN_APP_PATH = '/admin';
export const ADMIN_USERS_PATH = `${ADMIN_APP_PATH}/users`;

export const DONATE_PATH = '/d';
export const LIVE_DONATE_PATH = '/live';
export const QR_CODE_DONATE_PATH = '/qrcode';
export const GOALS_PATH = '/goals';

export const PRICING_PATH = '/pricing';
export const CONTACTS_PATH = '/contacts';
export const PRIVACY_POLICY_PATH = '/privacy-policy';
export const TERMS_PATH = '/terms';

export const MY_DONATES_PATH = '/donates';
export const STATISTICS_PATH = '/statistics';
export const BALANCE_PATH = '/balance';
export const SETTINGS_PATH = '/settings';
export const WIDGETS_PATH = '/widgets';
