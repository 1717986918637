import React, { useEffect, useState } from 'react';
import FormHeader from 'view/components/Form/Header';
import { StyledFormActions, StyledFormContainer } from '../../styled';
import Button from 'view/components/Button';
import useSocket from 'hooks/socket';
import { EXAMPLE_DONATION } from 'view/constants';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextArea from 'view/components/TextArea';
import Input from 'view/components/Form/Input';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { PaymentMethodTypes } from 'types/users';
import { Currency } from 'services/api/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExampleDonateValidationSchema } from 'constraints';

type ViewTestDonationProps = {
  onCancel: () => void;
};

type DonateFormData = {
  message: string;
  sponsorName: string;
  amount: number;
  paymentMethod: PaymentMethodTypes;
  voice: string;
  currency: Currency;
};

export default function ViewTestDonation({ onCancel }: ViewTestDonationProps) {
  const { socketIo } = useSocket();
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);

  const initialFormData = {
    resolver: yupResolver(ExampleDonateValidationSchema(t)),
    defaultValues: EXAMPLE_DONATION,
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DonateFormData>(initialFormData);

  useEffect(() => {
    return () => {
      if (socketIo) {
        socketIo.emit('hide-test-donation');
      }
    };
  }, [socketIo]);

  const onSubmit = async (formData: DonateFormData) => {
    if (socketIo) {
      if (!isShow) {
        socketIo.emit('show-test-donation', { donation: formData });
      } else {
        socketIo.emit('hide-test-donation');
      }
    }
    setIsShow(!isShow);
  };

  return (
    <>
      <StyledFormContainer>
        <FormHeader
          title={t('Test donation')}
          description={t(
            'Click «Show» to send a test donate, then open the OBS and move your Browser source to adjust the donate position.',
          )}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box maxWidth="30rem">
            <Box mb={3}>
              <TextArea
                name="message"
                label={t('Your message')}
                type="text"
                control={control}
                rows={3}
                disabled
                error={!!errors.message?.message}
                helperText={errors.message?.message}
              />
            </Box>
            <Input
              name="sponsorName"
              label={t('Your name')}
              type="text"
              disabled={isSubmitting}
              error={!!errors.sponsorName?.message}
              helperText={errors.sponsorName?.message}
              control={control}
              mb={2}
            />
            <Input
              name="amount"
              label={t('How much do you wish to donate?')}
              error={!!errors.amount?.message}
              type="number"
              helperText={errors.amount?.message}
              disabled={isSubmitting}
              control={control}
              mb={2}
              startAdornment={
                <Typography variant="body2">{Currency.UAH}</Typography>
              }
            />
          </Box>
          <StyledFormActions>
            <Button
              type="submit"
              withLoader
              loading={isSubmitting}
              label={isShow ? t('Hide') : t('Show')}
            />
            <Button
              label={t('Back')}
              variant="text"
              className="cancel-button"
              onClick={onCancel}
            />
          </StyledFormActions>
        </form>
      </StyledFormContainer>
    </>
  );
}
