import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActionMenu from 'view/components/ActionMenu';
import Avatar from 'view/components/Avatar';
import Drawer from 'view/components/Drawer';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import NavigationTabs from 'view/components/NavigationTabs';
import MenuIcon from '@mui/icons-material/Menu';
import ListIcon from '@mui/icons-material/List';
import LogoIcon from 'view/components/icons/Logo';
import MoreHorizontalIcon from 'view/components/icons/MoreHorizontal';
import GoogleIcon from '@mui/icons-material/Google';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DrawerDetails from './components/DrawerDetails';
import {
  SETTINGS_PATH,
  APP_PATH,
  BALANCE_PATH,
  MY_DONATES_PATH,
  STATISTICS_PATH,
  WIDGETS_PATH,
} from 'view/routes';
import useAuth from 'hooks/auth';
import Button from 'view/components/Button';
import { Link, useNavigate } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import useUser from 'hooks/user';
import useTheme from 'hooks/theme';
import CustomizedSwitches from 'view/components/Switch';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import WalletIcon from '@mui/icons-material/Wallet';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from 'view/components/icons/Close';
import LanguageChange from 'view/components/LanguageChange';

export default function ResponsiveAppBar() {
  const { isAuthenticated, signOut } = useAuth();
  const { user } = useUser();
  const { toggleTheme, isDarkMode } = useTheme();
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [dialogToLogout, setDialogToLogout] = useState<boolean>(false);

  const { t } = useTranslation();

  const additionalTabs = [
    {
      id: BALANCE_PATH,
      label: t('Balance'),
      icon: <WalletIcon />,
      onClick: () => navigate(BALANCE_PATH),
    },
    {
      id: SETTINGS_PATH,
      label: t('Settings'),
      icon: <SettingsIcon />,
      onClick: () => navigate(SETTINGS_PATH),
    },
  ];

  const tabs = [
    {
      id: MY_DONATES_PATH,
      onClick: () => navigate(MY_DONATES_PATH),
      label: t('donations'),
      icon: <ListIcon />,
    },
    {
      id: WIDGETS_PATH,
      label: t('Widgets'),
      icon: <WidgetsIcon />,
      onClick: () => navigate(WIDGETS_PATH),
    },
    {
      id: STATISTICS_PATH,
      onClick: () => navigate(STATISTICS_PATH),
      label: t('statistics'),
      icon: <StackedLineChartIcon />,
    },
    ...additionalTabs,
  ];

  const onLogoutConfirmation = () => signOut();

  const onConfirmationClose = () => {
    setDialogToLogout(false);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            padding: { xs: 0, md: 'inherit' },
          }}
        >
          {isAuthenticated && (
            <Box
              sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}
              mr={2}
            >
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => setDrawerOpen(!drawerOpen)}
                color="inherit"
              >
                {drawerOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </Box>
          )}

          <Box
            sx={{ flexGrow: 1, mr: 1, display: 'flex', alignItems: 'center' }}
          >
            <Box mr={2}>
              <Link to={APP_PATH} style={{ textDecoration: 'none' }}>
                <LogoIcon />
              </Link>
            </Box>

            {isAuthenticated && (
              <Box sx={{ display: { xs: 'none', md: 'grid' } }}>
                <NavigationTabs tabs={tabs} withIcons />
              </Box>
            )}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                alignItems: 'center',
                gap: 2,
              }}
            >
              <LanguageChange />
              <CustomizedSwitches onChange={toggleTheme} checked={isDarkMode} />
            </Box>
            {isAuthenticated && (
              <>
                <Box mr={1}>
                  <Avatar
                    src={user.profilePictureUrl}
                    contentCreatorName={user.contentCreatorName}
                    size={3}
                  />
                </Box>
                <Box
                  minWidth="0"
                  mr={1}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  <Tooltip arrow title={user.contentCreatorName}>
                    <Typography
                      maxWidth="6rem"
                      minWidth="1rem"
                      variant="body1"
                      fontSize="0.875rem"
                      sx={{ lineHeight: '1.5rem' }}
                      noWrap
                    >
                      {user.contentCreatorName}
                    </Typography>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    fontSize="0.75rem"
                    sx={{ lineHeight: '1rem' }}
                    noWrap
                  >
                    Content creator
                  </Typography>
                </Box>

                <ActionMenu
                  buttonIcon={<MoreHorizontalIcon />}
                  menuItems={[
                    ...additionalTabs,
                    {
                      label: t('Log out'),
                      icon: <LogoutIcon />,
                      onClick: () => setDialogToLogout(true),
                    },
                  ]}
                />
              </>
            )}
            {!isAuthenticated && (
              <Box sx={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                <MuiLink href={`${process.env.REACT_APP_API_URL}/api/auth`}>
                  <Button
                    variant="contained"
                    color="primary"
                    label={t('Sign in with Google')}
                    startIcon={<GoogleIcon />}
                  />
                </MuiLink>
              </Box>
            )}
          </Box>
          {dialogToLogout && (
            <ConfirmationDialog
              open={dialogToLogout}
              text={t('Are you sure, you want to log out?')}
              confirmText={t('Log out')}
              color="primary"
              onConfirm={onLogoutConfirmation}
              onClose={onConfirmationClose}
            />
          )}
          <Drawer
            variant="temporary"
            height="100vh"
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            action={
              <DrawerDetails
                onClose={() => setDrawerOpen(false)}
                adminTabs={tabs}
              />
            }
          />
        </Toolbar>
      </Container>
      <Divider />
    </AppBar>
  );
}
