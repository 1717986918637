export default function BitcoinPayIcon() {
  return (
    <svg
      width="42px"
      height="42px"
      viewBox="0 -9 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="0.5"
        width="57"
        height="39"
        rx="3.5"
        fill="white"
        stroke="#F3F3F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6654 16.1744C20.0714 16.1744 20.4218 16.2466 20.7164 16.3889C21.0124 16.5323 21.257 16.7256 21.4528 16.9687C21.6461 17.213 21.79 17.4987 21.8829 17.8258C21.9752 18.1541 22.0215 18.5068 22.0215 18.8846C22.0215 19.4644 21.9148 20.0128 21.699 20.5294C21.4835 21.0467 21.1907 21.4954 20.8184 21.8783C20.4465 22.2607 20.0073 22.562 19.5008 22.7845C18.995 23.0077 18.4454 23.1188 17.8551 23.1188C17.7787 23.1188 17.6445 23.1169 17.455 23.1126C17.2654 23.1088 17.0474 23.0895 16.8034 23.0565C16.5578 23.0226 16.2982 22.9722 16.0238 22.9047C15.7498 22.8384 15.4895 22.7453 15.2443 22.6274L17.4359 13.4655L19.3988 13.1627L18.6143 16.4138C18.7823 16.3385 18.9515 16.2799 19.1204 16.2376C19.2903 16.1965 19.4714 16.1744 19.6654 16.1744ZM18.019 21.5698C18.3146 21.5698 18.593 21.4976 18.8548 21.3554C19.1173 21.2135 19.3438 21.0214 19.5333 20.7821C19.7229 20.5422 19.8734 20.2714 19.9829 19.9689C20.0924 19.6667 20.1477 19.3514 20.1477 19.0231C20.1477 18.62 20.0802 18.3054 19.945 18.0782C19.8099 17.8516 19.5602 17.738 19.1974 17.738C19.0785 17.738 18.9252 17.7604 18.7353 17.8006C18.5448 17.8438 18.3743 17.9325 18.2214 18.0661L17.3858 21.5191C17.4362 21.5275 17.4809 21.5362 17.5188 21.544C17.556 21.5524 17.597 21.5586 17.638 21.563C17.6814 21.568 17.7309 21.5701 17.7906 21.5701C17.8504 21.5701 17.9261 21.5698 18.019 21.5698Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.058 22.943H22.1829L23.7664 16.3142H25.6543L24.058 22.943ZM24.9702 15.5072C24.7083 15.5072 24.4712 15.4294 24.2607 15.2744C24.0483 15.1201 23.9432 14.8823 23.9432 14.562C23.9432 14.3859 23.9791 14.2203 24.0505 14.0647C24.1227 13.9091 24.2175 13.7746 24.3361 13.661C24.4546 13.5481 24.5916 13.4578 24.7474 13.3896C24.9041 13.3227 25.0709 13.2894 25.2486 13.2894C25.5101 13.2894 25.7463 13.3669 25.9574 13.5222C26.1679 13.6781 26.274 13.9159 26.274 14.2343C26.274 14.4111 26.2383 14.5773 26.1661 14.7329C26.0944 14.8885 26 15.0223 25.8814 15.1365C25.7641 15.2495 25.6255 15.3395 25.47 15.407C25.3133 15.4748 25.1475 15.5072 24.9702 15.5072Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2969 14.6634L29.2608 14.3606L28.7785 16.3142H30.8816L30.5018 17.8513H28.4112L27.8544 20.1702C27.8031 20.3635 27.774 20.544 27.7659 20.7114C27.7568 20.8801 27.7781 21.0248 27.8287 21.1465C27.8791 21.2685 27.9698 21.3625 28.1012 21.4294C28.232 21.497 28.4193 21.5309 28.6646 21.5309C28.8667 21.5309 29.0638 21.5119 29.2543 21.4736C29.4435 21.4366 29.6356 21.3843 29.8299 21.3162L29.9697 22.7537C29.7163 22.8461 29.442 22.9258 29.1463 22.9927C28.8504 23.0603 28.4997 23.0929 28.0946 23.0929C27.5122 23.0929 27.0605 23.0073 26.7389 22.8359C26.4176 22.6631 26.1905 22.4275 26.0544 22.1291C25.9202 21.8319 25.8605 21.4889 25.8774 21.1027C25.8949 20.7155 25.9534 20.3084 26.0544 19.8799L27.2969 14.6634V14.6634Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.799 20.297C30.799 19.7259 30.8919 19.1878 31.0777 18.6833C31.2629 18.1788 31.53 17.7378 31.8766 17.359C32.222 16.9821 32.6424 16.6837 33.1373 16.4649C33.63 16.2467 34.1815 16.1368 34.7896 16.1368C35.17 16.1368 35.51 16.1723 35.8091 16.2442C36.1094 16.3164 36.3812 16.4101 36.6268 16.5274L35.9802 17.9905C35.8113 17.9223 35.6358 17.8623 35.4547 17.8075C35.2732 17.7521 35.0517 17.7253 34.7899 17.7253C34.1643 17.7253 33.6694 17.9398 33.3012 18.3674C32.9346 18.7956 32.7497 19.3717 32.7497 20.0947C32.7497 20.5232 32.8426 20.8693 33.029 21.1345C33.2146 21.3993 33.5571 21.531 34.0548 21.531C34.3004 21.531 34.5372 21.5055 34.7643 21.4557C34.9929 21.4052 35.1947 21.3421 35.3727 21.2668L35.5125 22.7662C35.2754 22.858 35.0139 22.9408 34.727 23.013C34.4396 23.0831 34.0973 23.1189 33.7004 23.1189C33.1767 23.1189 32.7331 23.0429 32.3706 22.8926C32.0077 22.7401 31.7077 22.5375 31.4715 22.281C31.2341 22.024 31.0636 21.7236 30.9582 21.3791C30.8524 21.0358 30.799 20.6742 30.799 20.297H30.799Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.1275 23.1194C38.6798 23.1194 38.2913 23.0519 37.9616 22.9177C37.6328 22.7833 37.3607 22.595 37.1451 22.3507C36.9296 22.1076 36.7679 21.8194 36.6574 21.4876C36.5467 21.1562 36.4938 20.7886 36.4938 20.3862C36.4938 19.8811 36.5755 19.3763 36.74 18.8727C36.9039 18.3686 37.147 17.9154 37.4692 17.5124C37.7886 17.1087 38.1824 16.7788 38.6467 16.5214C39.11 16.2662 39.6433 16.1377 40.2421 16.1377C40.6813 16.1377 41.0682 16.2055 41.4023 16.3397C41.7348 16.4741 42.0089 16.6637 42.2256 16.9067C42.4399 17.151 42.6029 17.438 42.7124 17.7704C42.8228 18.1012 42.8769 18.4697 42.8769 18.8724C42.8769 19.376 42.7965 19.8808 42.637 20.3859C42.4772 20.8882 42.2403 21.3426 41.9275 21.7463C41.6153 22.1493 41.2249 22.4789 40.756 22.7353C40.2871 22.9912 39.7437 23.1194 39.1275 23.1194ZM40.0644 17.7259C39.7863 17.7259 39.5407 17.8062 39.3302 17.9649C39.119 18.1252 38.9417 18.3268 38.7987 18.5699C38.6542 18.8139 38.5469 19.0809 38.4746 19.3701C38.403 19.6604 38.3676 19.9346 38.3676 20.1954C38.3676 20.6159 38.4358 20.9433 38.5697 21.1792C38.7058 21.4136 38.9498 21.5315 39.3048 21.5315C39.5839 21.5315 39.8282 21.4519 40.04 21.2916C40.2499 21.1325 40.4272 20.9309 40.5715 20.6872C40.715 20.4444 40.8233 20.1768 40.8955 19.887C40.9665 19.5976 41.0025 19.3218 41.0025 19.0616C41.0025 18.6421 40.9346 18.3141 40.7995 18.0785C40.6644 17.8435 40.4191 17.7259 40.0644 17.7259Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9197 22.943H43.0434L44.6269 16.3142H46.5157L44.9197 22.943ZM45.8306 15.5072C45.5691 15.5072 45.332 15.4294 45.1218 15.2744C44.9103 15.1201 44.8043 14.8823 44.8043 14.562C44.8043 14.3859 44.8405 14.2203 44.9122 14.0647C44.9838 13.9091 45.0789 13.7746 45.1975 13.661C45.3154 13.5481 45.4537 13.4578 45.6091 13.3896C45.7649 13.3227 45.9317 13.2894 46.109 13.2894C46.3712 13.2894 46.608 13.3669 46.8188 13.5222C47.03 13.6781 47.1363 13.9159 47.1363 14.2343C47.1363 14.4111 47.0985 14.5773 47.0275 14.7329C46.9565 14.8885 46.8617 15.0223 46.7434 15.1365C46.6246 15.2495 46.4882 15.3395 46.3324 15.407C46.1754 15.4748 46.0089 15.5072 45.8306 15.5072Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8658 16.6164C48.0082 16.575 48.1671 16.5252 48.3401 16.4711C48.5137 16.4169 48.7073 16.3665 48.9238 16.3211C49.1381 16.2744 49.3796 16.2367 49.6455 16.2069C49.9114 16.177 50.2139 16.1624 50.5508 16.1624C51.5393 16.1624 52.2212 16.4487 52.5976 17.0201C52.9733 17.5915 53.0386 18.3733 52.7943 19.3639L51.9322 22.943H50.0449L50.8808 19.4392C50.9315 19.2204 50.9718 19.0088 51.0012 18.8022C51.0313 18.5977 51.0294 18.4172 50.9947 18.2609C50.9618 18.1066 50.883 17.9805 50.7613 17.8825C50.6374 17.7857 50.4504 17.7378 50.1967 17.7378C49.9523 17.7378 49.7037 17.7639 49.4496 17.8143L48.2206 22.943H46.3327L47.8658 16.6164V16.6164Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2123 26.8937L24.8444 27.5585H24.4465C24.7268 27.0555 25.0002 26.5924 25.2667 26.1692C25.5332 25.7459 25.7966 25.3463 26.0569 24.9703H26.4135C26.451 25.1571 26.4854 25.346 26.5167 25.5361C26.548 25.7266 26.5787 25.9264 26.6087 26.1356C26.6387 26.3447 26.6675 26.5666 26.695 26.8006C26.7226 27.0347 26.7513 27.2871 26.7814 27.5588H26.4135C26.401 27.4468 26.3897 27.3341 26.3797 27.2208C26.3697 27.1075 26.3584 26.9986 26.3459 26.894H25.2123V26.8937ZM26.3121 26.6021C26.2921 26.4007 26.269 26.2 26.2427 26.0008C26.2164 25.8016 26.1858 25.5962 26.1507 25.3846C26.0631 25.5116 25.9543 25.6756 25.8241 25.8757C25.694 26.0764 25.5463 26.3183 25.3812 26.6021H26.3121Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1118 27.6142C27.9491 27.6142 27.8058 27.5905 27.682 27.5432C27.5581 27.4959 27.4549 27.4287 27.3723 27.3416C27.2897 27.2547 27.2277 27.1492 27.1865 27.026C27.1452 26.9027 27.1245 26.7639 27.1245 26.6096C27.1245 26.3855 27.1602 26.1714 27.2315 25.9672C27.3028 25.7633 27.4042 25.5828 27.5356 25.426C27.6669 25.2691 27.8252 25.144 28.0104 25.0503C28.1956 24.957 28.4018 24.9103 28.6298 24.9103C28.825 24.9103 28.9808 24.9302 29.0972 24.97C29.2135 25.0099 29.2967 25.0472 29.3468 25.0821L29.1966 25.3808C29.1388 25.3435 29.0609 25.308 28.962 25.2744C28.8632 25.2411 28.7462 25.224 28.611 25.224C28.4384 25.224 28.2832 25.2645 28.1456 25.3457C28.0079 25.4266 27.8915 25.5312 27.7965 25.6594C27.7014 25.7876 27.6288 25.932 27.5787 26.0926C27.5287 26.2532 27.5037 26.4119 27.5037 26.5688C27.5037 27.0543 27.7151 27.2971 28.1381 27.2971C28.2281 27.2971 28.3089 27.2905 28.3802 27.2781C28.4515 27.2659 28.5147 27.2513 28.5697 27.2351C28.6248 27.2189 28.6736 27.2012 28.7161 27.181C28.7587 27.1611 28.7962 27.1436 28.8288 27.1287L28.8738 27.4424C28.8438 27.4598 28.8044 27.4785 28.7556 27.4984C28.7068 27.5183 28.6498 27.537 28.5848 27.5548C28.5197 27.5722 28.4471 27.5865 28.367 27.5977C28.287 27.6089 28.2019 27.6142 28.1118 27.6142V27.6142Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.2208 27.6142C30.0582 27.6142 29.9149 27.5905 29.791 27.5432C29.6672 27.4959 29.5639 27.4287 29.4813 27.3416C29.3988 27.2547 29.3368 27.1492 29.2955 27.026C29.2542 26.9027 29.2336 26.7639 29.2336 26.6096C29.2336 26.3855 29.2692 26.1714 29.3406 25.9672C29.4119 25.7633 29.5132 25.5828 29.6446 25.426C29.776 25.2691 29.9343 25.144 30.1195 25.0503C30.3047 24.957 30.5108 24.9103 30.7389 24.9103C30.9341 24.9103 31.0899 24.9302 31.2062 24.97C31.3226 25.0099 31.4058 25.0472 31.4559 25.0821L31.3057 25.3808C31.2478 25.3435 31.1699 25.308 31.0711 25.2744C30.9722 25.2411 30.8552 25.224 30.7201 25.224C30.5474 25.224 30.3923 25.2645 30.2546 25.3457C30.117 25.4266 30.0006 25.5312 29.9055 25.6594C29.8104 25.7876 29.7378 25.932 29.6878 26.0926C29.6377 26.2532 29.6127 26.4119 29.6127 26.5688C29.6127 27.0543 29.8242 27.2971 30.2471 27.2971C30.3372 27.2971 30.4179 27.2905 30.4892 27.2781C30.5606 27.2659 30.6238 27.2513 30.6788 27.2351C30.7339 27.2189 30.7827 27.2012 30.8252 27.181C30.8678 27.1611 30.9053 27.1436 30.9378 27.1287L30.9829 27.4424C30.9528 27.4598 30.9134 27.4785 30.8646 27.4984C30.8158 27.5183 30.7589 27.537 30.6938 27.5548C30.6288 27.5722 30.5562 27.5865 30.4761 27.5977C30.396 27.6089 30.3109 27.6142 30.2208 27.6142V27.6142Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2288 27.5582L31.8519 24.9701H33.3872L33.3159 25.2726H32.1409L31.9533 26.0532H32.9968L32.9255 26.3482H31.8857L31.6642 27.2557H32.9255L32.8542 27.5582H31.2288H31.2288Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4739 24.9438C34.7768 24.9438 35.0051 25.0011 35.159 25.1156C35.3129 25.2302 35.3899 25.397 35.3899 25.6161C35.3899 25.7854 35.358 25.932 35.2942 26.0549C35.2303 26.1782 35.1396 26.2796 35.022 26.3596C34.9044 26.4393 34.763 26.4981 34.5978 26.5367C34.4326 26.5753 34.2487 26.5949 34.046 26.5949H33.787L33.5542 27.5584H33.1901L33.8058 25.0039C33.9109 24.979 34.0222 24.9628 34.1398 24.9554C34.2575 24.9476 34.3688 24.9438 34.4739 24.9438ZM34.4289 25.2501C34.2512 25.2501 34.1436 25.2551 34.1061 25.265L33.8583 26.2921H34.0948C34.1999 26.2921 34.3075 26.283 34.4176 26.2641C34.5277 26.2454 34.6266 26.2124 34.7142 26.1654C34.8018 26.1181 34.8737 26.0527 34.93 25.969C34.9863 25.8856 35.0145 25.7782 35.0145 25.6463C35.0145 25.5044 34.9619 25.4029 34.8568 25.3416C34.7517 25.2806 34.6091 25.2501 34.4289 25.2501V25.2501Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6106 24.9701L37.5392 25.2726H36.7547L36.2029 27.5582H35.8388L36.3906 25.2726H35.606L35.6773 24.9701H37.6106V24.9701Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2728 27.5582L37.8959 24.9701H39.4312L39.3599 25.2726H38.185L37.9973 26.0532H39.0408L38.9695 26.3482H37.9297L37.7082 27.2557H38.9695L38.8982 27.5582H37.2728H37.2728Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5123 24.9438C40.8977 24.9438 41.1855 25.0341 41.3757 25.2146C41.5659 25.3951 41.661 25.6385 41.661 25.9447C41.661 26.1589 41.6272 26.3649 41.5596 26.5625C41.4921 26.7608 41.3869 26.9354 41.2443 27.0876C41.1017 27.2391 40.9196 27.3602 40.6981 27.4498C40.4766 27.5395 40.212 27.5843 39.9042 27.5843C39.7991 27.5843 39.6852 27.5793 39.5626 27.5693C39.44 27.5594 39.3323 27.5419 39.2397 27.5171L39.8441 25.0036C39.9492 24.9787 40.0606 24.9625 40.1782 24.9551C40.2958 24.9476 40.4072 24.9438 40.5123 24.9438ZM40.4823 25.2501C40.2946 25.2501 40.182 25.2551 40.1444 25.265L39.6639 27.2594C39.684 27.2643 39.7177 27.2687 39.7653 27.2724C39.8128 27.2762 39.8904 27.278 39.998 27.278C40.1957 27.278 40.374 27.2454 40.5329 27.1794C40.6919 27.1134 40.8264 27.0213 40.9365 26.9027C41.0466 26.7844 41.1317 26.6441 41.1917 26.481C41.2518 26.3179 41.2818 26.1393 41.2818 25.9451C41.2818 25.726 41.218 25.5554 41.0904 25.4331C40.9628 25.3114 40.7601 25.2501 40.4823 25.2501V25.2501Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.4651 27.5582H44.101L44.39 26.3557H43.185L42.896 27.5582H42.5319L43.155 24.9701H43.5191L43.2564 26.0532H44.4617L44.7244 24.9701H45.0885L44.4651 27.5582V27.5582Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.0394 27.5582L45.6626 24.9701H47.1979L47.1263 25.2726H45.9513L45.7636 26.0532H46.8072L46.7355 26.3482H45.6957L45.4746 27.2557H46.7355L46.6639 27.5582H45.0394H45.0394Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2847 24.9438C48.5625 24.9438 48.7827 24.9999 48.9453 25.1119C49.108 25.2239 49.189 25.3883 49.189 25.6046C49.189 25.8237 49.1249 26.0076 48.996 26.1558C48.8671 26.3039 48.6863 26.4103 48.4536 26.4748C48.4836 26.5274 48.5187 26.594 48.5587 26.6749C48.5987 26.7558 48.64 26.8454 48.6826 26.9438C48.7248 27.0418 48.7664 27.1442 48.8065 27.2497C48.8465 27.3558 48.8812 27.4582 48.9116 27.5578H48.5399C48.5052 27.4632 48.4686 27.3674 48.4311 27.2703C48.3938 27.1731 48.3554 27.0798 48.3169 26.9901C48.2778 26.9005 48.2393 26.8165 48.2002 26.7381C48.1617 26.6596 48.1258 26.5902 48.0932 26.5308C48.0607 26.5333 48.0282 26.5351 47.9956 26.5364C47.9631 26.5376 47.9309 26.5383 47.898 26.5383H47.609L47.365 27.5578H47.0009L47.6168 25.0033C47.7219 24.9784 47.833 24.9622 47.9509 24.9547C48.0682 24.9476 48.1796 24.9438 48.2847 24.9438ZM48.2396 25.2501C48.0619 25.2501 47.9543 25.2551 47.9165 25.265L47.68 26.2438H47.9018C48.0044 26.2438 48.1095 26.2364 48.2171 26.2214C48.3247 26.2065 48.4229 26.1772 48.5115 26.1337C48.6003 26.0901 48.6729 26.0291 48.7292 25.9507C48.7855 25.8722 48.814 25.7683 48.814 25.6388C48.814 25.4994 48.7608 25.3998 48.6544 25.34C48.5481 25.28 48.4095 25.2501 48.2396 25.2501Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.2169 27.5582L49.84 24.9701H51.3753L51.3037 25.2726H50.1287L49.941 26.0532H50.9846L50.913 26.3482H49.8732L49.652 27.2557H50.913L50.8413 27.5582H49.2169H49.2169Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0033 23.1189C12.7665 23.1189 15.0065 20.8903 15.0065 18.1412C15.0065 15.3921 12.7665 13.1635 10.0033 13.1635C7.24003 13.1635 5 15.3921 5 18.1412C5 20.8903 7.24003 23.1189 10.0033 23.1189ZM12.2093 17.4316C12.3087 16.769 11.8017 16.4129 11.1084 16.1752L11.3334 15.2773L10.7844 15.141L10.5654 16.0152C10.4212 15.9794 10.2729 15.9455 10.1253 15.9122L10.3458 15.0324L9.79711 14.896L9.57188 15.7936C9.45238 15.7665 9.33507 15.7398 9.2212 15.7114L9.22183 15.7086L8.46449 15.5207L8.3184 16.1042C8.3184 16.1042 8.7257 16.197 8.71725 16.2029C8.93966 16.2583 8.98002 16.4045 8.97314 16.5206L8.71694 17.543C8.73226 17.5467 8.75197 17.552 8.77418 17.5614C8.75573 17.5564 8.73633 17.5517 8.716 17.5467L8.35688 18.9796C8.32966 19.0465 8.26053 19.1474 8.10506 19.1091C8.11069 19.1169 7.7059 19.0101 7.7059 19.0101L7.43312 19.6357L8.1476 19.8128C8.28055 19.8458 8.411 19.8806 8.53894 19.9133L8.31183 20.8211L8.86021 20.9574L9.08544 20.0593C9.23528 20.0994 9.38074 20.1371 9.52308 20.1722L9.29878 21.0661L9.84778 21.2024L10.0752 20.2964C11.0118 20.4726 11.7159 20.4016 12.0122 19.5588C12.2512 18.88 12.0003 18.4888 11.5076 18.2336C11.8667 18.1514 12.137 17.9168 12.2093 17.4316ZM10.9545 19.1822C10.7847 19.8607 9.63663 19.4938 9.26437 19.4016L9.56593 18.1991C9.93819 18.2915 11.1319 18.4745 10.9545 19.1822ZM11.1244 17.4219C10.9696 18.0388 10.0139 17.7254 9.70389 17.6488L9.97729 16.5577C10.2873 16.6349 11.2855 16.7783 11.1244 17.4219Z"
        fill="#F7931A"
      />
    </svg>
  );
}
