import { request } from '../request';
import { Withdrawal } from 'types/withdrawal';
import {
  PaginatedResponse,
  CardWithdrawDTO,
  IBANWithdrawDTO,
  WhitepayWithdrawDTO,
} from 'services/api/types';

export const createCardWithdrawalRequest = async (
  form: CardWithdrawDTO,
): Promise<Withdrawal> => {
  const { data } = await request({
    method: 'post',
    url: '/withdrawals/card',
    data: form,
    withCredentials: true,
  });

  return data;
};

export const createWhitepayWithdrawalRequest = async (
  form: WhitepayWithdrawDTO,
): Promise<Withdrawal> => {
  const { data } = await request({
    method: 'post',
    url: '/withdrawals/whitepay',
    data: form,
    withCredentials: true,
  });

  return data;
};

export const createIBANWithdrawalRequest = async (
  form: IBANWithdrawDTO,
): Promise<Withdrawal> => {
  const { data } = await request({
    method: 'post',
    url: '/withdrawals/iban',
    data: form,
    withCredentials: true,
  });

  return data;
};

export const listWithdrawals = async (
  url = '/withdrawals',
): Promise<PaginatedResponse<Withdrawal>> => {
  const { data } = await request({
    url,
    withCredentials: true,
  });

  return data;
};

// export const withdrawLiqPay = async (
//   params: WithdrawBalanceDTO,
// ): Promise<Withdrawal> => {
//   const { data } = await request({
//     method: 'post',
//     url: '/withdrawals/liqpay',
//     data: { ...params },
//     withCredentials: true,
//   });
//
//   return data;
// };

// export const withdrawWayForPay = async (
//   params: WithdrawBalanceDTO,
// ): Promise<Withdrawal> => {
//   const { data } = await request({
//     method: 'post',
//     url: '/withdrawals/wayforpay',
//     data: { ...params },
//     withCredentials: true,
//   });
//
//   return data;
// };
