import React from 'react';
import { StyledContainer } from './styled';
import Button from 'view/components/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Box from '@mui/material/Box';
import { primary } from 'view/theme/colors';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { PRICING_PATH } from 'view/routes';
import { useTranslation } from 'react-i18next';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GoogleIcon from '@mui/icons-material/Google';
import { getVoicesConfig, VoiceConfig } from 'view/constants';
import AudioPreview from 'view/components/AudioPreview';
import styled from '@emotion/styled';

const authLink = `${process.env.REACT_APP_API_URL}/api/auth`;

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const accordionQnA = [
    {
      title: t('How is the donation received?'),
      description: t(
        "Log in to 2Donate with your Google account, in the settings find the line with a unique link for OBS. In the OBS settings, add a new source - «Browser», paste the link from 2Donate into the URL field and click «OK». That's it, now you are ready to receive donations to your account.",
      ),
    },
    {
      title: t('How can my viewers get a donation form?'),
      description: t(
        'In the settings, copy the line with the link for donations and place it below your stream or anywhere else.',
      ),
    },
    {
      title: t('How do I withdraw funds to my card?'),
      description: t(
        'In the Balance section, find the withdrawal form. Fill in the required information, click «Withdraw», and wait for the transaction to be processed.',
      ),
    },
    {
      title: t('What are the possibilities for customizing donations?'),
      description: t(
        'Depending on the size of the donation, you can add unique animated images and notification sounds. 2Donate allows you to add any number of steps for donations. We recommend adding multiples of amounts and no more than 10 steps. In addition, you can customize the animation of donations appearing and disappearing on the page.',
      ),
    },
  ];

  const steps = [
    t('Log in with Google'),
    t('Set up integration in OBS'),
    t('Share your donation page with your audience'),
  ];

  return (
    <StyledContainer component="main">
      <Grid
        mt={{ xs: 8, md: 12 }}
        spacing={{ sm: 1, md: 6 }}
        container
        p={1}
        mb={{ xs: 0, sm: 4 }}
      >
        <Grid item sm={12} md={8} mb={{ xs: 8, sm: 8, md: 4 }}>
          <Typography
            variant="h1"
            sx={{ fontSize: { xs: 48, md: 64 }, lineHeight: 1 }}
            mb={2}
          >
            {t('An Ukrainian service for live donation voiceover')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: 24, md: 36 }, lineHeight: 1.2 }}
            mb={4}
          >
            {t('easySetup')}
          </Typography>
          <Box display="flex" gap={2}>
            <MuiLink href={authLink}>
              <Button variant="contained" size="large" label={t('Sign In')} />
            </MuiLink>
            <Button
              variant="outlined"
              label={t('pricing')}
              onClick={() => navigate(PRICING_PATH)}
            />
          </Box>
        </Grid>

        <Grid item sm={12} md={4}>
          <Box display="flex" flexDirection="column">
            <Typography
              variant="body2"
              mb={4}
              sx={{ fontSize: 24, lineHeight: 1 }}
            >
              {t('Try various voices')}
            </Typography>
            <Stack direction="row" gap={1} flexWrap="wrap" mb={4}>
              {Object.values(getVoicesConfig(t)).map((value) => {
                return <AudioButton key={value.label} config={value} />;
              })}
              <StyledAudioButton>
                <Typography ml={4}>{t('And others...')}</Typography>
              </StyledAudioButton>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="center" p={1} mb={8}>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ fontSize: 36, lineHeight: 1 }} mb={4}>
            {t('Why should you choose us?')}
          </Typography>
          <Stack direction="row" gap={1} flexWrap="wrap" mb={4}>
            {[
              t('Instant real-time voiceover of donations'),
              t('Intuitive settings and quick start'),
              t('Transparent tariffs without hidden fees'),
              t('Easy withdrawals'),
              t('Ukrainian language support'),
              t('Customize animated images and donation notifications'),
            ].map((item) => {
              return <Advantage key={item} header={item} />;
            })}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="center" p={1} mb={8}>
        <Grid item xs={8}>
          <Typography variant="h2" sx={{ fontSize: 36, lineHeight: 1 }} mb={4}>
            {t('Ready to get started?')}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: 24, lineHeight: 1 }}
            mb={4}
          >
            {t('Start receiving donations on your stream right now.')}
          </Typography>

          <MuiLink href={authLink}>
            <Button variant="contained" label={t('Free registration')} />
          </MuiLink>
        </Grid>

        <Grid item xs={4} display="flex" alignItems="center"></Grid>
      </Grid>

      <Grid container spacing={1} p={1} mb={8}>
        <Grid item xs={12} md={8}>
          <Typography variant="h1" sx={{ fontSize: 36, lineHeight: 1 }} mb={4}>
            {t('Just 3 easy steps to make money on your stream')}
          </Typography>
          <Box mb={4}>
            <Stepper orientation="vertical">
              {steps.map((label, index) => {
                return (
                  <Step active={true} key={label.toString()}>
                    <StepLabel>
                      <Typography variant="h2">{label}</Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>

          <Box display="flex" gap={2}>
            <MuiLink href={authLink}>
              <Button variant="contained" label={t('Get started')} />
            </MuiLink>
            <Button
              variant="outlined"
              label={t('pricing')}
              onClick={() => navigate(PRICING_PATH)}
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="center" p={1} mb={8}>
        <Grid item xs={12} mb={8}>
          <Typography variant="h2" sx={{ fontSize: 36, lineHeight: 1 }} mb={4}>
            {t('Questions and answers')}
          </Typography>
          {accordionQnA.map(({ title, description }) => {
            return (
              <Accordion disableGutters={true} key={title}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h2">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body2">{description}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Grid>

        <Grid item display="flex" justifyContent="center">
          <MuiLink href={authLink}>
            <Button
              variant="contained"
              color="primary"
              label={t('Sign in with Google')}
              startIcon={<GoogleIcon />}
            />
          </MuiLink>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}

type AdvantageProps = {
  header: string;
};
function Advantage({ header }: AdvantageProps) {
  return (
    <Box p={2} sx={{ border: `1px solid ${primary}`, borderRadius: 2 }}>
      <Typography variant="h3">{header}</Typography>
    </Box>
  );
}

type AudioButtonProps = {
  config: VoiceConfig;
};

const StyledAudioButton = styled(Box)`
  height: 55px;
  display: flex;
  align-items: center;
  border: 1px solid ${primary};
  padding: 0.25rem 2rem 0.25rem 0.25rem;
  gap: 0.5rem;
  border-radius: 2rem;
  width: auto;
`;

function AudioButton({ config }: AudioButtonProps) {
  return (
    <StyledAudioButton>
      <AudioPreview soundUrl={config.exampleUrl} />
      {config.label}
    </StyledAudioButton>
  );
}
