type ChevronIconProps = {
  left?: boolean;
  large?: boolean;
};

export default function ChevronIcon({ left, large = false }: ChevronIconProps) {
  if (large) {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        transform={left ? 'scale(1 -1)' : 'scale(-1 1)'}
      >
        <path
          d="M15 18L9 12L15 6"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      transform={left ? 'scale(-1 1)' : 'scale(1 -1)'}
    >
      <path
        d="M6.75 13.5L11.25 9L6.75 4.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
