import Box from '@mui/material/Box';
import { QRCodeSVG } from 'qrcode.react';
import LogoIcon from 'view/components/icons/Logo';
import React from 'react';
import styled from '@emotion/styled';
import { primary } from 'view/theme/colors';

const StyledLogoContainer = styled(Box)`
  margin-top: -6px;
  //background: ${primary};
  height: 1rem;
  width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    transform: scale(0.6);
  }
`;

type QRCodeProps = {
  value: string;
};

export default function QRCode({ value }: QRCodeProps) {
  return (
    <Box>
      <QRCodeSVG value={value} />
      <StyledLogoContainer>
        <LogoIcon />
      </StyledLogoContainer>
    </Box>
  );
}
