import { red } from 'view/theme/colors';

export default function AlertTriangleIcon({ small = true }) {
  if (small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          d="M12 9V11"
          stroke={red}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.0002 19H19.0002C19.6627 18.9954 20.28 18.6629 20.6484 18.1123C21.0168 17.5616 21.0887 16.8642 20.8402 16.25L13.7402 4.00002C13.388 3.36338 12.7178 2.96826 11.9902 2.96826C11.2626 2.96826 10.5924 3.36338 10.2402 4.00002L3.1402 16.25C2.89659 16.8498 2.95832 17.5303 3.30585 18.0764C3.65338 18.6225 4.24373 18.9667 4.8902 19"
          stroke={red}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none">
      <path
        d="M26.0003 19.5V23.8333"
        stroke={red}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8329 41.1666H41.1663C42.6018 41.1565 43.9391 40.4362 44.7374 39.2431C45.5357 38.05 45.6913 36.539 45.1529 35.2083L29.7696 8.66662C29.0064 7.28725 27.5544 6.43115 25.9779 6.43115C24.4015 6.43115 22.9495 7.28725 22.1863 8.66662L6.80294 35.2083C6.27514 36.5077 6.40887 37.9822 7.16186 39.1655C7.91484 40.3488 9.19394 41.0944 10.5946 41.1666"
        stroke={red}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
