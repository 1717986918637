import React from 'react';
import ReactDOM from 'react-dom';

type PortalsProps = {
  children: React.ReactNode;
};

export default function Portal({ children }: PortalsProps) {
  const portalRoot = document.getElementById('portal-root');
  if (!portalRoot) {
    return null;
  }

  return ReactDOM.createPortal(children, portalRoot);
}
