import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const StyledPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 2rem;
  margin: 8rem 0 4rem 0;
` as typeof Box;

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  align-self: center;
`;
