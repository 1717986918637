import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'view';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProviderWrapper } from 'contexts/theme';
import './i18n';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProviderWrapper>
        <App />
      </ThemeProviderWrapper>
    </StyledEngineProvider>
  </React.StrictMode>,
);
