import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'view/components/Loader';
import { MY_DONATES_PATH } from 'view/routes';
import useAuth from 'hooks/auth';

type Props = {
  children: ReactElement;
};

export default function AnonymousRoute({ children }: Props) {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <Loader height="100vh" />;
  }
  if (isAuthenticated) {
    return <Navigate to={MY_DONATES_PATH} />;
  }

  return children;
}
