import React from 'react';
import { StyledContainer } from '../DonationBook/styled';
import Typography from '@mui/material/Typography';
import PageHeader from 'view/components/PageHeader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import BackButton from 'view/components/BackButton';
import { useNavigate } from 'react-router-dom';
import Link from 'view/components/Link';

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const siteUrl = process.env.REACT_APP_UI_URL || '';

  return (
    <StyledContainer component="main">
      <PageHeader
        title="Політика конфіденційності"
        startAdornment={<BackButton onClick={() => navigate(-1)} />}
      />

      <Typography variant="body2" mb={4}>
        Будь ласка, уважно ознайомтеся з цією Політикою конфіденційності (далі –
        «Політика конфіденційності») перед використанням нашого веб-сайту.
        <br />
        <br />
        Дана Політика конфіденційності поширюється на веб-сайт{' '}
        <Link to={siteUrl} label={siteUrl} /> (далі – «Сервіс»). Політика
        конфіденційності включає в себе опис того яку інформацію ми збираємо, як
        ми можемо використовувати її зібраною нами для використання вами наших
        Послуг.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        1. Особиста інформація, що збирається під час надання наших Послуг.
      </Typography>
      <Typography variant="body2" mb={4}>
        Ви можете відвідувати і використовувати багато з наших Послуг, не
        розкриваючи свою особисту інформацію. Однак для того, щоб скористатися
        окремими, особливими або розширеними групами Послуг, вам знадобиться
        надати нам певну особисту інформацію з тим, щоб ці групи функціонували
        належним чином. При використанні розширених груп наших Послуг особиста
        інформація, що збирається нами включає:
        <List dense>
          <ListItem>Ім'я аканту Google</ListItem>
          <ListItem>Зображення акаунту Google</ListItem>
        </List>
        I іншу особисту інформацію, про яку докладно йдеться в цій Політиці
        Конфіденційності, і дані, які можуть бути запитані і надані вами. На
        додаток до наведеного вище переліку, можливо, буде потрібно дозвіл на
        використання іншої вашої інформації в порядку, викладеному на
        відповідній сторінках щодо Послуг.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />
      <Typography variant="h2" mb={2}>
        2. Умови та розголошення вашої особистої інформації.
      </Typography>
      <Typography variant="body2" mb={4}>
        Якщо ви надаєте особисту інформацію нам, ми можемо використовувати і
        розголошувати вашу інформацію в порядку, передбаченому цією Політикою
        конфіденційності та доведеному до вашого відома в той момент, коли ви
        вперше надали вашу інформацію, або допустимому згідно з чинним
        законодавством України. Ми не будемо продавати, надавати в тимчасове
        користування, публікувати або розголошувати вашу особисту інформацію
        кому б то не було, за винятком випадків, передбачених цією Політикою
        конфіденційності.
        <br />
        <br />
        Ми можемо:
        <List dense>
          <ListItem>
            {' '}
            Використовувати вашу інформацію для надання запитуваних вами послуг
            або надання вам пропозицій, відомостей про рекламні заходи
            (наприклад акції, передплатах на товари і послуги і т.д.) або
            контенту;
          </ListItem>
          <ListItem>
            Використовувати вашу інформацію для відправки вам електронних
            повідомлень, включаючи повідомлення про Сервіс в частині вашої
            взаємодії з нашим Сервісом, про продукти, послуги, спонсорів,
            партнерів та філіях, коли ви робите покупки, погоджуєтеся з
            правилами або умовами або іншим способом взаємодієте з нашим
            Сервісом, за умови, що розсилка подібних електронних повідомлень
            допускається чинним законодавством України;
          </ListItem>
          <ListItem>
            Передавати ваші дані після попереднього повідомлення у відповідному
            місці розміщення наших Послуг, де ми збираємо вашу особисту
            інформацію для здійснення економічних операцій і повідомляємо вас
            про те, що будемо ділитися такою інформацією з третіми особами;
          </ListItem>
          <ListItem>
            Використовувати або передавати вашу інформацію з вашого дозволу;
          </ListItem>
        </List>
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        3. Файли cookies.
      </Typography>
      <Typography variant="body2" mb={4}>
        Ми використовуємо і дозволяємо використання автоматичних методів, таких
        як файли cookies. Приклади інформації, яку ми збираємо і аналізуємо за
        допомогою цих методів, включають в себе (але не обмежуються цим
        переліком):
        <List dense>
          <ListItem>
            Особиста інформація, завірена вами, адреса інтернет-протоколу
            (IP-адреса), який використовується для з'єднання вашого комп'ютера
            або пристрою з Інтернетом;
          </ListItem>
          <ListItem>
            Тип і версія операційної системи і платформа комп'ютера або
            пристрою;
          </ListItem>
          <ListItem>
            Повна історія відвідувань Uniform Resource Locator (URL) наших
            Послуг, включаючи дату і час;
          </ListItem>
        </List>
        Ми використовуємо програмні засоби для оцінки та збору даних про сесії,
        включаючи час очікування відповіді сторінок, помилок завантаження,
        тривалість відвідування окремих сторінок, інформацію щодо взаємодії зі
        сторінкою (таких, як прокрутка, клацання миші і наведення курсору на
        елементи) і методи, використані для переходу на інші сторінки.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        4. Соціальні мережі.
      </Typography>
      <Typography variant="body2" mb={4}>
        При використанні наших послуг у вас є можливість підключити вашу
        соціальну мережу (наприклад, Facebook, Instagram, далі «Соціальна
        Мережа») до облікового запису на Сервісі. Якщо ви дозволяєте таке
        підключення, ви дозволяєте нам ділитися або публікувати інформацію про
        ваше використання наших Послуг з цією Соціальною Мережею і її
        користувачами, отримувати доступ до вашої інформації в Соціальній
        Мережі, зазначеної вами в той момент, коли ви дали згоду на підключення.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        5. Управління вашою інформацією.
      </Typography>
      <Typography variant="body2" mb={4}>
        Ви можете вибирати і контролювати, яку конкретно інформацію Ви надасте
        про себе, і яка конкретно інформація буде збиратися автоматично при
        використанні наших Послуг. Якщо ви не хочете надавати певну інформацію,
        необхідну для використання розширеного функціоналу або контенту наших
        Послуг, будь ласка, не використовуйте цей функціонал або контент.
        <br />
        Якщо ви не хочете, щоб інформація збиралася за допомогою файлів cookies,
        то в більшості браузерів передбачена проста процедура, що дозволяє
        користувачеві приймати чи не приймати більшість cookies.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        Зв'язок з нами.
      </Typography>
      <Typography variant="body2">
        Якщо у вас є питання або побоювання щодо цієї Політики конфіденційності,
        ви можете зв'язатися з нами за адресою:{' '}
        <Link to="mailto:2donate.me@gmail.com" label="2donate.me@gmail.com" />.{' '}
        Якщо ми внесемо важливу зміну в чинну Політику конфіденційності, ми
        розмістимо повідомлення і посилання на головній сторінці нашого Сервісу,
        щоб ви могли ознайомитися з оновленою політикою.
      </Typography>
    </StyledContainer>
  );
}
