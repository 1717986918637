import Accordion from '../Accordion';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import {
  getUserInfo,
  listWithdrawals,
  processUserWithdrawal,
  removeUserWithdrawal,
  updateUserWithdrawal,
} from 'services/api/admin/user';
import { PaymentMethodTypes, User } from 'types/users';
import { Order } from 'view/components/Table/components/TableHeader';
import Search from 'view/components/Search';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import Avatar from 'view/components/Avatar';
import UserInfoRow from 'view/components/UserInfoRow';
import { DONATE_PATH } from 'view/routes';
import Stack from '@mui/material/Stack';
import MuiLink from '@mui/material/Link';
import CopyButton from 'view/components/CopyButton';
import { formatCurrency } from 'tools';
import { useTranslation } from 'react-i18next';
import WithdrawalsTable from 'view/pages/AdminUsers/components/WithdrawalsTable';
import { Withdrawal, WithdrawalStatus } from 'types/withdrawal';
import {
  PaginatedResponse,
  PaymentMethodsCurrencyMap,
} from 'services/api/types';
import { StylePageHeader } from 'view/components/PageHeader';
import Typography from '@mui/material/Typography';
import Button from 'view/components/Button';
import TrashIcon from 'view/components/icons/Trash';
import useAuth from 'hooks/auth';
import IconButton from '@mui/material/IconButton';
import { SOCIAL_NETWORKS_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import Chip from '@mui/material/Chip';
import { PAYMENT_METHOD_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangePaymentMethods';

type UserDetailsProps = {
  userId: string;
  onEditUser: (user: User) => void;
  setUserToDelete: (user: User) => void;
};

export default function UserDetails({
  userId,
  onEditUser,
  setUserToDelete,
}: UserDetailsProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [page, setPage] = React.useState(1);
  const [sorting, setSorting] = useState<[string, Order]>([
    'createdAt',
    'desc',
  ]);
  const [user, setUser] = useState<User | null>(null);

  const [fieldToEdit, setFieldToEdit] = useState<string | null>(null);

  const [withdrawalsData, setWithdrawalsData] = useState({
    items: [] as Array<Withdrawal>,
    meta: { totalItems: 0 },
  } as PaginatedResponse<Withdrawal>);

  const [withdrawalToProcess, setWithdrawalToProcess] =
    useState<Withdrawal | null>(null);
  const [withdrawalToRefuse, setWithdrawalToRefuse] =
    useState<Withdrawal | null>(null);
  const [withdrawalToRemove, setWithdrawalToRemove] =
    useState<Withdrawal | null>(null);

  const handleChangePage = async (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    const getUser = async () => {
      const userData = await getUserInfo(userId);
      setUser(userData);
    };
    getUser();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchWithdrawals = useCallback(
    debounce(
      async (
        searchQuery: string,
        sorting: [string, 'asc' | 'desc'],
        page: number,
      ) => {
        setLoading(true);

        try {
          const searchResult = await listWithdrawals(
            {
              searchQuery,
              orderBy: sorting[0],
              orderDirection: sorting[1],
              page,
            },
            userId,
          );
          setWithdrawalsData(searchResult);
        } catch (err) {
          setErrorMessage('Error loading withdrawals');
        } finally {
          setLoading(false);
        }
      },
      500,
    ),
    [userId],
  );

  useEffect(() => {
    setLoading(true);
    searchWithdrawals(searchQuery, sorting, page);
  }, [searchQuery, sorting, page]);

  const onRemoveWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await removeUserWithdrawal(user?._id || '', withdrawal._id);
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.filter(
          (item) => item._id !== withdrawal._id,
        );
        const updatedMeta = {
          ...prevState.meta,
          totalItems: prevState.meta.totalItems - 1,
          itemCount: prevState.meta.itemCount - 1,
        };
        return {
          ...prevState,
          items: updatedItems,
          meta: updatedMeta,
        };
      });
      setSuccessMessage('Successfully removed withdrawal');
      setWithdrawalToRemove(null);
    } catch (err) {
      setErrorMessage('Error while deleting withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onProcessWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await processUserWithdrawal(user?._id || '', withdrawal._id);
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.map((item) => {
          if (item._id === withdrawal._id) {
            return { ...item, status: WithdrawalStatus.SUCCESS };
          }
          return item;
        });
        return {
          ...prevState,
          items: updatedItems,
        };
      });
      const newUserData = {
        ...user,
        balance: {
          ...(user as User).balance,
          [withdrawal.paymentMethod]:
            (user as User).balance[withdrawal.paymentMethod] -
            withdrawal.amount,
        },
      } as User;

      setUser(newUserData);
      onEditUser(newUserData);
      setSuccessMessage('Successfully processed withdrawal');
      setWithdrawalToProcess(null);
    } catch (err) {
      setErrorMessage('Error while processing withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onRefuseWithdrawalConfirmation = async (withdrawal: Withdrawal) => {
    try {
      await updateUserWithdrawal(user?._id || '', withdrawal._id, {
        status: WithdrawalStatus.FAILED,
      });
      setWithdrawalsData((prevState) => {
        const updatedItems = prevState.items.map((item) => {
          if (item._id === withdrawal._id) {
            return { ...item, status: WithdrawalStatus.FAILED };
          }
          return item;
        });
        return {
          ...prevState,
          items: updatedItems,
        };
      });
      setWithdrawalToRefuse(null);
    } catch (err) {
      setErrorMessage('Error while updating withdrawal');
    } finally {
      setLoading(false);
    }
  };

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchQuery(e.target.value);
  };

  const onClearSearch = () => setSearchQuery('');

  if (!user) {
    return null;
  }

  const rows = [
    {
      label: t('Profile picture'),
      type: 'profilePicture',
      value: (
        <Avatar
          src={user.profilePictureUrl}
          contentCreatorName={user.contentCreatorName}
        />
      ),
      notEditable: true,
    },
    {
      label: t('Username'),
      type: 'username',
      value: user.contentCreatorName,
      notEditable: true,
    },
    {
      label: t('OBS link'),
      type: 'obsURL',
      value: undefined,
      notEditable: true,
    },
    {
      label: t('Donation link'),
      type: 'donationLink',
      notEditable: true,
      value: (() => {
        const link = `${process.env.REACT_APP_UI_URL}${DONATE_PATH}/${user.contentCreatorName}`;
        return (
          <Stack direction="row" alignItems="center">
            <Box mr={2}>
              <MuiLink href={link} underline="hover" target="_blank">
                {link}
              </MuiLink>
              {'  '}
            </Box>

            <CopyButton value={link} />
          </Stack>
        );
      })(),
    },
    {
      label: t('Social networks'),
      type: 'socialNetworks',
      notEditable: true,
      value: (
        <>
          {user?.socialNetworks.map(({ value, url }) => {
            return (
              <IconButton
                edge="start"
                sx={{ marginRight: 2 }}
                href={url}
                target="_blank"
              >
                {SOCIAL_NETWORKS_MEDIA_MAP[value].icon}
              </IconButton>
            );
          })}
        </>
      ),
    },
    {
      label: t('Minimum donation amount'),
      type: 'minimumDonationAmount',
      notEditable: true,
      value: (
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={1}>
          {Object.keys(user?.minimumDonationAmount || {}).map((key) => {
            const paymentMethod = key as PaymentMethodTypes;
            return (
              <Chip
                key={key}
                label={`${
                  PAYMENT_METHOD_MEDIA_MAP[paymentMethod].shortLabel
                } ${formatCurrency(
                  user.minimumDonationAmount[paymentMethod],
                  PaymentMethodsCurrencyMap[paymentMethod],
                )}`}
                variant="outlined"
              />
            );
          })}
        </Box>
      ),
    },
  ];

  const renderEditForm = (fieldToEdit: string) => {
    return null;
  };

  return (
    <Box p="0 4rem 0 4rem" sx={{ overflowY: 'auto', height: '100%' }}>
      <Box>
        <StylePageHeader mt={12} mb={4}>
          <>
            <Typography variant="h1" mr={2}>
              {user.contentCreatorName}
            </Typography>
            <Button
              label="Delete user"
              variant="text"
              color="error"
              endIcon={<TrashIcon />}
              onClick={() => setUserToDelete(user)}
            />
          </>
        </StylePageHeader>
      </Box>
      {withdrawalToRemove && (
        <ConfirmationDialog
          open={!!withdrawalToRemove}
          text={`Are you sure you want to remove ${withdrawalToRemove._id}?`}
          confirmText="Remove"
          onConfirm={() => onRemoveWithdrawalConfirmation(withdrawalToRemove)}
          onClose={() => setWithdrawalToRemove(null)}
        />
      )}
      {withdrawalToProcess && (
        <ConfirmationDialog
          open={!!withdrawalToProcess}
          text={`Are you sure you want to process ${
            withdrawalToProcess._id
          } with amount ${formatCurrency(
            withdrawalToProcess.amount,
            PaymentMethodsCurrencyMap[withdrawalToProcess.paymentMethod],
          )}? It can not be undone.`}
          confirmText="Process"
          color="primary"
          onConfirm={() => onProcessWithdrawalConfirmation(withdrawalToProcess)}
          onClose={() => setWithdrawalToProcess(null)}
        />
      )}
      {withdrawalToRefuse && (
        <ConfirmationDialog
          open={!!withdrawalToRefuse}
          text={`Are you sure you want to refuse ${
            withdrawalToRefuse._id
          } with amount ${formatCurrency(
            withdrawalToRefuse.amount,
            PaymentMethodsCurrencyMap[withdrawalToRefuse.paymentMethod],
          )}?`}
          confirmText="Refuse"
          onConfirm={() => onRefuseWithdrawalConfirmation(withdrawalToRefuse)}
          onClose={() => setWithdrawalToRefuse(null)}
        />
      )}
      {fieldToEdit ? (
        renderEditForm(fieldToEdit)
      ) : (
        <>
          <Accordion
            defaultExpanded={true}
            disableGutters={true}
            square={true}
            summary="Information"
            children={
              <List>
                {rows.map(({ type, label, value = '', notEditable }, index) => {
                  return (
                    <UserInfoRow
                      key={type}
                      type={type}
                      label={label}
                      value={value}
                      notEditable={notEditable}
                      divider={rows.length !== index + 1}
                      onClick={() => setFieldToEdit(type)}
                    />
                  );
                })}
              </List>
            }
          />

          <Accordion
            defaultExpanded={true}
            disableGutters={true}
            square={true}
            summary="Withdrawals"
            children={
              <Box mb={8}>
                <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                  <Search
                    size="small"
                    name="withdrawals"
                    value={searchQuery}
                    label="Search"
                    onChange={onSearchChange}
                    onClear={onClearSearch}
                  />
                </Box>
                <WithdrawalsTable
                  setWithdrawalToProcess={setWithdrawalToProcess}
                  setWithdrawalToRefuse={setWithdrawalToRefuse}
                  onRemoveWithdrawal={(withdrawal: Withdrawal) =>
                    setWithdrawalToRemove(withdrawal)
                  }
                  loading={loading}
                  data={withdrawalsData}
                  page={page}
                  sorting={sorting}
                  onPageChange={handleChangePage}
                  setSorting={setSorting}
                />
              </Box>
            }
          />
        </>
      )}
    </Box>
  );
}
