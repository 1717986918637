import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { black54 } from 'view/theme/colors';

export const StyledLabel = styled(Typography)`
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 24px;
  font-size: 0.75rem;
  text-transform: uppercase;
  color: ${black54};
`;
