import { request } from 'services/api/request';
import { Statistics } from 'types/statistics';

export const getStatistics = async (
  url = '/statistics',
): Promise<Statistics> => {
  const { data } = await request({
    url,
    withCredentials: true,
  });

  return data;
};
