import React, { ReactElement } from 'react';
import { StyledDrawer } from './styled';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  action: ReactElement;
  width?: string;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  marginTop?: string;
  height: string;
  anchor?: 'right' | 'left' | 'top' | 'bottom';
};

export default function Drawer({
  open: propOpen,
  onClose,
  action,
  width,
  variant,
  marginTop,
  height,
  anchor = 'left',
}: DrawerProps) {
  return (
    <StyledDrawer
      onClose={() => {
        onClose();
      }}
      variant={variant}
      anchor={anchor}
      PaperProps={{
        sx: {
          width: width,
          overflow: 'visible',
          marginTop: marginTop,
          height: height,
        },
      }}
      open={propOpen}
    >
      {action}
    </StyledDrawer>
  );
}
