import styled from '@emotion/styled';
import MuiChip from '@mui/material/Chip';
import {
  green,
  lightGreen,
  red,
  lightRed,
  lightGray,
  black54,
  primary,
  yellow,
  lightYellow,
  lightPrimary,
} from 'view/theme/colors';
import { MouseEventHandler, ReactElement } from 'react';

export const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    border-radius: 0.25rem;
    margin: 0 0 0 0.25rem;
  }

  &.MuiChip-sizeSmall {
    font-size: 0.75rem;
  }

  &.MuiChip-filledPrimary {
    color: ${primary};
    background: ${lightPrimary};
  }

  &.MuiChip-filledSuccess {
    color: ${green};
    background: ${lightGreen};
  }

  &.MuiChip-filledWarning {
    color: ${yellow};
    background: ${lightYellow};
  }

  &.MuiChip-filledError {
    color: ${red};
    background: ${lightRed};
  }

  &.MuiChip-filledSecondary {
    border-radius: 10px;
    color: ${black54};
    background: ${lightGray};
  }
`;

type ChipProps = {
  label: string | number | undefined;
  color?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | any;
  size?: 'medium' | 'small';
  icon?: ReactElement;
  transform?: boolean;
  onClick?: MouseEventHandler<HTMLElement>;
  onMouseEnter?: MouseEventHandler<HTMLElement>;
  onMouseLeave?: MouseEventHandler<HTMLElement>;
};

export default function Chip({
  label,
  color,
  size,
  icon,
  onClick,
  transform,
  onMouseEnter,
  onMouseLeave,
}: ChipProps) {
  return (
    <StyledChip
      icon={icon}
      onClick={onClick}
      label={label}
      color={color}
      size={size}
      sx={{ textTransform: transform ? 'uppercase' : null }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
}
