import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ANIMATION_OPTIONS,
  EXAMPLE_DONATION,
  DEFAULT_PICTURE_URL,
  OPTION_SELECT,
} from 'view/constants';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { updateUserData } from 'services/api/user';
import Grid from '@mui/material/Grid';
import DonationComponent from 'view/components/DonationComponent';
import { AnimatePresence } from 'framer-motion';
import useUser from 'hooks/user';
import { ChangeDonationAnimationFormatValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';

type ChangeDonationAnimationFormatFormData = {
  start: string;
  end: string;
  letters: string;
};

type ChangeDonationDesignProps = {
  onCancel: () => void;
  onSaved: (user: User) => void;
};

export default function ChangeDonationDesign({
  onCancel,
  onSaved,
}: ChangeDonationDesignProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();
  const { start, end } = user.donationAnimationFormat;

  const [isVisible, setIsVisible] = useState(true);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setDonation(null);
  //
  //     setTimeout(() => {
  //       setDonation(exampleDonation);
  //     }, 5000);
  //   }, 5000);
  //
  //   return () => clearInterval(intervalId);
  // }, []);

  const initialFormData = {
    resolver: yupResolver(ChangeDonationAnimationFormatValidationSchema),
    defaultValues: user.donationAnimationFormat,
  };

  const {
    control,
    handleSubmit,
    watch,
    getValues,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangeDonationAnimationFormatFormData>(initialFormData);

  const onSubmit = async (data: ChangeDonationAnimationFormatFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, { donationAnimationFormat: data });
      onSaved({ ...user, donationAnimationFormat: data });
      reset(data);
      setSuccessMessage(t('Successfully updated donation animation'));
    } catch (err) {
      setErrorMessage(t('Error while updating donation animation format'));
    }
  };

  const isValuesChanged = start !== watch('start') || end !== watch('end');
  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isValuesChanged || isSubmitting;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Donation design')}
        description={t('Change your donation design')}
      />
      <Grid container spacing={8}>
        <Grid item xs={12} md={5}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Box mb={2}>
                <Input
                  select
                  name="start"
                  type="text"
                  label="Start animation"
                  fullWidth
                  control={control}
                  SelectProps={{
                    native: true,
                  }}
                >
                  {ANIMATION_OPTIONS.map((option: OPTION_SELECT) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Input>
              </Box>
              <Input
                select
                name="end"
                type="text"
                label="End animation"
                fullWidth
                control={control}
                SelectProps={{
                  native: true,
                }}
              >
                {ANIMATION_OPTIONS.map((option: OPTION_SELECT) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Input>
              {/*<Input*/}
              {/*  select*/}
              {/*  name="letters"*/}
              {/*  label="Letters animation"*/}
              {/*  control={control}*/}
              {/*  fullWidth*/}
              {/*  SelectProps={{*/}
              {/*    native: true,*/}
              {/*  }}*/}
              {/*>*/}
              {/*  {LETTER_ANIMATION_OPTIONS.map((option) => (*/}
              {/*    <option key={option.value} value={option.value}>*/}
              {/*      {option.label}*/}
              {/*    </option>*/}
              {/*  ))}*/}
              {/*</Input>*/}
            </Box>
            <StyledFormActions>
              <Button
                type="submit"
                label={t('Save')}
                loading={isSubmitting}
                disabled={submitDisabled}
              />
              <Button
                label={t('Back')}
                variant="text"
                className="cancel-button"
                onClick={onCancel}
              />
              <Button
                variant="outlined"
                label={t('Refresh')}
                onClick={() => setIsVisible(!isVisible)}
              />
            </StyledFormActions>
          </form>
        </Grid>
        <Grid item xs={12} md={7} minHeight="300px">
          <AnimatePresence>
            {isVisible && (
              <DonationComponent
                imageSrc={DEFAULT_PICTURE_URL || ''}
                animationFormat={getValues()}
                donation={EXAMPLE_DONATION}
              />
            )}
          </AnimatePresence>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
