import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from 'view/components/Loader';
import { ADMIN_APP_PATH, APP_PATH } from 'view/routes';
import useAuth from 'hooks/auth';
import useUser from 'hooks/user';
import { UserRole } from 'types/users';

type Props = {
  children: ReactElement;
};

export default function PrivateRoute({ children }: Props) {
  const { isLoading: isAuthLoading, isAuthenticated } = useAuth();
  const { isLoading, user } = useUser();
  const isAdmin = user?.role === UserRole.ADMIN;

  if (isAuthLoading || isLoading) {
    return <Loader height="100vh" />;
  }

  if (!isAuthenticated) {
    return <Navigate to={APP_PATH} />;
  }

  if (isAdmin) {
    return <Navigate to={ADMIN_APP_PATH} />;
  }

  return children;
}
