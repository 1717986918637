import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import Box from '@mui/material/Box';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import useUser from 'hooks/user';
import { ChangeContentCreatorNameValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';

type ChangeContentCreatorNameFormData = {
  contentCreatorName: string;
};

type ChangeUsernameProps = {
  onCancel: () => void;
  onSaved: ({ contentCreatorName }: Pick<User, 'contentCreatorName'>) => void;
};

export default function ChangeUsername({
  onCancel,
  onSaved,
}: ChangeUsernameProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(ChangeContentCreatorNameValidationSchema(t)),
    defaultValues: { contentCreatorName: user.contentCreatorName },
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<ChangeContentCreatorNameFormData>(initialFormData);

  const onSubmit = async (data: ChangeContentCreatorNameFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, data);
      onSaved(data);
      reset(data);
      setSuccessMessage(t('Successfully updated username'));
    } catch (err) {
      setErrorMessage(t('Error while updating username'));
    }
  };

  const submitDisabled = !isDirty || isSubmitting;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Username')}
        description={t(
          'Change your username. It is used for the donation link and should be unique',
        )}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box width="20rem">
          <Input
            name="contentCreatorName"
            label={t('Username')}
            type="text"
            error={!!errors.contentCreatorName?.message}
            helperText={errors.contentCreatorName?.message}
            control={control}
            mb={2}
          />
        </Box>
        <StyledFormActions>
          <Button
            type="submit"
            label={t('Save')}
            loading={isSubmitting}
            disabled={submitDisabled}
          />
          <Button
            label={t('Back')}
            variant="text"
            className="cancel-button"
            onClick={onCancel}
          />
        </StyledFormActions>
      </form>
    </StyledFormContainer>
  );
}
