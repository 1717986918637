import { Theme } from '@nivo/core';
import {
  black,
  black16,
  black54,
  black71,
  black87,
  white,
} from 'view/theme/colors';

export const nivoTheme = (isDarkMode: boolean): Theme => ({
  text: {
    fontFamily: `"Manrope", "Helvetica", "Arial", sans-serif`,
    fontSize: 16,
    fill: isDarkMode ? black87 : black,
    outlineWidth: 0,
    outlineColor: '',
    outlineOpacity: 0,
  },
  axis: {
    domain: {
      line: {
        stroke: black54,
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: black54,
        strokeWidth: 1,
      },
      text: {
        fontSize: 12,
        fill: isDarkMode ? white : black87,
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: isDarkMode ? white : black87,
      },
    },
  },
  grid: {
    line: {
      stroke: isDarkMode ? black71 : black16,
      strokeWidth: 1,
    },
  },
  legends: {
    text: {
      fontSize: 12,
      fill: isDarkMode ? white : black87,
    },
  },
  tooltip: {
    wrapper: {
      background: isDarkMode ? black87 : white,
      padding: 8,
      borderRadius: 3,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      border: `1px solid ${black16}`,
    },
  },
  crosshair: {
    line: {
      stroke: isDarkMode ? white : black87,
    },
  },
});
