import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import DonationsList from 'view/pages/DonationBook/components/DonationsList';
import PageHeader from 'view/components/PageHeader';
import Loader from 'view/components/Loader';
import Search from 'view/components/Search';
import { Donation } from 'types/donations';
import { StyledContainer } from './styled';
import { PaginatedResponse } from 'services/api/types';
import debounce from 'lodash.debounce';
import useUser from 'hooks/user';
import { listDonations, listSearchDonations } from 'services/api/donations';
import { StyledChip } from 'view/components/Chip';
import useSocket from 'hooks/socket';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';

export default function DonationsBook() {
  const { setErrorMessage } = useAuth();
  const { socketIo } = useSocket();
  const { donationsData, setDonationsData, isLoading } = useUser();
  const donationsDataRef = useRef(donationsData);
  const { t } = useTranslation();

  useEffect(() => {
    donationsDataRef.current = donationsData;
  }, [donationsData]);

  const [loading, setLoading] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResult, setSearchResult] = useState<PaginatedResponse<Donation>>(
    {
      items: [] as Donation[],
    } as PaginatedResponse<Donation>,
  );

  const handleDonation = (donation: Donation) => {
    const dataObject = {
      ...donationsDataRef.current,
      items: [donation, ...donationsDataRef.current.items],
      meta: {
        ...donationsDataRef.current.meta,
        totalItems: donationsDataRef.current.meta.totalItems + 1,
        itemCount: donationsDataRef.current.meta.itemCount + 1,
      },
    };
    setDonationsData(dataObject);
  };

  useEffect(() => {
    if (socketIo) {
      socketIo.on('donation', handleDonation);

      return () => {
        socketIo.off('donation');
      };
    }
  }, [socketIo]);

  const fetchNextDonations = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const donationsData = await listDonations(nextLink);
        setDonationsData(donationsData);
      } catch (err) {
        setErrorMessage(t('Error while loading donations'));
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchDonations = useCallback(
    debounce(async (searchQuery: string) => {
      setLoading(true);

      try {
        const searchResult = await listSearchDonations({ search: searchQuery });
        setSearchResult(searchResult);
        setLoading(false);
      } catch (err) {
        setErrorMessage(t('Error while searching for donations'));
        setLoading(false);
      }
    }, 500),
    [],
  );

  const fetchNextSearchDonations = async (nextLink?: string) => {
    if (nextLink) {
      try {
        const searchResult = await listSearchDonations({
          url: nextLink,
          search: searchQuery,
        });
        setSearchResult(({ items }) => ({
          ...searchResult,
          items: [...items, ...searchResult.items],
        }));
      } catch (err) {
        setErrorMessage(t('Error while loading donations'));
      }
    }
  };

  useEffect(() => {
    if (searchQuery) {
      setLoading(true);
      searchDonations(searchQuery);
    }
  }, [searchQuery, searchDonations]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearchQuery(e.target.value);

  const onClearSearch = () => setSearchQuery('');

  const totalDonations = searchQuery ? searchResult?.meta?.totalItems : 0;

  return (
    <StyledContainer component="main">
      <PageHeader
        title={t('donations')}
        endAdornment={
          !!totalDonations && <StyledChip label={totalDonations} size="small" />
        }
        action={
          <Box sx={{ width: '20rem' }}>
            <Search
              name="donations-search"
              size="small"
              value={searchQuery}
              label={t('searchDonations')}
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </Box>
        }
      />
      {(() => {
        if (loading || isLoading) {
          return <Loader justify="flex-start" />;
        }
        if (searchQuery) {
          return (
            <DonationsList
              donations={searchResult?.items}
              fetchNextDonations={() =>
                fetchNextSearchDonations(searchResult?.links?.next)
              }
              allDonationsLoaded={!!searchResult?.links?.next}
            />
          );
        }
        return (
          <DonationsList
            donations={donationsData?.items}
            fetchNextDonations={() =>
              fetchNextDonations(donationsData?.links?.next)
            }
            allDonationsLoaded={!!donationsData?.links?.next}
          />
        );
      })()}
    </StyledContainer>
  );
}
