import axios from 'axios';

export const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
