import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import RedeemIcon from '@mui/icons-material/Redeem';
import { DonateValidationSchema } from 'constraints';
import { StyledFormContainer, StyledPageContainer } from './styled';
import TextArea from 'view/components/TextArea';
import {
  getExchangeRates,
  postCryptomusPaymentIntent,
  postWayforpayPaymentIntent,
  postWhitePayPaymentIntent,
} from 'services/api/donations';
import { TERMS_PATH } from 'view/routes';
import Link from 'view/components/Link';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { getDonationConfig } from 'services/api/user';
import { DonationThresholdConfig, PaymentMethodTypes, User } from 'types/users';
import Skeleton from '@mui/material/Skeleton';
import FormControlLabel from '@mui/material/FormControlLabel';
import AmountBox from 'view/pages/Donate/components/AmountBox';
import PaymentBox from 'view/pages/Donate/components/PaymentBox';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {
  createHiddenInput,
  formatCurrency,
  getAmount,
  getIsVoiceOverAllowed,
  handleDonationThresholdConfig,
  isCurrentThresholdBox,
} from 'tools';
import {
  Currency,
  ExchangeRate,
  PaymentMethodsCurrencyMap,
  WayforpayPaymentIntentResponse,
} from 'services/api/types';
import Avatar from 'view/components/Avatar';
import { SOCIAL_NETWORKS_MEDIA_MAP } from 'view/pages/AccountSettings/components/ChangeSocialNetworks';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import {
  DEFAULT_VOICE_NAME,
  VOICE_OPTIONS,
  getVoicesConfig,
} from 'view/constants';
import AudioPreview from 'view/components/AudioPreview';
import { primary } from 'view/theme/colors';

type DonateFormData = {
  message: string;
  sponsorName: string;
  amount: number;
  paymentMethod: PaymentMethodTypes | null;
  voice: string;
  currency: Currency | null;
  goalId: string;
};

export default function DonatePage() {
  const { setErrorMessage } = useAuth();
  const { t } = useTranslation();
  const { contentCreatorName = '' } = useParams();
  const VOICES_CONFIG = getVoicesConfig(t);

  const [loading, setLoading] = useState(true);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(true);
  const [isGoalActive, setIsGoalActive] = useState<boolean>(true);

  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);
  const [donationConfig, setDonationConfig] = useState<
    | (Pick<
        User,
        | 'minimumDonationAmount'
        | 'minimumVoiceOverThreshold'
        | 'profilePictureUrl'
        | 'donationThresholdConfig'
        | 'socialNetworks'
        | 'goals'
      > & {
        paymentMethods: PaymentMethodTypes[];
      })
    | null
  >(null);
  const [minimumDonationAmount, setMinimumDonationAmount] = useState<number>(0);
  const [currency, setCurrency] = useState<Currency | null>(null);

  const initialFormData = {
    resolver: yupResolver(
      DonateValidationSchema(t, minimumDonationAmount, currency),
    ),
    defaultValues: {
      message: '',
      sponsorName: '',
      amount: minimumDonationAmount,
      paymentMethod: null,
      voice: DEFAULT_VOICE_NAME,
      currency: null,
      goalId: '',
    },
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<DonateFormData>(initialFormData);
  // const [key, setStore] = useShadow('key', 'value');
  const amount = watch('amount');
  const paymentMethod = watch('paymentMethod');
  const voice = watch('voice');

  const isVoiceOverAllowed = getIsVoiceOverAllowed(
    amount,
    donationConfig?.minimumVoiceOverThreshold,
    currency,
    exchangeRates,
  );

  const voiceHelperText = () => {
    if (
      !donationConfig?.minimumVoiceOverThreshold ||
      !currency ||
      !exchangeRates
    ) {
      return '';
    }
    if (donationConfig?.minimumVoiceOverThreshold === -1) {
      return t('The user disabled donations voice-over');
    }
    if (donationConfig?.minimumVoiceOverThreshold === 0) {
      return '';
    }

    const amountWithCurrency = getAmount(
      donationConfig.minimumVoiceOverThreshold,
      currency,
      exchangeRates,
    );
    if (!amountWithCurrency) {
      return '';
    }
    return t('The minimum size of a donation with voice over {{threshold}}', {
      threshold: formatCurrency(amountWithCurrency, currency),
    });
  };

  const donationThresholdConfig = handleDonationThresholdConfig(
    donationConfig?.donationThresholdConfig as DonationThresholdConfig[],
    currency,
    exchangeRates as ExchangeRate,
  );

  const getMinimumDonationAmount = (method: PaymentMethodTypes) => {
    if (method === PaymentMethodTypes.WHITEPAY) {
      return 5;
    }
    if (method === PaymentMethodTypes.CRYPTOMUS) {
      return 0.1;
    }
    return (
      donationConfig?.minimumDonationAmount[PaymentMethodTypes.WHITEPAY] || 10
    );
  };

  useEffect(() => {
    const fetchDonationConfig = async () => {
      if (contentCreatorName) {
        try {
          const config = await getDonationConfig(contentCreatorName);
          const rates = await getExchangeRates();
          setDonationConfig(config);
          setExchangeRates(rates);
          setValue(
            'amount',
            config.minimumDonationAmount[PaymentMethodTypes.WAYFORPAY] || 10,
          );

          const method = config.paymentMethods?.at(0);

          if (method) {
            setValue('paymentMethod', method);
            setValue('currency', PaymentMethodsCurrencyMap[method]);
            setCurrency(PaymentMethodsCurrencyMap[method]);
            setMinimumDonationAmount(getMinimumDonationAmount(method));
          }

          if (config.goals.length) {
            const [firstGoal] = config.goals;
            setValue('goalId', firstGoal._id);
          }
        } catch (err) {
          setErrorMessage(t('Error while loading user data'));
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDonationConfig();
  }, []);

  const onSubmit = async (formData: DonateFormData) => {
    setErrorMessage('');

    switch (formData.paymentMethod) {
      case PaymentMethodTypes.WAYFORPAY:
        return processWayforpayPayment(formData);
      case PaymentMethodTypes.WHITEPAY:
        return processWhitePayPayment(formData);
      case PaymentMethodTypes.CRYPTOMUS:
        return processCryptomusPayment(formData);
      // case PaymentMethodTypes.LIQPAY:
      //   return processLiqPayPayment(formData);
    }
  };

  const processWhitePayPayment = async (formData: DonateFormData) => {
    const {
      message,
      amount,
      sponsorName,
      voice,
      currency,
      paymentMethod,
      goalId,
    } = formData;

    if (!currency || !paymentMethod) {
      return setErrorMessage(t('Error while donation'));
    }

    const data = {
      message,
      sponsorName,
      contentCreatorName,
      amount,
      paymentMethod,
      voice,
      currency,
      goalId,
    };

    try {
      const { paymentUrl } = await postWhitePayPaymentIntent(data);
      window.location.href = paymentUrl;
    } catch (err) {
      setErrorMessage(t('Error while donation'));
    }
  };

  const processCryptomusPayment = async (formData: DonateFormData) => {
    const {
      message,
      amount,
      sponsorName,
      voice,
      currency,
      paymentMethod,
      goalId,
    } = formData;

    if (!currency || !paymentMethod) {
      return setErrorMessage(t('Error while donation'));
    }

    const data = {
      message,
      sponsorName,
      contentCreatorName,
      paymentMethod,
      amount,
      voice,
      currency,
      goalId,
    };

    try {
      const { paymentUrl } = await postCryptomusPaymentIntent(data);
      window.location.href = paymentUrl;
    } catch (err) {
      setErrorMessage(t('Error while donation'));
    }
  };

  const processWayforpayPayment = async (formData: DonateFormData) => {
    const {
      message,
      amount,
      sponsorName,
      voice,
      currency,
      paymentMethod,
      goalId,
    } = formData;
    if (!currency || !paymentMethod) {
      return setErrorMessage(t('Error while donation'));
    }

    const voicePrice = VOICES_CONFIG[voice].price[currency];

    const data = {
      message,
      sponsorName,
      contentCreatorName,
      amount,
      paymentMethod,
      voice,
      currency,
      goalId,
      productName: [
        'Послуги програмного забезпечення',
        ...(voicePrice ? ['Преміум озвучка'] : []),
      ],
      productCount: [1, ...(voicePrice ? [1] : [])],
      productPrice: [amount, ...(voicePrice ? [voicePrice] : [])],
    };

    try {
      const response = await postWayforpayPaymentIntent(data);
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://secure.wayforpay.com/pay';

      const keysMap = Object.keys(response) as Array<
        keyof WayforpayPaymentIntentResponse
      >;

      keysMap.forEach((name) => {
        const value = response[name];

        if (Array.isArray(value)) {
          value.forEach((item) => {
            const input = createHiddenInput(`${name}[]`, item);
            form.appendChild(input);
          });
        } else {
          const input = createHiddenInput(name, value);
          form.appendChild(input);
        }
      });
      document.body.appendChild(form);
      form.submit();
    } catch (err) {
      setErrorMessage(t('Error while donation'));
    }
  };

  // const processLiqPayPayment = async (formData: DonateFormData) => {
  //   const { message, amount, sponsorName, voice } = formData;
  //   const voicePrice = VOICES_CONFIG[voice].price;
  //
  //   try {
  //     const response = await postLiqpayPaymentIntent({
  //       message,
  //       amount,
  //       sponsorName,
  //       contentCreatorName,
  //       voicePrice,
  //     });
  //
  //     const { data, signature } = response;
  //
  //     const form = document.createElement('form');
  //     form.method = 'POST';
  //     form.action = 'https://www.liqpay.ua/api/3/checkout';
  //
  //     const inputData = createHiddenInput('data', data);
  //     const inputSignature = createHiddenInput('signature', signature);
  //
  //     form.appendChild(inputData);
  //     form.appendChild(inputSignature);
  //
  //     document.body.appendChild(form);
  //     form.submit();
  //   } catch (err) {
  //     setErrorMessage(t('Error while donation'));
  //   }
  // };

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const method = event.target.value as PaymentMethodTypes;
    setValue('paymentMethod', method);
    const minimumAmount = getMinimumDonationAmount(method);
    setValue('amount', minimumAmount < amount ? amount : minimumAmount);
    setValue('currency', PaymentMethodsCurrencyMap[method]);
    setCurrency(PaymentMethodsCurrencyMap[method]);
    setMinimumDonationAmount(minimumAmount);
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked);
  };

  const handleGoalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (donationConfig?.goals?.length && event.target.checked) {
      const [firstGoal] = donationConfig.goals;
      setValue('goalId', firstGoal._id);
    } else {
      setValue('goalId', '');
    }

    setIsGoalActive(event.target.checked);
  };

  const noUserData = !donationConfig && !loading;

  return (
    <StyledPageContainer mt={{ xs: 8, md: 10 }}>
      <StyledFormContainer>
        {donationConfig?.socialNetworks.length ? (
          <Box display="flex" flexDirection="row-reverse" mb={1}>
            {donationConfig?.socialNetworks.map(({ value, url }) => {
              return (
                <IconButton
                  key={value}
                  sx={{ marginRight: 0.5 }}
                  href={url}
                  target="_blank"
                >
                  {SOCIAL_NETWORKS_MEDIA_MAP[value].icon}
                </IconButton>
              );
            })}
          </Box>
        ) : null}

        <Box display="flex" alignItems="center" mb={3}>
          <Box mr={2}>
            <Avatar
              size={4}
              src={donationConfig?.profilePictureUrl}
              contentCreatorName={noUserData ? '?' : contentCreatorName}
            />
          </Box>
          <Box>
            <Typography variant="h2">
              {noUserData
                ? t('userDoesNotExist', {
                    contentCreatorName: contentCreatorName.toUpperCase(),
                  })
                : `${t('Donate to')} ${contentCreatorName.toUpperCase()}`}
            </Typography>
            <Typography variant="body2">
              {t('The world will see your message')}
            </Typography>
          </Box>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb={3}>
            <TextArea
              name="message"
              label={t('Your message')}
              type="text"
              control={control}
              rows={2}
              disabled={isSubmitting || noUserData}
              error={!!errors.message?.message}
              helperText={errors.message?.message}
            />
          </Box>
          <Input
            name="sponsorName"
            label={t('Your name')}
            type="text"
            disabled={isSubmitting || noUserData}
            error={!!errors.sponsorName?.message}
            helperText={errors.sponsorName?.message}
            control={control}
            mb={2}
          />
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Input
                name="amount"
                label={t('How much do you wish to donate?')}
                error={!!errors.amount?.message}
                type="number"
                helperText={errors.amount?.message}
                disabled={loading || !donationConfig || isSubmitting}
                control={control}
                startAdornment={
                  <Typography variant="body2" sx={{ color: primary }}>
                    {currency || '?'}
                  </Typography>
                }
                mb={2}
              />
            </Grid>
            <Grid item xs={7}>
              <Input
                select
                name="voice"
                helperText={voiceHelperText()}
                label={t('Voice')}
                type="text"
                disabled={!currency || !isVoiceOverAllowed}
                fullWidth
                startAdornment={
                  <AudioPreview
                    soundUrl={VOICES_CONFIG[voice].exampleUrl}
                    disabled={!currency || !isVoiceOverAllowed}
                  />
                }
                control={control}
                SelectProps={{
                  native: true,
                }}
              >
                {currency ? (
                  VOICE_OPTIONS(currency, t).map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))
                ) : (
                  <option>{t('No data')}</option>
                )}
              </Input>
            </Grid>
          </Grid>

          {donationConfig?.donationThresholdConfig.length ? (
            <Typography variant="body2" mb={1}>
              {t(
                'Make a donation from a certain amount to get a special notification and picture',
              )}
            </Typography>
          ) : null}

          <Box display="inline-flex" flexWrap="wrap" gap={1} mb={2}>
            {loading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={150}
                  height={50}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={150}
                  height={50}
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={150}
                  height={50}
                />
              </>
            ) : (
              donationThresholdConfig
                ?.sort((a, b) => a.threshold - b.threshold)
                .map((thresholdConfig, index) => {
                  const isCurrent = isCurrentThresholdBox(
                    amount,
                    thresholdConfig.threshold,
                    donationThresholdConfig,
                  );
                  return (
                    <AmountBox
                      key={thresholdConfig.threshold}
                      amount={thresholdConfig.threshold}
                      isCurrent={isCurrent}
                      pictureUrl={thresholdConfig.pictureUrl}
                      soundUrl={thresholdConfig.soundUrl}
                      onClick={() =>
                        setValue('amount', thresholdConfig.threshold)
                      }
                    />
                  );
                })
            )}
          </Box>
          {donationConfig?.goals?.length ? (
            <>
              <Typography variant="body2" mb={1}>
                {t('Select the collection goal')}
              </Typography>
              <Grid container spacing={1} mb={2}>
                <Grid item display="flex" alignItems="center" xs={12}>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isGoalActive}
                          disabled={isSubmitting}
                          onChange={handleGoalChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label=""
                    />
                  </Box>
                  <Box width="100%">
                    <Input
                      select
                      name="goalId"
                      helperText={voiceHelperText()}
                      label=""
                      type="text"
                      disabled={!isGoalActive}
                      fullWidth
                      control={control}
                      SelectProps={{
                        native: true,
                        size: 'small',
                      }}
                    >
                      {[...donationConfig.goals].map((goal) => (
                        <option key={goal._id} value={goal._id}>
                          {goal.label}.{' '}
                          {t('AccumulatedOption', {
                            accumulated: formatCurrency(
                              goal.accumulated,
                              goal.currency,
                            ),
                            finish: formatCurrency(goal.finish, goal.currency),
                          })}
                        </option>
                      ))}
                    </Input>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : null}
          <Divider sx={{ mb: 1 }} />
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="rounded"
                width="100%"
                height={15}
                sx={{ mb: 1 }}
              />
              <Skeleton
                animation="wave"
                variant="rounded"
                width="100%"
                height={30}
                sx={{ mb: 2 }}
              />
            </>
          ) : (
            <>
              <Typography variant="body2" mb={1}>
                {`${t(
                  donationConfig?.paymentMethods.length
                    ? 'Choose a payment method'
                    : "User hasn't added any payment methods yet",
                )}`}
              </Typography>
              {donationConfig?.paymentMethods.length ? (
                <Stack mb={2}>
                  <RadioGroup
                    sx={{ flexDirection: 'column', gap: 1 }}
                    aria-label="payment"
                    name="payment"
                    value={paymentMethod}
                    onChange={handlePaymentChange}
                  >
                    {donationConfig?.paymentMethods.map((payment) => {
                      return <PaymentBox key={payment} payment={payment} />;
                    })}
                  </RadioGroup>
                </Stack>
              ) : null}
            </>
          )}

          <Box mb={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTermsAccepted}
                  disabled={isSubmitting || noUserData}
                  onChange={handleTermsChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={
                <Typography variant="body2">
                  {t('By clicking «Donate», you agree to')}{' '}
                  <Link to={TERMS_PATH} label={t('Terms of use')} />
                </Typography>
              }
            />
          </Box>

          <Box>
            <Button
              type="submit"
              label={t('Donate')}
              disabled={
                !isTermsAccepted || !paymentMethod || loading || !donationConfig
              }
              withLoader
              loading={isSubmitting || loading}
              startIcon={<RedeemIcon />}
              fullWidth
            />
          </Box>
        </form>
      </StyledFormContainer>
    </StyledPageContainer>
  );
}
