import { useState, useEffect, ReactElement, createContext } from 'react';
import Alert from 'view/components/Alert';
import { checkIsAuthenticated as checkIsAuthenticatedApi } from 'services/api/user';
import { useTranslation } from 'react-i18next';

export type AuthData = {
  isAuthenticated: boolean;
  isLoading: boolean;
};

export const AuthContext = createContext({
  isAuthenticated: false,
  isLoading: true,
  signOut: () => {},
  setSuccessMessage: (message: string) => {},
  setErrorMessage: (message: string) => {},
});

type Props = {
  children: ReactElement;
};

export const AuthProvider = ({ children }: Props) => {
  const { t } = useTranslation();

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [authData, setAuthData] = useState<AuthData>({
    isAuthenticated: false,
    isLoading: true,
  });

  const checkIsAuthenticated = async () => {
    try {
      const { isAuthenticated } = await checkIsAuthenticatedApi();

      setAuthData((prevState) => ({
        ...prevState,
        isAuthenticated,
        isLoading: false,
      }));
    } catch (err) {}
  };

  const signOut = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/logout`;
    } catch (err) {
      setErrorMessage(t('Error while signing out'));
    }
  };

  useEffect(() => {
    checkIsAuthenticated();
  }, []);

  const value = { ...authData, setErrorMessage, setSuccessMessage, signOut };

  return (
    <AuthContext.Provider value={value}>
      <Alert
        open={!!successMessage}
        type="success"
        label={successMessage}
        position="right"
        onClose={() => setSuccessMessage('')}
      />
      <Alert
        open={!!errorMessage}
        type="error"
        label={errorMessage}
        onClose={() => setErrorMessage('')}
      />
      {children}
    </AuthContext.Provider>
  );
};
