import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loader({ height = 'auto', justify = 'center' }) {
  return (
    <Box
      display="flex"
      justifyContent={justify}
      alignItems="center"
      width="100%"
      height={height}
    >
      <CircularProgress />
    </Box>
  );
}
