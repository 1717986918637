import React, { createContext, useState, ReactElement, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { lightTheme, darkTheme } from 'view/theme';

type Props = {
  children: ReactElement;
};

interface ThemeContextProps {
  isDarkMode: boolean;
  toggleTheme: () => void;
}

export const ThemeContext = createContext<ThemeContextProps>({
  isDarkMode: false,
  toggleTheme: () => {},
});

export const ThemeProviderWrapper = ({ children }: Props) => {
  const preferDarkTheme = useMediaQuery('(prefers-color-scheme: dark)');

  const [isDarkMode, setIsDarkMode] = useState<boolean>(() => {
    const localValue = localStorage.getItem('isDarkMode');
    if (localValue) {
      return localValue !== 'false';
    }
    return preferDarkTheme;
  });

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newValue = !prevMode;
      if (newValue !== preferDarkTheme) {
        localStorage.setItem('isDarkMode', JSON.stringify(!prevMode));
      } else {
        localStorage.removeItem('isDarkMode');
      }

      return !prevMode;
    });
  };

  useEffect(() => {
    const localValue = localStorage.getItem('isDarkMode');
    if (!localValue) {
      return setIsDarkMode(preferDarkTheme);
    }
    const isLocalDarkMode = localValue === 'true';
    if (isLocalDarkMode === preferDarkTheme) {
      setIsDarkMode(preferDarkTheme);
      localStorage.removeItem('isDarkMode');
    }
  }, [preferDarkTheme]);

  const theme = isDarkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
