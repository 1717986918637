import { LANGUAGE_OPTIONS, OPTION_SELECT } from 'view/constants';
import Input from 'view/components/Input';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LanguageChange() {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.split('-')[0];

  const handleLanguageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    await i18n.changeLanguage(value);
  };

  return (
    <Input
      select
      id="language"
      fullWidth={false}
      value={currentLanguage}
      variant="standard"
      onChange={handleLanguageChange}
      SelectProps={{
        native: true,
      }}
    >
      {LANGUAGE_OPTIONS.map((option: OPTION_SELECT) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Input>
  );
}
