import styled from '@emotion/styled';
import Box from '@mui/material/Box';

export const StyledPageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
` as typeof Box;

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  max-width: 33rem;
  align-self: center;
`;
