import { request } from '../request';
import { Donation } from 'types/donations';
import {
  ExchangeRate,
  PaginatedResponse,
  PostLiqpayPaymentIntentDTO,
  PostWayforpayPaymentIntentDTO,
  PostWhitePayPaymentIntentDTO,
  WayforpayPaymentIntentResponse,
} from 'services/api/types';

export const postLiqpayPaymentIntent = async (
  donate: PostLiqpayPaymentIntentDTO,
): Promise<{ data: string; signature: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/liqpay',
    data: { ...donate },
  });

  return data;
};

export const postWayforpayPaymentIntent = async (
  formData: PostWayforpayPaymentIntentDTO,
): Promise<WayforpayPaymentIntentResponse> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/wayforpay',
    data: { ...formData },
  });

  return data;
};

export const postWhitePayPaymentIntent = async (
  formData: PostWhitePayPaymentIntentDTO,
): Promise<{ paymentUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/whitepay',
    data: { ...formData },
  });

  return data;
};

export const postCryptomusPaymentIntent = async (
  formData: PostWhitePayPaymentIntentDTO,
): Promise<{ paymentUrl: string }> => {
  const { data } = await request({
    method: 'post',
    url: '/donations/cryptomus',
    data: { ...formData },
  });

  return data;
};

export const listDonations = async (
  url = '/donations',
): Promise<PaginatedResponse<Donation>> => {
  const { data } = await request({
    url,
    withCredentials: true,
  });

  return data;
};

export const listSearchDonations = async ({
  search = '',
  url = '/donations',
}): Promise<PaginatedResponse<Donation>> => {
  const { data } = await request({
    url,
    withCredentials: true,
    params: {
      search,
    },
  });
  return data;
};

export const getExchangeRates = async (): Promise<ExchangeRate> => {
  const { data } = await request({
    url: 'donations/exchange-rate',
    withCredentials: true,
  });

  return data;
};
