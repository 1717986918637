import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { primary, white } from 'view/theme/colors';
import { nivoTheme } from 'view/theme/nivo';
import Typography from '@mui/material/Typography';
import { formatUAHCurrency } from 'tools';
import useTheme from 'hooks/theme';
import { useTranslation } from 'react-i18next';

type BarChartProps = {
  data: Array<{
    sponsorName: string;
    amount: number;
  }>;
};

export default function BarChart({ data }: BarChartProps) {
  const { isDarkMode } = useTheme();
  const { t } = useTranslation();

  return (
    <ResponsiveBar
      theme={nivoTheme(isDarkMode)}
      data={data}
      keys={['amount']}
      indexBy="sponsorName"
      margin={{ top: 50, right: 50, bottom: 50, left: 70 }}
      tooltip={(dataItem) => {
        const { data } = dataItem;
        return (
          <>
            <Typography variant="body1">
              {formatUAHCurrency(data.amount)}
            </Typography>
            <Typography variant="body2">
              {t('Sponsor name')}: {data.sponsorName}
            </Typography>
          </>
        );
      }}
      padding={0.5}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={[primary]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Sponsor name',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Amount',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={white}
      label={({ value }) => Math.round(value || 0).toString()}
      animate={true}
    />
  );
}
