import React from 'react';
import { FieldArray, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Form/Input';
import FormHeader from 'view/components/Form/Header';
import { User } from 'types/users';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import Grid from '@mui/material/Grid';
import useUser from 'hooks/user';
import { ChangeDonationThresholdConfigValidationSchema } from 'constraints';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import AudioPreview from 'view/components/AudioPreview';
import AddIcon from '@mui/icons-material/Add';
import PicturePreview from 'view/components/PicturePreview';

type ChangeDonationThresholdConfigFormData = {
  donationThresholdConfig: Array<{
    _id: string;
    threshold: number;
    soundUrl: string;
    pictureUrl: string;
  }>;
};

type ChangeDonationThresholdConfigProps = {
  onCancel: () => void;
  onSaved: ({
    donationThresholdConfig,
  }: Pick<User, 'donationThresholdConfig'>) => void;
};

const defaultValues = {
  threshold: 50,
  soundUrl: '',
  pictureUrl: '',
  _id: uuidv4(),
};

export default function ChangeDonationThresholdConfig({
  onCancel,
  onSaved,
}: ChangeDonationThresholdConfigProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user } = useUser();
  const { t } = useTranslation();

  const initialFormData = {
    resolver: yupResolver(ChangeDonationThresholdConfigValidationSchema(t)),
    defaultValues: {
      donationThresholdConfig: user.donationThresholdConfig,
    },
  };

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting, isDirty, isValidating },
  } = useForm<ChangeDonationThresholdConfigFormData>(initialFormData);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'donationThresholdConfig',
    keyName: '_id',
  });

  const watchFields = watch('donationThresholdConfig');

  const onSubmit = async (data: ChangeDonationThresholdConfigFormData) => {
    setErrorMessage('');

    try {
      await updateUserData(user._id, {
        donationThresholdConfig: data.donationThresholdConfig,
      });
      onSaved({
        ...user,
        donationThresholdConfig: data.donationThresholdConfig,
      });

      reset(data);
      setSuccessMessage(t('Successfully updated donation threshold config'));
    } catch (err) {
      setErrorMessage(t('Error while updating donation threshold config'));
    }
  };

  const submitDisabled =
    Boolean(Object.keys(errors).length) || !isDirty || isSubmitting;

  const onAppendClick = () => {
    const threshold =
      Math.max(...watchFields.map((config) => config.threshold)) + 50;
    append({ ...defaultValues, threshold });
  };

  const getIndexById = (
    fields: FieldArray<ChangeDonationThresholdConfigFormData>[],
    _id: string,
  ) => fields.findIndex((field) => field._id === _id);

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Donation threshold config')}
        description={t('Change your donation threshold config')}
      />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" flexDirection="column" mb={2}>
              {fields.map((field) => {
                const { _id } = field;
                const index = getIndexById(fields, _id);
                const { pictureUrl, soundUrl } = field;

                return (
                  <Box
                    key={field._id}
                    display="flex"
                    alignItems="center"
                    gap={2}
                    mb={2}
                  >
                    <Input
                      name={`donationThresholdConfig.${index}.threshold`}
                      label={t('Threshold')}
                      type="number"
                      error={
                        !!errors.donationThresholdConfig?.[index]?.threshold
                      }
                      helperText={
                        errors.donationThresholdConfig?.[index]?.threshold
                          ?.message
                      }
                      control={control}
                    />

                    {pictureUrl && <PicturePreview pictureUrl={pictureUrl} />}
                    <Input
                      name={`donationThresholdConfig.${index}.pictureUrl`}
                      label={t('Picture URL')}
                      type="url"
                      error={
                        !!errors.donationThresholdConfig?.[index]?.pictureUrl
                      }
                      helperText={
                        errors.donationThresholdConfig?.[index]?.pictureUrl
                          ?.message
                      }
                      control={control}
                    />

                    {soundUrl && <AudioPreview soundUrl={soundUrl} />}

                    <Input
                      name={`donationThresholdConfig.${index}.soundUrl`}
                      label={t('Sound URL')}
                      type="url"
                      error={
                        !!errors.donationThresholdConfig?.[index]?.soundUrl
                      }
                      helperText={
                        errors.donationThresholdConfig?.[index]?.soundUrl
                          ?.message
                      }
                      control={control}
                    />

                    <Tooltip arrow title={t('Remove row')}>
                      <IconButton
                        onClick={() => remove(index)}
                        disabled={fields.length === 1}
                      >
                        <TrashIcon
                          color={fields.length !== 1 ? red : 'currentColor'}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}

              <Box>
                <Button
                  startIcon={<AddIcon />}
                  onClick={onAppendClick}
                  variant="text"
                  label={t('Add row')}
                />
              </Box>
            </Box>

            <StyledFormActions>
              <Button
                withLoader
                type="submit"
                label={t('Save')}
                loading={isSubmitting || isValidating}
                disabled={submitDisabled}
              />
              <Button
                label={t('Back')}
                variant="text"
                className="cancel-button"
                onClick={onCancel}
              />
            </StyledFormActions>
          </form>
        </Grid>
      </Grid>
    </StyledFormContainer>
  );
}
