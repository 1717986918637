import React from 'react';
import Typography from '@mui/material/Typography';
import useUser from 'hooks/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CardWithdrawValidationSchema } from 'constraints';
import Box from '@mui/material/Box';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { createCardWithdrawalRequest } from 'services/api/withdrawals';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { Currency } from 'services/api/types';

type CardWithdrawFormData = {
  amount: number;
  card: string;
};

export default function CardWithdrawForm() {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { withdrawalsData, setWithdrawalsData } = useUser();
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CardWithdrawFormData>({
    resolver: yupResolver(CardWithdrawValidationSchema(t)),
  });

  const onSubmit = async (data: CardWithdrawFormData) => {
    setErrorMessage('');

    const { amount, card: rawCard } = data;
    const card = rawCard.replace(/ /g, '');
    try {
      const response = await createCardWithdrawalRequest({
        card,
        amount,
        currency: Currency.UAH,
      });
      const dataObject = {
        ...withdrawalsData,
        items: [response],
        meta: {
          ...withdrawalsData.meta,
          totalItems: withdrawalsData.meta.totalItems + 1,
          itemCount: withdrawalsData.meta.itemCount + 1,
        },
      };
      setWithdrawalsData(dataObject);
      reset();
      setSuccessMessage(t('Withdrawal request successfully sent'));
    } catch (err) {
      setErrorMessage(t('Error while withdrawal request'));
    }
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="body1">
          {t('Send a withdrawal request')}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} mb={3}>
          <Input
            name="amount"
            label={t('Amount to withdraw')}
            placeholder="100"
            type="number"
            error={!!errors.amount?.message}
            helperText={errors.amount?.message}
            control={control}
            startAdornment={<>{Currency.UAH}</>}
            mb={2}
          />
          <Input
            name="card"
            label={t('Card number')}
            placeholder="0000 0000 0000 0000"
            error={!!errors.card?.message}
            helperText={errors.card?.message}
            control={control}
            mask="9999 9999 9999 9999"
            startAdornment={<CreditCardIcon />}
            mb={2}
          />
        </Box>
        <Button
          type="submit"
          label={t('Send')}
          withLoader
          loading={isSubmitting}
          startIcon={<AccountBalanceWalletIcon />}
          fullWidth
        />
      </form>
    </>
  );
}
