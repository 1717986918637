import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import { black100, primary, white } from 'view/theme/colors';

export const StyledName = styled(Typography)`
  font-size: 2rem;
  height: 2.2rem;
  color: ${primary};
  text-shadow: -0.5px -0.5px 0 ${black100}, 0.5px -0.5px 0 ${black100},
    -0.5px 0.5px 0 ${black100}, 0.5px 0.5px 0 #000;
  white-space: nowrap;
`;

export const StyledMessage = styled(Typography)`
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: ${white};
  text-shadow: -0.5px -0.5px 0 ${black100}, 0.5px -0.5px 0 ${black100},
    -0.5px 0.5px 0 ${black100};
`;
