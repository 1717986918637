import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import {
  SETTINGS_PATH,
  ADMIN_APP_PATH,
  ADMIN_USERS_PATH,
  APP_PATH,
  BALANCE_PATH,
  CONTACTS_PATH,
  DONATE_PATH,
  GOALS_PATH,
  LIVE_DONATE_PATH,
  MY_DONATES_PATH,
  PRICING_PATH,
  PRIVACY_POLICY_PATH,
  QR_CODE_DONATE_PATH,
  STATISTICS_PATH,
  TERMS_PATH,
  WIDGETS_PATH,
} from 'view/routes';
import Loader from 'view/components/Loader';
// import { ShadowProvider } from 'contexts/shadow';
import { AuthProvider } from 'contexts/auth';
import { UserProvider } from 'contexts/user';
import { SocketProvider } from 'contexts/socket';
import DonatePage from 'view/pages/Donate';
import DonationsBook from 'view/pages/DonationBook';
import ResponsiveAppBar from 'view/components/AppBar';
import AdminAppBar from 'view/components/AdminAppBar';
import AnonymousRoute from 'view/components/routing/AnonymousRoute';
import PrivateRoute from 'view/components/routing/PrivateRoute';
import AdminRoute from 'view/components/routing/AdminRoute';
import HomePage from 'view/pages/Home';
import StatisticsPage from 'view/pages/Statistics';
import DonateSuccessPage from 'view/pages/DonateSuccess';
import DonationAlert from 'view/pages/DonationAlert';
import BalancePage from 'view/pages/Balance';
import Footer from 'view/components/Footer';
import AccountSettings from 'view/pages/AccountSettings';
import PrivacyPolicyPage from 'view/pages/PrivacyPolicy';
import TermsPage from 'view/pages/Terms';
import PricingPage from 'view/pages/Pricing';
import QRCodeDonatePage from 'view/pages/QRCodeDonate';
import DonateFailurePage from 'view/pages/DonateSuccess';
import CookieNotification from 'view/components/CookieNotification';
import AdminUsers from 'view/pages/AdminUsers';
import ContactsPage from 'view/pages/Contacts';
import GoalPage from 'view/pages/Goal';
import Widgets from 'view/pages/Widgets';

export default function App() {
  return (
    <Suspense fallback={<Loader height="100vh" />}>
      <AuthProvider>
        <UserProvider>
          <SocketProvider>
            <Router>
              <Routes>
                <Route
                  path={`:cipher${LIVE_DONATE_PATH}`}
                  element={<DonationAlert />}
                />
                <Route
                  path={`${QR_CODE_DONATE_PATH}/:contentCreatorName`}
                  element={<QRCodeDonatePage />}
                />
                <Route
                  path={`${GOALS_PATH}/:contentCreatorName/:goalId`}
                  element={<GoalPage />}
                />
                <Route
                  element={
                    <Box>
                      <CookieNotification />
                      <ResponsiveAppBar />
                      <Outlet />
                      <Footer />
                    </Box>
                  }
                >
                  <Route path={PRICING_PATH} element={<PricingPage />} />
                  <Route path={CONTACTS_PATH} element={<ContactsPage />} />
                  <Route
                    path={PRIVACY_POLICY_PATH}
                    element={<PrivacyPolicyPage />}
                  />
                  <Route path={TERMS_PATH} element={<TermsPage />} />
                </Route>
                <Route
                  element={
                    <AdminRoute>
                      <Box display="flex">
                        <AdminAppBar />
                        <Outlet />
                      </Box>
                    </AdminRoute>
                  }
                >
                  <Route
                    path={ADMIN_APP_PATH}
                    element={<Navigate to={ADMIN_USERS_PATH} />}
                  />
                  <Route path={ADMIN_USERS_PATH} element={<AdminUsers />} />
                  <Route path="*" element={<Navigate to={ADMIN_APP_PATH} />} />
                </Route>
                <Route
                  element={
                    <PrivateRoute>
                      <Box>
                        <ResponsiveAppBar />
                        <Outlet />
                        <Footer />
                      </Box>
                    </PrivateRoute>
                  }
                >
                  <Route path={MY_DONATES_PATH} element={<DonationsBook />} />
                  <Route path={STATISTICS_PATH} element={<StatisticsPage />} />
                  <Route path={BALANCE_PATH} element={<BalancePage />} />
                  <Route path={WIDGETS_PATH} element={<Widgets />} />
                  <Route path={SETTINGS_PATH} element={<AccountSettings />} />
                  <Route path="*" element={<Navigate to={MY_DONATES_PATH} />} />
                </Route>
                <Route
                  element={
                    <AnonymousRoute>
                      <Box>
                        <CookieNotification />
                        <ResponsiveAppBar />
                        <Outlet />
                        <Footer />
                      </Box>
                    </AnonymousRoute>
                  }
                >
                  <Route
                    path={`${DONATE_PATH}/:contentCreatorName`}
                    element={<DonatePage />}
                  />
                  <Route
                    path={`${DONATE_PATH}/:contentCreatorName/success`}
                    element={<DonateSuccessPage />}
                  />
                  <Route
                    path={`${DONATE_PATH}/:contentCreatorName/failure`}
                    element={<DonateFailurePage />}
                  />
                  <Route path={APP_PATH} element={<HomePage />} />
                </Route>
              </Routes>
            </Router>
          </SocketProvider>
        </UserProvider>
      </AuthProvider>
    </Suspense>
  );
}
