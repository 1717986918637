import Box from '@mui/material/Box';

export default function CreditCardsIcon() {
  return (
    <Box height="32px" width="32px">
      <svg
        width="32px"
        height="32px"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
      >
        <path
          fill="#A0D2F0"
          d="M141.241,445.793h353.103c9.751,0,17.655-7.904,17.655-17.655V216.276
	c0-9.751-7.904-17.655-17.655-17.655H141.241c-9.751,0-17.655,7.904-17.655,17.655v211.862
	C123.586,437.888,131.49,445.793,141.241,445.793z"
        />
        <path
          fill="#87BEE1"
          d="M141.241,198.621c-9.751,0-17.655,7.904-17.655,17.655v114.759h247.172
	c19.501,0,35.31-15.809,35.31-35.31v-97.103H141.241z"
        />
        <g>
          <path
            fill="#8F96AC"
            d="M379.586,348.69h-44.138c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h44.138c4.875,0,8.828,3.953,8.828,8.828l0,0C388.414,344.737,384.461,348.69,379.586,348.69z"
          />
          <path
            fill="#8F96AC"
            d="M220.69,348.69h-44.138c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h44.138c4.875,0,8.828,3.953,8.828,8.828l0,0C229.517,344.737,225.565,348.69,220.69,348.69z"
          />
          <path
            fill="#8F96AC"
            d="M459.034,348.69h-44.138c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h44.138c4.875,0,8.828,3.953,8.828,8.828l0,0C467.862,344.737,463.91,348.69,459.034,348.69z"
          />
          <path
            fill="#8F96AC"
            d="M300.138,348.69H256c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828h44.138
		c4.875,0,8.828,3.953,8.828,8.828l0,0C308.966,344.737,305.013,348.69,300.138,348.69z"
          />
          <path
            fill="#8F96AC"
            d="M300.138,419.31h-35.31c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h35.31c4.875,0,8.828,3.953,8.828,8.828l0,0C308.966,415.358,305.013,419.31,300.138,419.31z"
          />
          <path
            fill="#8F96AC"
            d="M273.655,384h-8.828c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828h8.828
		c4.875,0,8.828,3.953,8.828,8.828l0,0C282.483,380.047,278.53,384,273.655,384z"
          />
          <path
            fill="#8F96AC"
            d="M317.793,384h-8.828c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828h8.828
		c4.875,0,8.828,3.953,8.828,8.828l0,0C326.621,380.047,322.668,384,317.793,384z"
          />
          <path
            fill="#8F96AC"
            d="M423.724,419.31h-88.276c-4.875,0-8.828-3.953-8.828-8.828l0,0c0-4.875,3.953-8.828,8.828-8.828
		h88.276c4.875,0,8.828,3.953,8.828,8.828l0,0C432.552,415.358,428.599,419.31,423.724,419.31z"
          />
        </g>
        <circle fill="#FF6464" cx="176.552" cy="392.828" r="26.483" />
        <circle fill="#FFD782" cx="211.862" cy="392.828" r="26.483" />
        <path
          fill="#FFA05A"
          d="M185.379,392.828c0-7.812,3.447-14.762,8.828-19.609c5.38,4.847,8.828,11.797,8.828,19.609
	c0,7.812-3.447,14.762-8.828,19.609C188.825,407.59,185.379,400.64,185.379,392.828z"
        />
        <rect
          x="123.586"
          y="225.103"
          fill="#707487"
          width="388.414"
          height="35.31"
        />
        <rect
          x="123.586"
          y="225.103"
          fill="#5B5D6E"
          width="282.483"
          height="35.31"
        />
        <path
          fill="#B4E1FA"
          d="M370.759,313.379H17.655C7.904,313.379,0,305.475,0,295.724V83.862
	c0-9.751,7.904-17.655,17.655-17.655h353.103c9.751,0,17.655,7.904,17.655,17.655v211.862
	C388.414,305.474,380.509,313.379,370.759,313.379z"
        />
        <g>
          <path
            fill="#AFB9D2"
            d="M132.414,216.276h44.138c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		h-44.138c-4.875,0-8.828,3.953-8.828,8.828l0,0C123.586,212.323,127.539,216.276,132.414,216.276z"
          />
          <path
            fill="#AFB9D2"
            d="M291.31,216.276h44.138c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		H291.31c-4.875,0-8.828,3.953-8.828,8.828l0,0C282.483,212.323,286.435,216.276,291.31,216.276z"
          />
          <path
            fill="#AFB9D2"
            d="M52.966,216.276h44.138c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		H52.966c-4.875,0-8.828,3.953-8.828,8.828l0,0C44.138,212.323,48.09,216.276,52.966,216.276z"
          />
          <path
            fill="#AFB9D2"
            d="M211.862,216.276H256c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828h-44.138
		c-4.875,0-8.828,3.953-8.828,8.828l0,0C203.034,212.323,206.987,216.276,211.862,216.276z"
          />
        </g>
        <g>
          <path
            fill="#EFF2FA"
            d="M158.897,110.345h123.586c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		H158.897c-4.875,0-8.828,3.953-8.828,8.828l0,0C150.069,106.392,154.022,110.345,158.897,110.345z"
          />
          <path
            fill="#EFF2FA"
            d="M211.862,145.655h70.621c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		h-70.621c-4.875,0-8.828,3.953-8.828,8.828l0,0C203.034,141.703,206.987,145.655,211.862,145.655z"
          />
        </g>
        <g>
          <path
            fill="#AFB9D2"
            d="M211.862,286.897h35.31c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		h-35.31c-4.875,0-8.828,3.953-8.828,8.828l0,0C203.034,282.944,206.987,286.897,211.862,286.897z"
          />
          <path
            fill="#AFB9D2"
            d="M238.345,251.586h8.828c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		h-8.828c-4.875,0-8.828,3.953-8.828,8.828l0,0C229.517,247.634,233.47,251.586,238.345,251.586z"
          />
          <path
            fill="#AFB9D2"
            d="M194.207,251.586h8.828c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		h-8.828c-4.875,0-8.828,3.953-8.828,8.828l0,0C185.379,247.634,189.332,251.586,194.207,251.586z"
          />
          <path
            fill="#AFB9D2"
            d="M88.276,286.897h88.276c4.875,0,8.828-3.953,8.828-8.828l0,0c0-4.875-3.953-8.828-8.828-8.828
		H88.276c-4.875,0-8.828,3.953-8.828,8.828l0,0C79.448,282.944,83.401,286.897,88.276,286.897z"
          />
        </g>
        <circle fill="#FF6464" cx="300.138" cy="260.414" r="26.483" />
        <circle fill="#FFD782" cx="335.448" cy="260.414" r="26.483" />
        <path
          fill="#FFA05A"
          d="M308.966,260.414c0,7.812,3.447,14.762,8.828,19.609c5.38-4.847,8.828-11.797,8.828-19.609
	s-3.447-14.762-8.828-19.609C312.412,245.652,308.966,252.601,308.966,260.414z"
        />
        <path
          fill="#FFC36E"
          d="M97.103,180.966H52.966c-4.875,0-8.828-3.953-8.828-8.828v-26.483c0-4.875,3.953-8.828,8.828-8.828
	h44.138c4.875,0,8.828,3.953,8.828,8.828v26.483C105.931,177.013,101.978,180.966,97.103,180.966z"
        />
        <path
          fill="#FFD782"
          d="M105.931,154.483v-8.828c0-4.875-3.953-8.828-8.828-8.828h-35.31v44.138h26.483V163.31
	c0-4.875,3.953-8.828,8.828-8.828H105.931z"
        />
        <circle fill="#FF6464" cx="335.448" cy="119.172" r="26.483" />
      </svg>
    </Box>
  );
}
