import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDonationConfigFromCipher } from 'services/api/user';
import useSocket from 'hooks/socket';
import Portal from 'view/portals';
import DonationComponent from 'view/components/DonationComponent';
import Alert from 'view/components/Alert';
import { Donation } from 'types/donations';
import { DonationThresholdConfig, User } from 'types/users';
import {
  getIsVoiceOverAllowed,
  getMediaSources,
  handleDonationThresholdConfig,
} from 'tools';
import DonationQueue from 'view/pages/DonationAlert/tools/DonationQueue';
import { DEFAULT_TTS_EXAMPLE_URL } from 'view/constants';
import { AnimatePresence } from 'framer-motion';
import { Currency, ExchangeRate } from 'services/api/types';
import { getExchangeRates } from 'services/api/donations';

export default function DonationAlert() {
  const { socketIo } = useSocket();
  const { cipher } = useParams();
  const [error, setError] = useState('');
  const [donation, setDonation] = useState<Donation | null>(null);
  const [donationConfig, setDonationConfig] = useState<
    | (Pick<
        User,
        | 'donationAnimationFormat'
        | 'minimumVoiceOverThreshold'
        | 'donationThresholdConfig'
      > & {
        userId: string;
      })
    | null
  >(null);
  const [exchangeRates, setExchangeRates] = useState<ExchangeRate | null>(null);

  const donationThresholdConfig = handleDonationThresholdConfig(
    donationConfig?.donationThresholdConfig as DonationThresholdConfig[],
    Currency.UAH,
    exchangeRates as ExchangeRate,
  );

  const donationQueue = new DonationQueue(setDonation, setError);

  const addDonationToQueue = (donation: Donation) => {
    if (!donationConfig || !exchangeRates) {
      return;
    }

    const isVoiceOverAllowed = getIsVoiceOverAllowed(
      donation?.amount,
      donationConfig?.minimumVoiceOverThreshold,
      donation?.currency,
      exchangeRates,
    );

    const { alertUrl } = getMediaSources(
      donationThresholdConfig,
      donation?.amount,
      donation.currency,
      exchangeRates,
    );

    donationQueue.addDonation({
      ...donation,
      alertUrl,
      isVoiceOverAllowed,
    });
  };

  const announceDonation = (donation: Donation) => {
    if (!donationConfig || !exchangeRates) {
      return;
    }

    const isVoiceOverAllowed = getIsVoiceOverAllowed(
      donation?.amount,
      donationConfig?.minimumVoiceOverThreshold,
      donation?.currency,
      exchangeRates,
    );

    const { alertUrl } = getMediaSources(
      donationThresholdConfig,
      donation?.amount,
      donation.currency,
      exchangeRates,
    );

    donationQueue.addDonation({
      ...donation,
      alertUrl,
      voiceUrl: DEFAULT_TTS_EXAMPLE_URL,
      isVoiceOverAllowed,
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (cipher) {
        try {
          const config = await getDonationConfigFromCipher(cipher);
          const rates = await getExchangeRates();

          setDonationConfig(config);
          setExchangeRates(rates);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
        }
      }
    };

    if (cipher) {
      fetchUserData();
    }
  }, [cipher]);

  useEffect(() => {
    if (socketIo && donationConfig?.userId) {
      socketIo.emit('join', donationConfig.userId);

      socketIo.on('donation', (donation) => {
        addDonationToQueue(donation);
      });

      socketIo.on('repeat-donation', async (donation) => {
        addDonationToQueue(donation);
      });

      socketIo.on('show-test-donation', (donation) => {
        announceDonation(donation);
      });

      socketIo.on('hide-test-donation', () => {
        donationQueue.stop();
      });

      return () => {
        setDonation(null);
        socketIo.off('donation');
        socketIo.off('repeat-donation');
        socketIo.off('show-test-donation');
        socketIo.off('hide-test-donation');
      };
    }
  }, [socketIo, donationConfig?.userId]);

  if (!donation || !exchangeRates) {
    return null;
  }

  const { pictureUrl } = getMediaSources(
    donationThresholdConfig,
    donation?.amount,
    donation?.currency,
    exchangeRates,
  );

  return (
    <Portal>
      <Alert open={!!error} type="error" label={error} />
      <AnimatePresence>
        {donation && donationConfig?.donationAnimationFormat && (
          <DonationComponent
            imageSrc={pictureUrl}
            animationFormat={donationConfig.donationAnimationFormat}
            donation={donation}
          />
        )}
      </AnimatePresence>
    </Portal>
  );
}
