import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BackButton from 'view/components/BackButton';
import React from 'react';

type FormHeaderProps = {
  title: string;
  onBackClick?: () => void;
  description?: string;
  mt?: number;
};

export default function FormHeader({
  title,
  description,
  onBackClick,
  mt,
}: FormHeaderProps) {
  return (
    <Box mb={5} mt={mt} display="flex" gap={1}>
      {!!onBackClick && <BackButton onClick={onBackClick} />}
      <Box>
        <Typography variant="h2">{title}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Box>
    </Box>
  );
}
