import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

type PicturePreviewProps = {
  pictureUrl: string;
};

export default function PicturePreview({ pictureUrl }: PicturePreviewProps) {
  const { t } = useTranslation();

  return (
    <Tooltip
      arrow
      title={
        <Box width="200px" textAlign="center">
          <img width="200px" src={pictureUrl} alt="Preview" />
          {t('Donation picture')}
        </Box>
      }
    >
      <img width="32px" src={pictureUrl} alt="Preview" />
    </Tooltip>
  );
}
