import React, { ReactNode } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CustomizedSwitches from 'view/components/Switch';
import useTheme from 'hooks/theme';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import LanguageChange from 'view/components/LanguageChange';
import Divider from '@mui/material/Divider';

type DrawerDetailsProps = {
  adminTabs: Array<{
    id: string;
    onClick: () => void;
    icon: ReactNode;
    label: string;
  }>;
  onClose: () => void;
};

const DrawerDetails = ({ adminTabs, onClose }: DrawerDetailsProps) => {
  const { toggleTheme, isDarkMode } = useTheme();

  return (
    <Box sx={{ width: 300 }} mt="4rem">
      <Box mb={2}>
        <List>
          {adminTabs.map((page, index) => (
            <ListItem key={page.id} disablePadding>
              <ListItemButton
                onClick={() => {
                  page.onClick();
                  onClose();
                }}
              >
                <ListItemIcon>{page.icon}</ListItemIcon>
                <ListItemText primary={page.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>

      <Box px={2} mb={2}>
        <LanguageChange />
      </Box>
      <Box px={1} mb={2}>
        <CustomizedSwitches onChange={toggleTheme} checked={isDarkMode} />
      </Box>
    </Box>
  );
};
export default DrawerDetails;
