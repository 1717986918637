import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import useUser from 'hooks/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { WhitepayWithdrawValidationSchema } from 'constraints';
import Box from '@mui/material/Box';
import Input from 'view/components/Form/Input';
import Button from 'view/components/Button';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { createWhitepayWithdrawalRequest } from 'services/api/withdrawals';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from 'view/components/Link';
import { TERMS_PATH } from 'view/routes';
import { Currency } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';

type WhitepayWithdrawFormData = {
  amount: string;
  wallet: string;
};

export default function WhitepayWithdrawForm() {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { withdrawalsData, setWithdrawalsData, user, setUser } = useUser();
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<WhitepayWithdrawFormData>({
    resolver: yupResolver(WhitepayWithdrawValidationSchema(t)),
  });

  const onSubmit = async (data: WhitepayWithdrawFormData) => {
    setErrorMessage('');
    const formatedData = {
      ...data,
      currency: Currency.USDT,
      amount: data.amount.toString(),
    };

    try {
      const response = await createWhitepayWithdrawalRequest(formatedData);
      const dataObject = {
        ...withdrawalsData,
        items: [response, ...withdrawalsData.items],
        meta: {
          ...withdrawalsData.meta,
          totalItems: withdrawalsData.meta.totalItems + 1,
          itemCount: withdrawalsData.meta.itemCount + 1,
        },
      };
      setWithdrawalsData(dataObject);
      reset();
      setUser({
        ...user,
        balance: {
          ...user.balance,
          [PaymentMethodTypes.WHITEPAY]:
            user.balance[PaymentMethodTypes.WHITEPAY] - response.amount,
        },
      });
      setSuccessMessage(t('Withdrawal request successfully sent'));
    } catch (err) {
      setErrorMessage(t('Error while withdrawal request'));
    }
  };

  const handleTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked);
  };

  return (
    <>
      <Box mb={2}>
        <Typography variant="body1">
          {t('Withdraw funds to your crypto wallet')}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={2} mb={3}>
          <Input
            name="amount"
            label={t('Amount to withdraw')}
            placeholder="100"
            type="number"
            error={!!errors.amount?.message}
            helperText={errors.amount?.message}
            control={control}
            startAdornment={<>{Currency.USDT}</>}
            mb={2}
          />
          <Input
            name="wallet"
            type="text"
            label={t('Wallet credentials')}
            placeholder="djfhku2h345h43l63jh423j4v2"
            error={!!errors.wallet?.message}
            helperText={errors.wallet?.message}
            control={control}
            mb={2}
          />
        </Box>
        <Box mb={2}>
          <Typography variant="body1" mb={2}>
            {t('The withdrawal fee is 1 USDT')}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isTermsAccepted}
                onChange={handleTermsChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label={
              <Typography variant="body2">
                {t('By clicking «Donate», you agree to')}{' '}
                <Link to={TERMS_PATH} label={t('Terms of use')} />
              </Typography>
            }
          />
        </Box>
        <Button
          type="submit"
          label={t('Send')}
          withLoader
          loading={isSubmitting}
          disabled={!isTermsAccepted}
          startIcon={<AccountBalanceWalletIcon />}
          fullWidth
        />
      </form>
    </>
  );
}
