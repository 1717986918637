import React, { ReactNode, useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import FormHeader from 'view/components/Form/Header';
import { PaymentMethodTypes } from 'types/users';
import { StyledFormActions, StyledFormContainer } from '../../styled';
import Grid from '@mui/material/Grid';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';
import { updateUserData } from 'services/api/user';
import IconButton from '@mui/material/IconButton';
import { red } from 'view/theme/colors';
import Tooltip from '@mui/material/Tooltip';
import TrashIcon from 'view/components/icons/Trash';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from 'view/components/ConfirmationDialog';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreditCardsIcon from 'view/components/icons/CreditCards';
import BitcoinIcon from 'view/components/icons/Bitcoin';
import DefaultPaymentCardIcon from 'view/components/icons/DefaultPaymentCard';
import WhitepayDialog, {
  WhitepayFormData,
} from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Whitepay/components/WhitepayDialog';
import { WayforpayFormData } from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Wayforpay/components/WayforpayDialog';
// import WayforpayDataRow from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Wayforpay/components/WayforpayDataRow';
// import WhitepayDataRow from 'view/pages/AccountSettings/components/ChangePaymentMethods/components/Whitepay/components/WhitepayDataRow';

type ChangePaymentMethodsProps = {
  onCancel: () => void;
};

export const PAYMENT_METHOD_MEDIA_MAP: Record<
  PaymentMethodTypes,
  { label: string; shortLabel: string; disabled?: boolean; icon: ReactNode }
> = {
  [PaymentMethodTypes.WAYFORPAY]: {
    label: 'Онлайн-оплата карткою (Wayforpay)',
    shortLabel: 'Wayforpay',
    icon: <CreditCardsIcon />,
  },
  [PaymentMethodTypes.WHITEPAY]: {
    label: 'Оплата криптовалютою (Whitepay)',
    shortLabel: 'Whitepay',
    disabled: true,
    icon: <BitcoinIcon />,
  },
  [PaymentMethodTypes.CRYPTOMUS]: {
    label: 'Оплата криптовалютою (Cryptomus)',
    shortLabel: 'Cryptomus',
    disabled: true,
    icon: <BitcoinIcon />,
  },
  [PaymentMethodTypes.MONOBANKA]: {
    label: 'Поповнення монобанки (Monobank)',
    shortLabel: 'Monobank',
    disabled: true,
    icon: <DefaultPaymentCardIcon />,
  },
  [PaymentMethodTypes.LIQPAY]: {
    label: 'Онлайн-оплата карткою (LiqPay)',
    shortLabel: 'LiqPay',
    disabled: true,
    icon: <DefaultPaymentCardIcon />,
  },
};

export default function ChangePaymentMethods({
  onCancel,
}: ChangePaymentMethodsProps) {
  const { setErrorMessage, setSuccessMessage } = useAuth();
  const { user, setUser } = useUser();
  const { t } = useTranslation();

  const [paymentMethodToUpdate, setPaymentMethodToUpdate] =
    useState<PaymentMethodTypes | null>(null);
  const [paymentToDelete, setPaymentToDelete] =
    useState<PaymentMethodTypes | null>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAddPaymentMethodSubmit = async (
    paymentMethod: PaymentMethodTypes,
    data?: WhitepayFormData | WayforpayFormData | true,
  ) => {
    setErrorMessage('');

    try {
      const updatedPaymentMethods = {
        ...user.paymentMethods,
        [paymentMethod]: data || true,
      };
      await updateUserData(user._id, {
        paymentMethods: updatedPaymentMethods,
      });
      setUser({ ...user, paymentMethods: updatedPaymentMethods });
      setPaymentMethodToUpdate(null);
      setSuccessMessage(
        t('successfullyAddedPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    } catch (err) {
      setErrorMessage(
        t('errorWhileUpdatingPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label,
        }),
      );
    }
  };

  const deletePaymentMethod = async (paymentToDelete: PaymentMethodTypes) => {
    try {
      const { [paymentToDelete]: _, ...rest } = user.paymentMethods;
      await updateUserData(user._id, {
        paymentMethods: rest || {},
      });
      setPaymentToDelete(null);
      setUser({ ...user, paymentMethods: rest });
    } catch (err) {
      setErrorMessage(
        t('errorWhileDeletingPaymentMethod', {
          paymentMethod: PAYMENT_METHOD_MEDIA_MAP[paymentToDelete].label,
        }),
      );
    }
  };

  const options = Object.values(PaymentMethodTypes).filter(
    (key) => !Object.keys(user?.paymentMethods || {}).includes(key),
  );

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('Payment methods')}
        description={t('Change your payment methods')}
      />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Box display="flex" flexDirection="column" mb={2}>
            <Box gap={2} mb={2}>
              {((): ReactNode => {
                const paymentMethodsKeys = Object.keys(
                  user?.paymentMethods || {},
                ) as PaymentMethodTypes[];

                if (!paymentMethodsKeys.length) {
                  return (
                    <Typography variant="body2">
                      {t('There are no payment methods. Add a new one below')}
                    </Typography>
                  );
                }
                return paymentMethodsKeys.map(
                  (paymentMethod: PaymentMethodTypes) => {
                    return (
                      <Box
                        key={paymentMethod}
                        display="flex"
                        alignItems="center"
                        gap={1.5}
                        mb={2}
                      >
                        {PAYMENT_METHOD_MEDIA_MAP[paymentMethod].icon}
                        <Typography>
                          {PAYMENT_METHOD_MEDIA_MAP[paymentMethod].label}
                        </Typography>

                        {/*{paymentMethod === PaymentMethodTypes.WAYFORPAY && (*/}
                        {/*  <WayforpayDataRow*/}
                        {/*    setPaymentMethodToUpdate={setPaymentMethodToUpdate}*/}
                        {/*  />*/}
                        {/*)}*/}

                        {/*{isWhitepay && (*/}
                        {/*  <WhitepayDataRow*/}
                        {/*    setPaymentMethodToUpdate={setPaymentMethodToUpdate}*/}
                        {/*  />*/}
                        {/*)}*/}

                        <Tooltip arrow title={t('Remove')}>
                          <IconButton
                            color="error"
                            onClick={() => setPaymentToDelete(paymentMethod)}
                          >
                            <TrashIcon color={red} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    );
                  },
                );
              })()}
            </Box>
          </Box>

          <StyledFormActions>
            <Button
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              label={t('Add')}
            />
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {options.map((key) => (
                <MenuItem
                  key={key}
                  disabled={PAYMENT_METHOD_MEDIA_MAP[key].disabled}
                  onClick={() => {
                    handleClose();
                    // if (key === PaymentMethodTypes.WHITEPAY) {
                    //   return setPaymentMethodToUpdate(key);
                    // }
                    // if (key === PaymentMethodTypes.WAYFORPAY) {
                    //   return setPaymentMethodToUpdate(key);
                    // }
                    return onAddPaymentMethodSubmit(key);
                  }}
                  disableRipple
                >
                  <ListItemIcon sx={{ marginRight: 1 }}>
                    {PAYMENT_METHOD_MEDIA_MAP[key].icon}
                  </ListItemIcon>
                  <ListItemText>
                    {PAYMENT_METHOD_MEDIA_MAP[key].label}
                  </ListItemText>
                </MenuItem>
              ))}
            </Menu>

            <Button
              label={t('Back')}
              variant="text"
              className="cancel-button"
              onClick={onCancel}
            />
          </StyledFormActions>
        </Grid>
      </Grid>
      {/*{paymentMethodToUpdate === PaymentMethodTypes.WAYFORPAY && (*/}
      {/*  <WayforpayDialog*/}
      {/*    open={paymentMethodToUpdate === PaymentMethodTypes.WAYFORPAY}*/}
      {/*    onSaved={async (data) => {*/}
      {/*      setPaymentMethodToUpdate(null);*/}
      {/*      await onAddPaymentMethodSubmit(PaymentMethodTypes.WAYFORPAY, data);*/}
      {/*    }}*/}
      {/*    onClose={() => setPaymentMethodToUpdate(null)}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{paymentMethodToUpdate === PaymentMethodTypes.WHITEPAY && (*/}
      {/*  <WhitepayDialog*/}
      {/*    open={paymentMethodToUpdate === PaymentMethodTypes.WHITEPAY}*/}
      {/*    onSaved={async (data) => {*/}
      {/*      setPaymentMethodToUpdate(null);*/}
      {/*      await onAddPaymentMethodSubmit(PaymentMethodTypes.WHITEPAY, data);*/}
      {/*    }}*/}
      {/*    onClose={() => setPaymentMethodToUpdate(null)}*/}
      {/*  />*/}
      {/*)}*/}
      <ConfirmationDialog
        open={!!paymentToDelete}
        text={t('deletePaymentMethod', {
          paymentMethod:
            PAYMENT_METHOD_MEDIA_MAP[paymentToDelete as PaymentMethodTypes]
              ?.label,
        })}
        confirmText={t('Delete')}
        color="error"
        onConfirm={() =>
          deletePaymentMethod(paymentToDelete as PaymentMethodTypes)
        }
        onClose={() => setPaymentToDelete(null)}
      />
    </StyledFormContainer>
  );
}
