import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import { primary } from 'view/theme/colors';
import { ReactNode } from 'react';

const StyledLink = styled(MuiLink)`
  text-decoration: none;
  color: ${primary};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 24px;
` as typeof MuiLink;

type LinkProps = {
  to: string;
  label: string | ReactNode;
};

const Link = ({ to, label }: LinkProps) => (
  <StyledLink component={RouterLink} to={to}>
    {label}
  </StyledLink>
);

export default Link;
