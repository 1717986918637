import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { groupItemsByDate } from 'tools';
import Loader from 'view/components/Loader';
import { Withdrawal } from 'types/withdrawal';
import WithdrawalCard from 'view/components/WithdrawalCard';
import { useTranslation } from 'react-i18next';
import React from 'react';

type WithdrawalsListProps = {
  withdrawals: Withdrawal[];
  fetchNextWithdrawals: () => void;
  allWithdrawalsLoaded: boolean;
};

export default function WithdrawalsList({
  withdrawals,
  fetchNextWithdrawals,
  allWithdrawalsLoaded,
}: WithdrawalsListProps) {
  const { t } = useTranslation();
  if (!withdrawals?.length) {
    return <Typography variant="body2">{t('No withdrawals yet.')}</Typography>;
  }

  const withdrawalsGroupedByDate = groupItemsByDate(withdrawals);

  const sortWithdrawalsByDate = (withdrawals: string[]) => {
    return [
      ...withdrawals.filter((date) => date === 'today'),
      ...withdrawals.filter((date) => date === 'yesterday'),
      ...withdrawals.filter((date) => date === 'last week'),
      ...withdrawals.filter(
        (date) =>
          date !== 'today' && date !== 'yesterday' && date !== 'last week',
      ),
    ];
  };

  const handleSummaryName = (date: string) => {
    if (['today', 'yesterday', 'last week'].includes(date)) {
      return date;
    }
    return format(new Date(date), 'MMMM, d');
  };

  return (
    <InfiniteScroll
      dataLength={withdrawals.length}
      next={fetchNextWithdrawals}
      hasMore={allWithdrawalsLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{ overflow: 'visible' }}
    >
      <Box mb={5}>
        {sortWithdrawalsByDate(Object.keys(withdrawalsGroupedByDate))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((dateName: string) => (
            <Box mb={2} key={dateName}>
              <Typography variant="subtitle1" mb={0.5}>
                {handleSummaryName(dateName)}
              </Typography>
              {withdrawalsGroupedByDate[dateName]
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .map((withdrawal) => (
                  <WithdrawalCard
                    key={withdrawal._id}
                    withdrawal={withdrawal}
                  />
                ))}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
}
