import styled from '@emotion/styled';
import Menu from '@mui/material/Menu';

export const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    border-radius: 0.5rem;
  }

  .MuiList-root {
    padding: 0.5rem;
  }
`;
