import { createContext, ReactElement, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import useUser from 'hooks/user';

type Props = {
  children: ReactElement;
};

interface SocketContextProps {
  socketIo: Socket | null;
}

export const SocketContext = createContext<SocketContextProps>({
  socketIo: null,
});

export const SocketProvider = ({ children }: Props) => {
  const { user } = useUser();
  const [socketIo, setSocketIo] = useState<Socket | null>(null);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL || '', {
      transports: ['websocket'],
    });
    if (user.cipherId) {
      socket.emit('join', user.cipherId);
    }
    setSocketIo(socket);

    return () => {
      if (socket.connected) {
        setSocketIo(null);
        socket.disconnect();
      }
    };
  }, [user.cipherId]);

  return (
    <SocketContext.Provider value={{ socketIo }}>
      {children}
    </SocketContext.Provider>
  );
};
