import React, { ReactNode, useState } from 'react';
import { StyledWithdrawalCard } from './styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Withdrawal, WithdrawalStatus } from 'types/withdrawal';
import { formatCurrency, handleCardText, handleEventDate } from 'tools';
import Grid from '@mui/material/Grid';
import ChecksIcon from 'view/components/icons/Checks';
import SandClockIcon from 'view/components/icons/SandClock';
import AlertTriangleIcon from 'view/components/icons/AlertTriangle';
import StatusUnknownIcon from 'view/components/icons/StatusUnknown';
import CopyButton from 'view/components/CopyButton';
import Chip from '@mui/material/Chip';
import { PaymentMethodsCurrencyMap } from 'services/api/types';

type WithdrawalCardProps = {
  withdrawal: Withdrawal;
};

export const WITHDRAWAL_STATUS_MEDIA_MAP: Record<
  WithdrawalStatus,
  { label: string; icon: ReactNode }
> = {
  [WithdrawalStatus.SUCCESS]: {
    label: 'Success',
    icon: <ChecksIcon />,
  },
  [WithdrawalStatus.PENDING]: {
    label: 'Pending',
    icon: <SandClockIcon />,
  },
  [WithdrawalStatus.FAILED]: {
    label: 'Failed',
    icon: <AlertTriangleIcon />,
  },
};

export default function WithdrawalCard({ withdrawal }: WithdrawalCardProps) {
  const [elevated, setElevated] = useState(false);

  const handleMouseEnter = !elevated ? () => setElevated(true) : undefined;
  const handleMouseLeave = () => setElevated(false);

  const amount = formatCurrency(
    withdrawal.amount,
    PaymentMethodsCurrencyMap[withdrawal.paymentMethod],
  );
  const valueToCopy = `id: ${withdrawal._id}; amount: ${withdrawal.amount}; currency: ${withdrawal.currency}; createdAt: ${withdrawal.createdAt}; status: ${withdrawal.status}`;

  return (
    <StyledWithdrawalCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Grid container justifyContent="space-between" alignItems="center" p={1}>
        <Grid item xs={2} md={3} display="flex" alignItems="center">
          <Box
            mr={0.5}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="32px"
          >
            {WITHDRAWAL_STATUS_MEDIA_MAP[withdrawal?.status]?.icon || (
              <StatusUnknownIcon />
            )}
          </Box>

          {amount && <Chip variant="outlined" label={amount} />}
        </Grid>
        <Grid item xs={4} md={5}>
          <Typography variant="body2" mr={2} noWrap>
            {handleCardText(withdrawal.card)}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="body2" mr={2} noWrap>
            {handleEventDate(new Date(withdrawal.createdAt))}
          </Typography>
          {elevated && <CopyButton value={valueToCopy} />}
        </Grid>
      </Grid>
    </StyledWithdrawalCard>
  );
}
