import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import Button from 'view/components/Button';
import { useTranslation } from 'react-i18next';
import useTheme from 'hooks/theme';
import Stack from '@mui/material/Stack';
import { PRIVACY_POLICY_PATH } from 'view/routes';
import { useNavigate } from 'react-router-dom';
import { black, black87, white } from 'view/theme/colors';

function CookieNotification() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDarkMode } = useTheme();
  const [cookiesAccepted, setIsCookiesAccepted] = useState<boolean>(
    JSON.parse(localStorage.getItem('cookiesAccepted') || 'false') || false,
  );

  const handleAccept = () => {
    setIsCookiesAccepted(true);
    localStorage.setItem('cookiesAccepted', 'true');
  };

  return (
    <Snackbar
      open={!cookiesAccepted}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        p={2}
        sx={{ maxWidth: { xs: '20rem', sm: '32rem' } }}
        style={{
          backgroundColor: isDarkMode ? black87 : white,
          color: isDarkMode ? white : black,
          borderRadius: '12px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1',
        }}
      >
        <Typography variant="h2">{t('Cookies Settings')}</Typography>
        <Typography variant="body2" mb={2}>
          {t('cookiesDescription')}
        </Typography>
        <Stack sx={{ flexDirection: { xs: 'column', sm: 'row' } }} gap={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAccept}
            size="small"
            label={t('Accept')}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate(PRIVACY_POLICY_PATH)}
            size="small"
            label={t('privacyPolicy')}
          />
        </Stack>
      </Box>
    </Snackbar>
  );
}

export default CookieNotification;
