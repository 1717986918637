import { HeadCell } from 'view/components/Table/components/TableHeader';
import { Withdrawal } from 'types/withdrawal';

export const headCells: HeadCell<Withdrawal>[] = [
  {
    id: '_id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
    width: 260,
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Amount',
    isSortable: true,
    width: 100,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    isSortable: true,
    width: 180,
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    isSortable: true,
    width: 100,
  },
  {
    id: 'card',
    numeric: false,
    disablePadding: false,
    label: 'Card',
    width: 240,
  },
];
