import { request } from '../request';
import { BulkCreateGoals } from 'services/api/types';
import { Goal } from 'types/goals';

export const bulkCreateGoals = async (
  goals: BulkCreateGoals,
): Promise<Goal[]> => {
  const { data } = await request({
    method: 'post',
    url: '/goals/bulk',
    data: goals,
    withCredentials: true,
  });

  return data;
};

export const getGoal = async (goalId: string): Promise<Goal> => {
  const { data } = await request({
    url: `/goals/${goalId}`,
  });
  return data;
};

export const deleteGoal = async (goalId: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/goals/${goalId}`,
  });

  return;
};
