import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronIcon from 'view/components/icons/Chevron';
import { StyledLabel } from './styled';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const renderValue = (value: string | JSX.Element | null, type?: string) => {
  if (type === 'obsURL') {
    return '••••••••••••••';
  }

  return value;
};

type UserInfoRowProps = {
  type: string;
  value: string | JSX.Element | null;
  label: string;
  divider?: boolean;
  notEditable?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function UserInfoRow({
  type,
  label,
  value,
  notEditable,
  divider = false,
  onClick,
}: UserInfoRowProps) {
  return (
    <>
      <Grid
        container
        alignItems="center"
        spacing={2}
        sx={{ padding: '16px 0', wordBreak: 'break-word' }}
      >
        <Grid item xs={3}>
          <StyledLabel>{label}</StyledLabel>
        </Grid>
        <Grid item xs={8}>
          <Box typography="body1" sx={{ fontWeight: 500 }}>
            {renderValue(value, type)}
          </Box>
        </Grid>
        {!notEditable && (
          <Grid
            item
            xs={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <IconButton onClick={onClick}>
              <ChevronIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      {divider && <Divider />}
    </>
  );
}
