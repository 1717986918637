import { ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StylePageHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type PageHeaderProps = {
  title: string;
  startAdornment?: string | ReactNode;
  endAdornment?: string | ReactNode;
  action?: ReactElement;
};

const PageHeader = ({
  title,
  startAdornment,
  endAdornment,
  action,
}: PageHeaderProps) => (
  <StylePageHeader
    sx={{
      padding: { xs: '1rem 0 2rem', sm: '3rem 0 2rem', md: '4rem 0 3.5rem' },
    }}
  >
    <Box display="flex" alignItems="center" gap={1} mr={3}>
      {startAdornment}
      <Typography variant="h1" mr={1}>
        {title}
      </Typography>
      {endAdornment}
    </Box>
    {action}
  </StylePageHeader>
);

export default PageHeader;
