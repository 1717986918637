import React, { useEffect, useState } from 'react';
import PageHeader from 'view/components/PageHeader';
import { StyledContainer } from './styled';
import Typography from '@mui/material/Typography';
import BarChart from 'view/components/charts/Bar';
import LineChart from 'view/components/charts/Line';
import Box from '@mui/material/Box';
import { getStatistics } from 'services/api/statistics';
import Loader from 'view/components/Loader';
import Stack from '@mui/material/Stack';
import { primary } from 'view/theme/colors';
import { formatUAHCurrency } from 'tools';
import { Statistics } from 'types/statistics';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/auth';

export default function StatisticsPage() {
  const { setErrorMessage } = useAuth();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Statistics | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const statistics = await getStatistics();
        setData(statistics);
      } catch (err) {
        setErrorMessage(t('Error while loading statistics'));
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  return (
    <StyledContainer component="main">
      <PageHeader title={t('statistics')} />
      {(() => {
        if (loading) {
          return <Loader height="100vh" />;
        }
        if (!data) {
          return (
            <Typography variant="body2">{t('No statistics yet.')}</Typography>
          );
        }
        return (
          <>
            <Typography mb={2} variant="h2">
              {t('summary')}
            </Typography>
            <Stack direction="row" mb={6} gap={1.5} flexWrap="wrap">
              <SummaryItem
                value={formatUAHCurrency(data.sumOfAllDonates)}
                description={t('totalDonationsAmount')}
              />

              <SummaryItem
                value={formatUAHCurrency(data.theBiggestDonationAmount)}
                description={t('theBiggestDonation')}
              />

              <SummaryItem
                value={formatUAHCurrency(data.averageDonationAmount)}
                description={t('averageDonationAmount')}
              />

              <SummaryItem
                value={data.totalDonations}
                description={t('Total donations')}
              />

              <SummaryItem
                value={data.uniqSponsorsCount}
                description={t('Number of sponsors')}
              />
            </Stack>

            <Box mb={6}>
              <Typography variant="h2">
                {t('Donations summarized by date')}
              </Typography>
              <Typography variant="body2">
                {t(
                  'This chart shows how much amount of donations you earn by the day. Days without donations will have 0 sum.',
                )}
              </Typography>
              <Box height={400}>
                <LineChart data={data.sumDonationsByDate} />
              </Box>
            </Box>

            <Box>
              <Typography variant="h2">{t('Top 5 sponsors')}</Typography>
              <Typography variant="body2">
                {t('This chart shows top 5 sponsors by sum of donations made.')}
              </Typography>
              <Box
                height={400}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                {data?.top5SponsorsByDonations?.length ? (
                  <BarChart data={data.top5SponsorsByDonations} />
                ) : (
                  <Typography variant="h5">{t('No data')}</Typography>
                )}
              </Box>
            </Box>
          </>
        );
      })()}
    </StyledContainer>
  );
}

type SummaryItemProps = {
  value: string | number;
  description: string;
};

function SummaryItem({ value, description }: SummaryItemProps) {
  return (
    <Box p={2} sx={{ border: `1px solid ${primary}`, borderRadius: 4 }}>
      <Typography variant="h2">{value}</Typography>
      <Typography variant="body2">{description}</Typography>
    </Box>
  );
}
