import React from 'react';
import Box from '@mui/material/Box';
import Button from 'view/components/Button';
import Input from 'view/components/Input';
import FormHeader from 'view/components/Form/Header';
import { StyledFormContainer, StyledFormActions } from '../../styled';
import { QR_CODE_DONATE_PATH } from 'view/routes';
import useUser from 'hooks/user';
import { useTranslation } from 'react-i18next';
import CopyButton from 'view/components/CopyButton';
import QRCode from 'view/components/QRCode';
import Stack from '@mui/material/Stack';

type ViewOBSURLProps = {
  onCancel: () => void;
};

export default function ViewQRCodeOBSURL({ onCancel }: ViewOBSURLProps) {
  const { user } = useUser();
  const { t } = useTranslation();
  const link = `${process.env.REACT_APP_UI_URL}${QR_CODE_DONATE_PATH}/${user.contentCreatorName}`;

  return (
    <StyledFormContainer>
      <FormHeader
        title={t('QR code widget for OBS')}
        description={t('Add link below to your OBS browser source')}
      />
      <Box mb={2}>
        <QRCode value={link} />
      </Box>
      <Stack direction="row" alignItems="center">
        <Box display="flex">
          <Box width="24rem" mr={1}>
            <Input
              helperText={t(
                'OBS settings for the browser source: width 128px, height 144px',
              )}
              id="OBSurl"
              type="url"
              disabled
              value={link}
            />
          </Box>
          <CopyButton value={link} />
        </Box>
      </Stack>

      <StyledFormActions>
        <Button
          label={t('Back')}
          variant="text"
          className="cancel-button"
          onClick={onCancel}
        />
      </StyledFormActions>
    </StyledFormContainer>
  );
}
