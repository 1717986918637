import { Donation } from 'types/donations';
import { formatUAHCurrency, formatUSDTCurrency } from 'tools';
import { Currency } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';
import type { TFunction } from 'i18next';

export const ANIMATION_OPTIONS: OPTION_SELECT[] = [
  { value: 'none', label: 'None' },
  { value: 'opacity', label: 'Opacity' },
  { value: 'scale', label: 'Scale' },
];

export type OPTION_SELECT = {
  value: string;
  label: string;
};

// type AnimationPreset = {
//   opacity?: number;
//   rotate?: number[];
//   scale?: number;
//   transition?: {
//     duration: number;
//     repeat: number;
//     ease: string;
//   };
// };

// type AnimationType = 'none' | 'rotation' | 'opacity' | 'scale';

export const ENTRY_ANIMATION_PRESETS: Record<string, any> = {
  opacity: { opacity: 0 },
  scale: { scale: 0 },
};

export const MAIN_ANIMATION_PRESETS: Record<string, any> = {
  opacity: 1,
  scale: 1,
};

export const LANGUAGE_OPTIONS: OPTION_SELECT[] = [
  { value: 'uk', label: 'UK' },
  { value: 'en', label: 'EN' },
];

export const MAX_UAH_AMOUNT = 5000;
export const MAX_USDT_AMOUNT = 500;

export const DEFAULT_PICTURE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultDonationPicture.gif?alt=media&token=8186f0dc-58c4-46a3-8335-98662b8b794e';
export const DEFAULT_SOUND_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultDonationSound.mp3?alt=media&token=16d4850f-c9eb-4016-8be8-b991a4e0b60d';

export const DEFAULT_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/defaultTTSexample.mp3?alt=media&token=de008c63-a139-4e2b-9ab6-e9c473df21fc';
export const ANTON_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/AntonTTSexample.mp3?alt=media&token=3a04c0a4-f7d2-4ebd-a037-2a816cda9f88';
export const DMYTRO_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/DmytroTTSexample.mp3?alt=media&token=9675260f-d697-4236-9212-d1798cac9ab7';
export const TYLER_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/TylerTTSexample.mp3?alt=media&token=295b4f49-71e6-4c66-bc88-122a483483c3';
export const ALEX_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/AlexTTSexample.mp3?alt=media&token=17089ff5-f1a2-417c-8169-60cf545f1821';
export const VIRA_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/ViraTTSexample.mp3?alt=media&token=6d78c597-d8da-49a8-ab33-6fe0499c1f54';
export const OKSANA_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/OksanaTTSexample.mp3?alt=media&token=65bdf158-c75d-4c48-9e51-380444377ca8';
export const CHARLOTTE_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/CharlotteTTSexample.mp3?alt=media&token=bb741f82-8009-4c44-9d76-9e9bbe94dcf0';
export const FIN_TTS_EXAMPLE_URL =
  'https://firebasestorage.googleapis.com/v0/b/donate-2fd1d.appspot.com/o/FinTTSexample.mp3?alt=media&token=d19443fa-9ba1-45e1-ae62-5ac289dc7edf';

export type VoiceConfig = {
  label: string;
  exampleUrl: string;
  price: { [key in Currency]: number };
};
export const DEFAULT_VOICE_NAME = 'default';
export const getVoicesConfig = (
  t: TFunction,
): { [key: string]: VoiceConfig } => ({
  [DEFAULT_VOICE_NAME]: {
    price: {
      [Currency.UAH]: 0,
      [Currency.USDT]: 0,
    },
    label: t('Default'),
    exampleUrl: DEFAULT_TTS_EXAMPLE_URL,
  },
  GVRiwBELe0czFUAJj0nX: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Anton'),
    exampleUrl: ANTON_TTS_EXAMPLE_URL,
  },
  NJGiMgVHtXSA1XYSuNKl: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Dmytro'),
    exampleUrl: DMYTRO_TTS_EXAMPLE_URL,
  },
  Png1rsLPwah87cs3JX7l: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Tyler'),
    exampleUrl: TYLER_TTS_EXAMPLE_URL,
  },
  ymDCYd8puC7gYjxIamPt: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Oksana'),
    exampleUrl: OKSANA_TTS_EXAMPLE_URL,
  },
  nCqaTnIbLdME87OuQaZY: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Vira'),
    exampleUrl: VIRA_TTS_EXAMPLE_URL,
  },
  '9Sj8ugvpK1DmcAXyvi3a': {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Alex'),
    exampleUrl: ALEX_TTS_EXAMPLE_URL,
  },
  XB0fDUnXU5powFXDhCwa: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Charlotte'),
    exampleUrl: CHARLOTTE_TTS_EXAMPLE_URL,
  },
  D38z5RcWu1voky8WS1ja: {
    price: {
      [Currency.UAH]: 9,
      [Currency.USDT]: 0.25,
    },
    label: t('Fin'),
    exampleUrl: FIN_TTS_EXAMPLE_URL,
  },
});

export const VOICE_OPTIONS: (
  currency: Currency,
  t: TFunction,
) => OPTION_SELECT[] = (currency: Currency, t: TFunction) => {
  const formatFunction =
    currency === Currency.USDT ? formatUSDTCurrency : formatUAHCurrency;

  const VOICES_CONFIG = getVoicesConfig(t);

  return Object.keys(VOICES_CONFIG).map((key) => ({
    label: `${VOICES_CONFIG[key].label}${
      VOICES_CONFIG[key].price
        ? ` (+${formatFunction(VOICES_CONFIG[key].price[currency])})`
        : ''
    }`,
    value: key,
  }));
};

export const EXAMPLE_DONATION: Donation = {
  _id: '123',
  createdAt: new Date(),
  sponsorName: 'Імʼя спонсора',
  amount: 100,
  currency: Currency.UAH,
  paymentMethod: PaymentMethodTypes.WAYFORPAY,
  voice: DEFAULT_VOICE_NAME,
  message:
    'Цей текст згенеровано для тестування системи перетворення тексту в мовлення.',
};
