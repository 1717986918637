import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSocket from 'hooks/socket';
import { getGoal } from 'services/api/goals';
import { Goal } from 'types/goals';
import Alert from 'view/components/Alert';
import GoalBar from 'view/components/GoalBar';
import { Donation } from 'types/donations';
import { useTranslation } from 'react-i18next';

export default function GoalPage() {
  const { socketIo } = useSocket();
  const { goalId } = useParams();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [goal, setGoal] = useState<Goal | null>(null);

  useEffect(() => {
    const fetchGoalData = async (goalId: string) => {
      try {
        const data = await getGoal(goalId);

        if (!data.active) {
          return setError(t('Goal is not active'));
        }

        setGoal(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
      }
    };

    if (goalId) {
      fetchGoalData(goalId);
    }
  }, [goalId]);

  useEffect(() => {
    if (socketIo && goal?.contentCreatorId) {
      socketIo.emit('join', goal?.contentCreatorId);

      socketIo.on('donation', (donation: Donation) => {
        if (donation.goalId === goalId) {
          setGoal({ ...goal, accumulated: goal.accumulated + donation.amount });
        }
      });

      return () => {
        socketIo.off('donation');
      };
    }
  }, [socketIo, goal?.contentCreatorId]);

  return (
    <>
      <Alert open={!!error} type="error" label={error} />
      {goal && <GoalBar goal={goal} />}
    </>
  );
}
