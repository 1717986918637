import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionMenu, { MenuItemType } from 'view/components/ActionMenu';
import ListIcon from 'view/components/icons/List';
import Loader from 'view/components/Loader';
import TablePagination from 'view/components/Table/components/TablePagination';
import TableHeader, {
  Order,
} from 'view/components/Table/components/TableHeader';
import { headCells } from './helpers';
import {
  PaginatedResponse,
  PaymentMethodsCurrencyMap,
} from 'services/api/types';
import TrashIcon from 'view/components/icons/Trash';
import { Withdrawal, WithdrawalStatus } from 'types/withdrawal';
import { formatCurrency, handleCardText } from 'tools';
import format from 'date-fns/format';
import StatusUnknownIcon from 'view/components/icons/StatusUnknown';
import { WITHDRAWAL_STATUS_MEDIA_MAP } from 'view/components/WithdrawalCard';
import CopyButton from 'view/components/CopyButton';
import TimesIcon from 'view/components/icons/Times';
import Chip from '@mui/material/Chip';

type WithdrawalsTableProps = {
  setWithdrawalToProcess: (withdrawal: Withdrawal) => void;
  setWithdrawalToRefuse: (withdrawal: Withdrawal) => void;
  setSorting: (sorting: [string, Order]) => void;
  onRemoveWithdrawal: (withdrawal: Withdrawal) => void;
  onPageChange: (_event: unknown, newPage: number) => void;
  loading: boolean;
  data: PaginatedResponse<Withdrawal>;
  page: number;
  sorting: [string, Order];
};

export default function WithdrawalsTable({
  loading,
  setWithdrawalToProcess,
  setWithdrawalToRefuse,
  data,
  page,
  sorting,
  onPageChange,
  setSorting,
  onRemoveWithdrawal,
}: WithdrawalsTableProps) {
  const rowsPerPage = 8;
  const orderBy = sorting[0];
  const order = sorting[1];

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Withdrawal,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setSorting([property, isAsc ? 'desc' : 'asc']);
  };

  if (!data.items.length) {
    return (
      <Box sx={{ width: '100%' }}>
        <Typography variant="body2">No withdrawals found.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper
        sx={{ width: '100%', mb: 2, boxShadow: 'none', background: 'none' }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.meta.totalItems}
            />
            {loading ? (
              <TableRow
                sx={{
                  'th,td': { borderBottom: 'none' },
                  height: 73 * rowsPerPage,
                }}
              >
                <TableCell colSpan={6}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {data.items.map((row: Withdrawal, index: number) => {
                  const status = row.status;
                  const menuItems: MenuItemType[] = [
                    ...(status === WithdrawalStatus.PENDING
                      ? [
                          {
                            label: 'Process',
                            icon: <ListIcon />,
                            onClick: () => setWithdrawalToProcess(row),
                          },
                          {
                            label: 'Refuse',
                            icon: <TimesIcon />,
                            onClick: () => setWithdrawalToRefuse(row),
                          },
                        ]
                      : []),
                    {
                      label: 'Remove',
                      icon: <TrashIcon />,
                      onClick: () => onRemoveWithdrawal(row),
                      className: 'error',
                    },
                  ];
                  return (
                    <TableRow
                      sx={
                        index + 1 === data.items.length
                          ? {
                              'th,td': { borderBottom: 'none' },
                            }
                          : undefined
                      }
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        sx={{ padding: '1rem 0' }}
                      >
                        <Box display="flex" sx={{ alignItems: 'center' }}>
                          <Box display="flex" alignItems="center" gap={1}>
                            {row._id}
                            <CopyButton value={row._id} />
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Chip
                          variant="outlined"
                          label={formatCurrency(
                            row.amount,
                            PaymentMethodsCurrencyMap[row.paymentMethod],
                          )}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {format(new Date(row.createdAt), 'hh:m MMM d, yyyy')}
                      </TableCell>
                      <TableCell>
                        {WITHDRAWAL_STATUS_MEDIA_MAP[status].icon || (
                          <StatusUnknownIcon />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">
                          <Box display="flex" alignItems="center" gap={1}>
                            {handleCardText(row.card)}
                            <CopyButton value={row.card} />
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell align="right" sx={{ paddingRight: 0 }}>
                        <ActionMenu
                          small
                          buttonIcon={<MoreVertIcon />}
                          menuItems={menuItems}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          page={page}
          totalItems={data.meta.totalItems}
          totalPages={data.meta.totalPages}
          onPageChange={onPageChange}
          rowsPerPage={data.meta.itemsPerPage}
        />
      </Paper>
    </Box>
  );
}
