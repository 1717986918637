import styled from '@emotion/styled';
import Drawer from '@mui/material/Drawer';

export const StyledDrawer = styled(Drawer)`
  z-index: 1099;

  .MuiPaper-root {
    border: none;
  }
`;
