import * as yup from 'yup';
import type { TFunction } from 'i18next';
import { formatCurrency } from 'tools';
import { checkIsUserNameExist } from 'services/api/user';
import { Currency } from 'services/api/types';
import { MAX_UAH_AMOUNT, MAX_USDT_AMOUNT } from 'view/constants';
import { PaymentMethodTypes } from 'types/users';

export const DonateValidationSchema = (
  t: TFunction,
  minimumAmount: number,
  currency: Currency | null,
) => {
  if (!currency) {
    return yup
      .object()
      .test('currency-required', t('Currency must be selected'), () => false);
  }
  const maxDonationAmount =
    currency === Currency.UAH ? MAX_UAH_AMOUNT : MAX_USDT_AMOUNT;
  return yup
    .object({
      message: yup
        .string()
        .trim()
        .typeError(t('Username must be a string'))
        .max(256, t('The message must not be longer than 256 characters.')),
      sponsorName: yup.string().trim(),
      voice: yup.string(),
      amount: yup
        .number()
        .typeError(t('Amount must be a number'))
        .required()
        .test(
          'minimumAmount',
          t('Minimum donation amount is', {
            amount: formatCurrency(minimumAmount, currency),
          }),
          (item = 0) => {
            return item >= minimumAmount;
          },
        )
        .test(
          'maxAmount',
          t('Maximum donation amount is', {
            amount: formatCurrency(maxDonationAmount, currency),
          }),
          (item = 0) => {
            return item <= maxDonationAmount;
          },
        ),
    })
    .required();
};

export const ExampleDonateValidationSchema = (t: TFunction) => {
  return yup
    .object({
      message: yup
        .string()
        .trim()
        .typeError(t('Username must be a string'))
        .max(256, t('The message must not be longer than 256 characters.')),
      sponsorName: yup.string().trim(),
      voice: yup.string(),
      amount: yup.number().typeError(t('Amount must be a number')).required(),
    })
    .required();
};

export const CardWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    card: yup
      .string()
      .trim()
      .required(t('Card number cannot be blank.'))
      .test(
        'cardLength',
        t('The card number should contain 16 digits.'),
        (item = '') => {
          return item.replace(/ /g, '').toString().length === 16;
        },
      ),
    amount: yup
      .number()
      .typeError(t('Amount must be a number'))
      .required(t('Amount cannot be blank.'))
      .min(1, t('The minimum withdrawn amount must be greater than 1.'))
      .test(
        'minimumAmount',
        t('The amount should be greater than 0.'),
        (item = 0) => {
          return item > 0;
        },
      ),
  });

export const IBANWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    iban: yup
      .string()
      .trim()
      .required(t('IBAN number cannot be blank'))
      .min(29, t('IBAN number should contain 29 symbols'))
      .max(29, t('IBAN number should contain 29 symbols')),
    amount: yup
      .number()
      .typeError(t('Amount must be a number'))
      .required(t('Amount cannot be blank.'))
      .min(1, t('The minimum withdrawn amount must be greater than 1.'))
      .test(
        'minimumAmount',
        t('The amount should be greater than 0.'),
        (item = 0) => {
          return item > 0;
        },
      ),
    okpo: yup.string().trim().required(t('EDRPOU cannot be blank')),
    accountName: yup
      .string()
      .trim()
      .required(t('Account name cannot be blank')),
  });

export const WhitepayWithdrawValidationSchema = (t: TFunction) =>
  yup.object({
    amount: yup
      .number()
      .typeError(t('Amount must be a number'))
      .required(t('Amount cannot be blank.'))
      .min(1, t('The minimum withdrawn amount must be greater than 1.'))
      .test(
        'minimumAmount',
        t('The amount should be greater than 0.'),
        (item = 0) => {
          return item > 0;
        },
      ),
    wallet: yup.string().trim().required(t('Wallet cannot be blank')),
  });

export const ChangeDonationAnimationFormatValidationSchema = yup
  .object({
    start: yup.string().required(),
    end: yup.string().required(),
    letters: yup.string().required(),
  })
  .required();

export const ChangeMinimumDonationAmountValidationSchema = (t: TFunction) =>
  yup.object().shape({
    minimumDonationAmount: yup.object().shape(
      Object.values(PaymentMethodTypes)
        .filter(
          (paymentMethod) =>
            paymentMethod !== PaymentMethodTypes.MONOBANKA &&
            paymentMethod !== PaymentMethodTypes.LIQPAY,
        )
        .reduce(
          (acc, paymentMethod) => ({
            ...acc,
            [paymentMethod]: yup
              .number()
              .typeError(t('Amount must be a number'))
              .required(t('Amount cannot be blank.'))
              .min(
                paymentMethod === PaymentMethodTypes.WHITEPAY ? 5 : 1,
                t(
                  paymentMethod === PaymentMethodTypes.WHITEPAY
                    ? 'The minimum withdrawn amount for USDT must be greater than 5.'
                    : 'The minimum withdrawn amount must be greater than 1.',
                ),
              )
              .test(
                'minimumAmount',
                t('The amount should be greater than 0.'),
                (item = 0) => item > 0,
              ),
          }),
          {},
        ),
    ),
  });

yup.addMethod(yup.object, 'uniqueProperty', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }

    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);

    const subOptions = options.slice(0, currentIndex);

    if (
      subOptions.some((option) => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }

    return true;
  });
});

export const ChangeDonationThresholdConfigValidationSchema = (t: TFunction) =>
  yup.object().shape({
    donationThresholdConfig: yup
      .array()
      .of(
        yup.object().shape({
          threshold: yup
            .number()
            .typeError(t('Amount must be a number'))
            .required(t('Amount cannot be blank.'))
            .positive(t('Amount must be positive')),
          pictureUrl: yup
            .string()
            .trim()
            .required(t('Please enter URL'))
            .test('validUrl', t('URL should be valid'), async (url) => {
              const isValid = await new Promise((resolve) => {
                const img = new Image();
                img.onload = () => resolve(true);
                img.onerror = () => resolve(false);
                img.src = url || '';
              });
              return !!isValid;
            }),
          soundUrl: yup
            .string()
            .trim()
            .required(t('Please enter URL'))
            .test('validUrl', t('URL should be valid'), async (url) => {
              const isValid = await new Promise((resolve) => {
                const audio = new Audio();
                audio.onloadedmetadata = () => resolve(true);
                audio.onerror = () => resolve(false);
                audio.src = url || '';
              });
              return !!isValid;
            }),
        }),
      )
      .test('unique-amounts', t('Amounts must be unique'), function (value) {
        const thresholds = value?.map(({ threshold }) => threshold);
        const duplicates = thresholds?.filter(
          (item, index) => thresholds.indexOf(item) !== index,
        );

        if (duplicates?.length) {
          return this.createError({
            path: `donationThresholdConfig.${thresholds?.lastIndexOf(
              duplicates[0],
            )}.amount`,
            message: t('Threshold must be unique'),
          });
        }

        return true;
      }),
  });

export const ChangeSocialNetworksValidationSchema = (t: TFunction) =>
  yup.object().shape({
    socialNetworks: yup.array().of(
      yup.object().shape({
        url: yup
          .string()
          .trim()
          .required(t('The link cannot be empty'))
          .url(t('The link is not valid')),
      }),
    ),
  });

export const ChangeContentCreatorNameValidationSchema = (t: TFunction) =>
  yup.object({
    contentCreatorName: yup
      .string()
      .trim()
      .typeError(t('Username must be a string'))
      .required(t('Username cannot be blank'))
      .min(3, t('Username must be at least 3 characters'))
      .max(64, t('Username must be at most 64 characters'))
      .test(
        'isUnique',
        t('Username should be unique'),
        async (contentCreatorName = '') => {
          if (!contentCreatorName) {
            return false;
          }
          try {
            const isExist = await checkIsUserNameExist(contentCreatorName);
            return !isExist;
          } catch (error) {
            return false;
          }
        },
      ),
  });

export const ChangeMinimumDonationVoiceOverValidationSchema = (t: TFunction) =>
  yup.object({
    minimumVoiceOverThreshold: yup
      .number()
      .typeError(t('Amount must be a number'))
      .required(t('Amount cannot be blank'))
      .test(
        'isValidThreshold',
        t('Threshold must be -1, 0, or a positive integer'),
        (value) =>
          value === -1 ||
          value === 0 ||
          (Number.isInteger(value) && (value as number) > 0),
      ),
  });

export const ChangeWhitepayWalletValidationSchema = (t: TFunction) =>
  yup.object({
    wallet: yup
      .string()
      .trim()
      .required(t('The wallet credentials cannot be empty')),
  });

export const ChangeWayforpayValidationSchema = (t: TFunction) =>
  yup.object({
    merchantAccount: yup
      .string()
      .trim()
      .required(t('Merchant account cannot be empty')),
    merchantSecretKey: yup
      .string()
      .trim()
      .required(t('Merchant secret key credentials cannot be empty')),
  });

export const ChangeGoalsValidationSchema = (t: TFunction) =>
  yup.object().shape({
    goals: yup.array().of(
      yup.object().shape({
        label: yup.string().trim().required(t('label cannot be empty')),
        start: yup
          .number()
          .typeError(t('Must be a number'))
          .required(t('Start is required'))
          .test(
            'is-smaller',
            t('Start must be smaller than Finish'),
            function (value) {
              const { finish } = this.parent;

              if (!value) {
                return false;
              }

              return value < finish;
            },
          ),
        finish: yup
          .number()
          .typeError(t('Must be a number'))
          .required(t('Finish is required'))
          .test(
            'is-greater',
            t('Finish must be greater than Start'),
            function (value) {
              const { start } = this.parent;

              if (!value) {
                return false;
              }

              return value > start;
            },
          ),
      }),
    ),
  });
