import {
  createContext,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { listDonations } from 'services/api/donations';
import { PaginatedResponse } from 'services/api/types';
import { DonationAnimationFormat, User } from 'types/users';
import { Donation } from 'types/donations';
import useAuth from 'hooks/auth';
import { getMe, getOBSUrl } from 'services/api/user';
import { Withdrawal } from 'types/withdrawal';
import { listWithdrawals } from 'services/api/withdrawals';

export type UserData = {
  isLoading: boolean;
  donationsData: PaginatedResponse<Donation>;
  withdrawalsData: PaginatedResponse<Withdrawal>;
  user: User;
  OBSUrl: string;
  donationAnimationFormat: DonationAnimationFormat;
};

export type UserContextType = UserData & {
  setUser: (user: User) => void;
  setUserData: Dispatch<SetStateAction<UserData>>;
  setDonationsData: (donations: PaginatedResponse<Donation>) => void;
  setWithdrawalsData: (withdrawals: PaginatedResponse<Withdrawal>) => void;
};

export const UserContext = createContext<UserContextType>({
  isLoading: true,
  donationsData: {} as PaginatedResponse<Donation>,
  withdrawalsData: {} as PaginatedResponse<Withdrawal>,
  user: {} as User,
  OBSUrl: '',
  donationAnimationFormat: {} as DonationAnimationFormat,
  setUser: () => {},
  setUserData: () => {},
  setDonationsData: () => {},
  setWithdrawalsData: () => {},
});

type Props = {
  children: ReactElement;
};

const initialData = {
  isLoading: true,
  user: {} as User,
  OBSUrl: '',
  donationAnimationFormat: {} as DonationAnimationFormat,
  donationsData: {} as PaginatedResponse<Donation>,
  withdrawalsData: {} as PaginatedResponse<Withdrawal>,
};

export const UserProvider = ({ children }: Props) => {
  const { isAuthenticated, setErrorMessage } = useAuth();
  const [userData, setUserData] = useState<UserData>(initialData);

  const setUser = (user: User = {} as User) =>
    setUserData((prevState) => ({ ...prevState, user }));

  const setDonationsData = (donationsData: PaginatedResponse<Donation>) =>
    setUserData((prevState) => ({
      ...prevState,
      donationsData: {
        ...prevState.donationsData,
        items: [...donationsData.items, ...prevState.donationsData.items],
        links: { ...prevState.donationsData.links, ...donationsData.links },
        meta: { ...prevState.donationsData.meta, ...donationsData.meta },
      },
    }));

  const setWithdrawalsData = (withdrawalsData: PaginatedResponse<Withdrawal>) =>
    setUserData((prevState) => ({
      ...prevState,
      withdrawalsData: {
        ...prevState.withdrawalsData,
        items: [...withdrawalsData.items, ...prevState.withdrawalsData.items],
        links: { ...prevState.withdrawalsData.links, ...withdrawalsData.links },
        meta: { ...prevState.withdrawalsData.meta, ...withdrawalsData.meta },
      },
    }));

  useEffect(() => {
    if (!isAuthenticated) {
      return setUserData({ ...initialData, isLoading: false });
    }

    const getCurrentUserData = async () => {
      try {
        setUserData((prevState) => ({ ...prevState, isLoading: true }));
        const [user, OBSUrl, donationsData, withdrawalsData] =
          await Promise.all([
            getMe(),
            getOBSUrl(),
            listDonations(),
            listWithdrawals(),
          ]);

        return setUserData({
          isLoading: false,
          donationsData,
          withdrawalsData,
          user,
          OBSUrl,
          donationAnimationFormat: user.donationAnimationFormat,
        });
      } catch (err) {
        if (err instanceof Error) {
          setErrorMessage(err.message);
        }
        return setUserData({
          isLoading: false,
          donationsData: {} as PaginatedResponse<Donation>,
          withdrawalsData: {} as PaginatedResponse<Withdrawal>,
          user: {} as User,
          OBSUrl: '',
          donationAnimationFormat: {} as DonationAnimationFormat,
        });
      }
    };

    getCurrentUserData();
  }, [isAuthenticated]);

  const value = {
    ...userData,
    setUser,
    setDonationsData,
    setWithdrawalsData,
    setUserData,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
