import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import { black40 } from 'view/theme/colors';
import Container from '@mui/material/Container';

export const StyledContainer = styled(Container)`
  margin-top: 4rem;
` as typeof Container;

export const StyledFormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const StyledFormActions = styled(Box)`
  display: flex;
  margin-top: 1.5rem;
  column-gap: 1rem;

  .MuiButton-root {
    &.cancel-button {
      color: ${black40};
    }
  }
`;
