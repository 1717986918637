import React from 'react';
import Box from '@mui/material/Box';
import { Donation } from 'types/donations';
import { formatCurrency, handleEventDate } from 'tools';
import { StyledDonationCard, StyledDonationMessageBar } from './styled';
import Typography from '@mui/material/Typography';
import useSocket from 'hooks/socket';

type DonationCardProps = {
  donation: Donation;
};

export default function DonationCard({ donation }: DonationCardProps) {
  const { socketIo } = useSocket();

  const onPlayClick = () => {
    if (socketIo) {
      socketIo.emit('repeat-donation', { donation });
    }
  };

  return (
    <StyledDonationCard onClick={onPlayClick}>
      <StyledDonationMessageBar>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="body1">
            {donation.sponsorName ? donation.sponsorName : 'Анонім'} -{' '}
            {formatCurrency(donation.amount, donation.currency)}
          </Typography>
          <Typography variant="body2" mr={2}>
            {handleEventDate(new Date(donation.createdAt))}
          </Typography>
        </Box>
        <Typography variant="body2">{donation.message}</Typography>
      </StyledDonationMessageBar>
    </StyledDonationCard>
  );
}
