import React from 'react';
import { StyledContainer } from '../DonationBook/styled';
import Typography from '@mui/material/Typography';
import PageHeader from 'view/components/PageHeader';
import Divider from '@mui/material/Divider';
import BackButton from 'view/components/BackButton';
import { useNavigate } from 'react-router-dom';
import Link from 'view/components/Link';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

export default function TermsPage() {
  const navigate = useNavigate();
  const siteUrl = process.env.REACT_APP_UI_URL || '';

  return (
    <StyledContainer component="main">
      <PageHeader
        title="Договір публічної оферти"
        startAdornment={<BackButton onClick={() => navigate(-1)} />}
      />

      <Typography variant="body2" mb={4}>
        Цей договір (надалі «Договір») є офіційною та публічною пропозицією
        Продавця укласти договір купівлі-продажу Товару, представленого на сайті{' '}
        <Link to={siteUrl} label={siteUrl} />.
        <br />
        <br />
        Даний договір є публічною офертою фізичної особи-підприємця у особі
        Богуцький Андрій Ігорович (надалі «Продавець»), реєстраційний номер
        платника податків – 3527516237, адреса реєстрації – 33024, Україна,
        Рівненська область, Рівненський район, місто Рівне, вул. Олександра
        Храпаченка, будинок 18. Відповідно до статті 633 Цивільного кодексу
        України, умови договору є однаковими для всіх покупців незалежно від їх
        статусу (фізична особа, юридична особа, фізична особа-підприємець) без
        надання переваги одному покупцю перед іншим. Шляхом укладення цього
        Договору покупець в повному обсязі приймає умови та порядок оформлення
        замовлення, оплати товару, повернення товару, відповідальності за
        недобросовісне замовлення та усі інші умови договору. Договір вважається
        укладеним з моменту натискання кнопки «Оплатити» (або кнопки зі схожою
        назвою, наприклад: «Підтвердити», «Надіслати», «Задонатити» та інші) на
        сторінці оформлення замовлення.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        1. Загальні положення
      </Typography>
      <Typography variant="body2" mb={4}>
        1.1. Цей Договір є публічною офертою (відповідно до ст.ст. 633, 641 і
        гл. 63 Цивільного кодексу України) і містить всі істотні умови
        організації купівлі-продажу дистанційним способом, тобто через Сервіс.
        <br />
        1.2. У відповідності до ст. 642 Цивільного Кодексу України повним і
        безсуперечним прийняттям умов публічного договору, тобто публічної
        оферти Сервісу є факт оформлення замовлення натисканням на кнопку
        «Оплатити» (або кнопки зі схожою назвою, наприклад: «Підтвердити»,
        «Надіслати», «Задонатити» та інші), оплати замовлення в розмірі 100% на
        умовах цієї угоди.
        <br />
        1.3. Публічна оферта також є прийнятою при реєстрації Покупця у Сервісі.
        <br />
        1.4. Укладаючи Договір, Покупець підтверджує, що цілком і повністю
        ознайомлений та згоден з його умовами та дає дозвіл на обробку своїх
        персональних даних з метою можливості виконання умов цього Договору,
        можливості проведення взаєморозрахунків. Дозвіл на обробку персональних
        даних діє протягом усього строку дії Договору. Крім цього, укладанням
        цього Договору, Покупець підтверджує, що йому повідомлено (без
        додаткового повідомлення) про права, встановлені Законом України «Про
        захист персональних даних», про цілі збору даних. Обсяг прав Покупця, як
        суб'єкта персональних даних відповідно до Закону України «Про захист
        персональних даних», йому відомий і зрозумілий.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        2. Терміни та визначення
      </Typography>
      <Typography variant="body2" mb={4}>
        2.1. «Сервіс» - сайт Продавця (<Link to={siteUrl} label={siteUrl} />
        ), створений для укладення договорів купівлі — продажу голосових
        повідомлень на підставі ознайомлення Покупця з запропонованим Продавцем
        функціоналу здійснення таких повідомлень, за допомогою мережі Інтернет,
        що виключає можливість безпосереднього ознайомлення Покупця з Товаром –
        дистанційним засобом продажу товару.
        <br />
        2.2. «Донат» — голосове повідомлення довільного змісту, яке може, але не
        обовʼязково містить: імʼя автора, власне повідомлення, спеціальний звук
        сповіщення, спеціальне зображення, спеціальний звук озвучування.
        Асортимент додаткових опцій представлений в Сервісі.
        <br />
        2.3. «Оформити замовлення» — рішення Покупця придбати донат, оформлений
        у Сервісі.
        <br />
        2.4. «Покупець» — будь-яка фізична особа, що використовує послуги Сайту,
        оплачує послуги на банківський Рахунок Продавця.
        <br />
        2.5. «Стример» — будь-яка фізична особа, що використовує Сервіс для
        озвучення Донатів на своїй прямій трансляції.
        <br />
        2.6. «Персональні дані» — будь-яка інформація, яка прямо чи
        опосередковано відноситься до певної особи, чи до особи, яка
        визначається.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        3. Предмет договору
      </Typography>
      <Typography variant="body2" mb={4}>
        3.1. За цим Договором Продавець зобов'язується надати Покупцю послуги у
        сфері програмного забезпечення, а Покупець зобов'язується прийняти
        зазначені послуги й оплатити їх в розмірі 100% вартості в порядку та на
        умовах, визначених цим Договором та законодавством України.
        <br />
        3.2. Надання послуг Продавцем за цим Договором здійснюється у
        відповідності з нормами чинного законодавства України та домовленостями
        Сторін. Продавець несе відповідальність за виконання умов договору перед
        Покупцем.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        4. Порядок оформлення замовлення
      </Typography>
      <Typography variant="body2" mb={4}>
        4.1. Покупець самостійно оформляє замовлення в Сервісі.
        <br />
        4.2. Покупець звертається до Продавця будь-яким зручним способом
        зв’язку, що опублікований у Сервісі та приймає умови зазначені в цьому
        Договорі.
        <br />
        4.3. Продавець, отримавши запит від Покупця, інформує його про умови
        цього Договору, отримує згоду на обробку персональних даних (у тому
        числі в усній формі) та надає реквізити банківського рахунку для сплати
        вартості послуг, зазначених у Сервісі.
        <br />
        4.4. Покупець розуміє, що здійснення оплати за послуги відповідно до
        цього Договору підтверджує його згоду на прийняття послуг, наданих
        Продавцем.
        <br />
        4.5. Покупець, сплативши за послуги, підтверджує прийняття умов цього
        Договору та отримує послуги, передбачені ним. У разі несплати за послуги
        Покупець автоматично відмовляється від цього Договору та від послуг, що
        надаються Продавцем.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        5. Вартість і порядок оплати замовлення
      </Typography>
      <Typography variant="body2" mb={4}>
        5.1. Повна вартість замовлення складається з вартості товару (зазначеної
        на сторінках Сервісу) і вартості додаткових опцій.
        <br />
        5.2. Покупець оплачує замовлення в розмірі 100% вартості за допомогою
        банківського переказу грошей на поточний рахунок Продавця, що вказаний в
        рахунку, в т.ч. за допомогою Інтернет-банкінгу.
        <br />
        5.3. Оплата послуг Продавця здійснюється за наступними реквізитами:
        <List dense>
          <ListItem>Отримувач ФОП Богуцький Андрій Ігорович</ListItem>
          <ListItem>ІПН 3527516237</ListItem>
          <ListItem>Р/р отримувача UA463220010000026008340144236</ListItem>
          <ListItem>Банк АТ «УНІВЕРСАЛ БАНК»</ListItem>
          <ListItem>
            Призначення платежу «За послуги по договору публічної оферти про
            надання послуг у сфері програмного забезпечення»
          </ListItem>
        </List>
        <br />
        5.4. Перелік можливих способів оплати обмежується способами оплати
        платіжних систем Wayforpay, Whitepay, Cryptomus та іншими, які
        використовуються у Сервісі.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        6. Доставка замовлення
      </Typography>
      <Typography variant="body2" mb={4}>
        6.1. Доставка замовлення Покупцю виконується силами Продавця за
        допомогою Сервісу шляхом розміщення замовлення на інтернет-трансляції,
        якщо інше не передбачене Договором або діючим законодавством.
        <br />
        6.5. Продавець не несе відповідальність за термін доставки замовлення,
        тому що вони залежать від дій третіх осіб (Стримерів).
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        7. Порядок повернення
      </Typography>
      <Typography variant="body2" mb={4}>
        7.1. Покупець має право звернутися до Продавця із запитом на повернення
        коштів протягом 14 днів з моменту отримання послуги.
        <br />
        7.2. Повернення коштів здійснюється у разі, якщо Продавець не виконав
        умови договору, або надані послуги не відповідають заявленим
        характеристикам.
        <br />
        Для ініціювання повернення коштів Покупець повинен звернутися до
        Продавця з запитом на електронну пошту{' '}
        <Link
          to="mailto:2donate.me@gmail.com"
          label="2donate.me@gmail.com"
        />{' '}
        із зазначенням причин повернення та підтвердженням оплати та реквізитами
        для відшкодування.
        <br />
        7.3. Продавець розглядає запит на повернення коштів протягом 14 днів з
        моменту його отримання і повідомляє Покупця про рішення.
        <br />
        7.4. У разі позитивного рішення про повернення коштів, Продавець
        повертає кошти на банківський рахунок Покупця протягом 14 днів з моменту
        прийняття рішення.
        <br />
        7.5. Повернення коштів здійснюється тільки за ті послуги, які не були
        надані або були надані неналежним чином. Послуги, що були надані
        належним чином та відповідно до умов договору, не підлягають поверненню.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        8. Права та обов'язки сторін
      </Typography>
      <Typography variant="body2" mb={4}>
        8.1. Покупець зобов'язаний:
        <br />
        <List dense>
          <ListItem>
            а) ознайомитись з інформацією про Товар, яка розміщена на Сервісі
            Продавця;
          </ListItem>
          <ListItem>б) самостійно оформити замовлення в Сервісі;</ListItem>
        </List>
        8.2. Покупець має право вимагати від Сервісу дотримання умов цього
        договору.
        <br />
        8.3. Сервіс зобов'язаний:
        <List dense>
          <ListItem>а) дотримувати умов цього договору;</ListItem>
          <ListItem>
            б) передати Покупцю товар у відповідності з оформленим замовленням
            та умовами цього договору;
          </ListItem>
          <ListItem>
            в) Сервіс не несе відповідальності, не може виступати в якості
            відповідача у суді і не відшкодовує збитки, що виникли у Покупця
            через дію чи бездіяльність третіх осіб.
          </ListItem>
        </List>
        8.4. Сервіс має право:
        <List dense>
          <ListItem>
            а) в односторонньому порядку призупинити надання послуг за цим
            Договором у випадку порушення Покупцем умов цього Договору.
          </ListItem>
        </List>
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        9. Відповідальність сторін
      </Typography>
      <Typography variant="body2" mb={4}>
        9.1. Сторони відповідають за невиконання або неналежне виконання умов
        цього Договору в порядку, передбаченому цим Договором і чинним
        законодавством України.
        <br />
        9.2. У випадку обставин непереборної сили, сторони звільняються від
        виконання умов цього Договору. Під обставинами непереборної сили для
        цілей цього Договору розуміються події, що мають надзвичайний,
        невідворотний непередбачуваний характер, які виключають або об'єктивно
        перешкоджають виконанню цього Договору, настання яких Сторони не могли
        передбачити й запобігти розумними мірами.
        <br />
        9.3. Сторона, що посилається на дію обставин непереборної сили повинна
        протягом п'яти календарних днів у письмовому вигляді за допомогою
        електронної пошти повідомити іншу сторону про настання таких обставин.
        <br />
        9.4. Якщо через дію обставин непереборної сили невиконання зобов'язань
        за цим Договором триває більше п'яти місяців, кожна із Сторін має право
        розірвати цей Договір в односторонньому порядку, письмово повідомивши
        про це іншу сторону.
        <br />
        9.5. Сторони докладають максимальних зусиль для вирішення будь–яких
        розбіжностей виключно шляхом переговорів.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        10. Права інтелектуальної власності
      </Typography>
      <Typography variant="body2" mb={4}>
        10.1. Всі компоненти Сервісу в цілому належать Продавцю й охороняються
        законодавством у сфері захисту прав інтелектуальної власності.
        <br />
        10.2 Використання в комерційних цілях будь-яких матеріалів або елементів
        Сервісу без письмового дозволу Продавця суворо заборонене.
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        11. Інші умови
      </Typography>
      <Typography variant="body2" mb={4}>
        11.1. Сервіс залишає за собою право в односторонньому порядку вносити
        зміни в цей Договір з попередньою публікацією його на сайті{' '}
        <Link to={siteUrl} label={siteUrl} />.
        <br />
        11.2. Сервіс не несе відповідальність за зміст і правдивість інформації,
        що надається Покупцем при оформленні замовлення.
        <br />
        11.3. Покупець несе відповідальність за достовірність вказаної при
        оформленні замовлення інформації.
        <br />
        11.4. Оплата Покупцем оформленого в Сервісі замовлення означає повну
        згоду Покупця з умовами Договору купівлі – продажу (публічної оферти
        Сервісу) і є датою укладення Договору купівлі – продажу між Продавцем і
        Покупцем.
        <br />
        11.5. Використання ресурсу Сервісу для перегляду товару, а також для
        оформлення замовлення є для Покупця безоплатним.
        <br />
        11.6. Власним акцептування Договору Покупець добровільно надає згоду на
        збір та обробку власних персональних даних у зареєстрованій базі
        Продавця «Контрагенти» з наступною метою: дані, що стають відомі
        Продавцю використовуватимуться в комерційних цілях, в тому числі для
        обробки замовлень на придбання товарів, отримання інформації про
        замовлення, надсилання телекомунікаційними засобами зв'язку (електронною
        поштою, мобільним зв'язком) рекламних та спеціальних пропозицій,
        інформації про акції, розіграші або будь-якої іншої інформації про
        діяльність магазину.
        <br />
        11.7. У випадку небажання отримувати розсилку, Покупець має право
        звернутися до Продавця, написавши заяву про відмову від отримання
        рекламних матеріалів, надіславши його на поштову або електронну адресу{' '}
        <Link to="mailto:2donate.me@gmail.com" label="2donate.me@gmail.com" /> .
      </Typography>
      <Divider sx={{ marginBottom: 4 }} />

      <Typography variant="h2" mb={2}>
        12. Термін дії цього договору
      </Typography>
      <Typography variant="body2" mb={4}>
        12.1. Цей договір набирає чинності з дня оформлення замовлення або
        реєстрації в Сервісі <Link to={siteUrl} label={siteUrl} /> і діє до
        виконання всіх умов договору.
      </Typography>
    </StyledContainer>
  );
}
