import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { black, white } from 'view/theme/colors';
import useTheme from 'hooks/theme';

export default function LogoIcon() {
  const { isDarkMode } = useTheme();

  const logoColor = isDarkMode ? white : black;

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" mr={1}>
        <svg
          width="17"
          height="25"
          viewBox="0 0 70 99"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 86V12H30.2273C38.1465 12 45.0758 13.5857 51.0152 16.7571C57.0253 19.8581 61.6919 24.1924 65.0152 29.76C68.3384 35.3276 70 41.741 70 49C70 56.259 68.3384 62.6724 65.0152 68.24C61.6919 73.8076 57.0253 78.1772 51.0152 81.3486C45.0758 84.4495 38.1465 86 30.2273 86H0ZM7.84848 79.2343H29.803C36.3788 79.2343 42.0707 77.9657 46.8788 75.4286C51.7576 72.821 55.5404 69.2619 58.2273 64.7514C60.9141 60.1705 62.2576 54.92 62.2576 49C62.2576 43.0095 60.9141 37.7591 58.2273 33.2486C55.5404 28.7381 51.7576 25.2143 46.8788 22.6771C42.0707 20.0695 36.3788 18.7657 29.803 18.7657H7.84848V79.2343Z"
            fill={logoColor}
          />
          <path d="M22 99V0H28V99H22Z" fill={logoColor} />
          <path d="M35 99V0H41V99H35Z" fill={logoColor} />
        </svg>
      </Box>
      <Typography sx={{ textDecoration: 'none' }}>2Donate</Typography>
    </Box>
  );
}
