import { Dispatch, SetStateAction } from 'react';
import { Donation } from 'types/donations';
import { Howl } from 'howler';
import { t } from 'i18next';
import { delay } from 'tools';

type AlertDonation = Donation & {
  alertUrl: string;
  voiceUrl?: string;
  isVoiceOverAllowed: boolean;
};

export default class DonationQueue {
  private queue: AlertDonation[] = [];
  private isProcessing: boolean = false;
  private alertUrl: Howl | null = null;
  private voiceUrl: Howl | null = null;
  private readonly setDonation: Dispatch<SetStateAction<Donation | null>>;
  private readonly setError: Dispatch<SetStateAction<string>>;

  constructor(
    setDonation: Dispatch<SetStateAction<Donation | null>>,
    setError: Dispatch<SetStateAction<string>>,
  ) {
    this.setDonation = setDonation;
    this.setError = setError;
  }

  addDonation(donation: AlertDonation) {
    this.queue.push(donation);
    if (!this.isProcessing) {
      this.processQueue();
    }
  }

  private async processQueue() {
    if (this.queue.length === 0) {
      this.alertUrl = null;
      this.voiceUrl = null;
      return;
    }

    const currentDonation = this.queue.shift();

    if (currentDonation && !this.isProcessing) {
      await this.announceDonation(currentDonation);
    }
  }

  errorCallback() {
    return setTimeout(() => this.setDonation(null), 5000);
  }

  stop() {
    this.alertUrl?.stop();
    this.voiceUrl?.stop();
    this.queue = [];
    this.isProcessing = false;
    this.setDonation(null);
  }

  getTTSUrl(donation: Donation, voiceUrl?: string) {
    if (voiceUrl) {
      return voiceUrl;
    }
    const ttsUrl = new URL(
      `${process.env.REACT_APP_API_URL}/api/donations/tts`,
    );
    ttsUrl.searchParams.set('sponsorName', donation.sponsorName);
    ttsUrl.searchParams.set('message', donation.message);
    ttsUrl.searchParams.set('voice', donation.voice);

    return ttsUrl.toString();
  }

  async announceDonation(donation: AlertDonation) {
    this.setDonation(donation);
    this.isProcessing = true;

    const ttsUrl = this.getTTSUrl(donation, donation?.voiceUrl);

    try {
      // Voiceless donation announce
      if (!donation.isVoiceOverAllowed) {
        await delay(5000);
        this.setDonation(null);
        this.isProcessing = false;
        // Delay for the donation animation
        await delay(1000);
        if (this.queue.length) {
          this.processQueue();
        }
      } else {
        this.alertUrl = new Howl({
          src: donation.alertUrl,
          format: 'mp3',
          html5: true,
          autoplay: true,
          onloaderror: () => this.errorCallback(),
          onplayerror: () => this.errorCallback(),
          onend: () => {
            this.voiceUrl = new Howl({
              src: ttsUrl,
              format: 'mp3',
              html5: true,
              autoplay: true,
              rate: 1.2,
              onloaderror: () => this.errorCallback(),
              onplayerror: () => this.errorCallback(),
              onend: async () => {
                this.setDonation(null);
                this.isProcessing = false;
                // Delay for the donation animation
                await delay(1000);
                if (this.queue.length) {
                  this.processQueue();
                }
              },
            });
            this.voiceUrl.play();
          },
        });

        this.alertUrl.play();
      }
    } catch (err) {
      this.setError(t('Error announcing donation'));
      this.errorCallback();
    }
  }
}
