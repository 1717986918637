import { request } from '../../request';
import { User } from 'types/users';
import { PaginatedResponse } from 'services/api/types';
import { Withdrawal } from 'types/withdrawal';

export const getUserInfo = async (userId: string): Promise<User> => {
  const { data } = await request({
    url: `/admin/users/${userId}`,
  });

  return data;
};

export const deleteUser = async (userId: string): Promise<void> => {
  await request({
    method: 'delete',
    url: `/admin/users/${userId}`,
  });

  return;
};

export const listUsers = async ({
  searchQuery = '',
  page = 1,
  orderBy = '',
  orderDirection = 'ASC',
  filters = {},
  url = '/admin/users',
}): Promise<PaginatedResponse<User>> => {
  const { data } = await request({
    url,
    params: {
      page,
      orderBy,
      orderDirection,
      ...(searchQuery.trim() && { searchQuery: searchQuery.trim() }),
      ...(page && { page }),
      ...(filters && { filters }),
    },
  });
  return data;
};

export const listWithdrawals = async (
  { searchQuery = '', page = 1, orderBy = '', orderDirection = 'ASC' },
  userId: string,
): Promise<PaginatedResponse<Withdrawal>> => {
  const { data } = await request({
    url: `/admin/users/${userId}/withdrawals`,
    params: {
      page,
      orderBy,
      orderDirection,
      ...(searchQuery.trim() && { searchQuery: searchQuery.trim() }),
      ...(page && { page }),
    },
  });
  return data;
};

export const removeUserWithdrawal = async (
  userId: string,
  withdrawalId: string,
): Promise<void> => {
  await request({
    method: 'delete',
    url: `/admin/users/${userId}/withdrawals/${withdrawalId}`,
  });

  return;
};

export const processUserWithdrawal = async (
  userId: string,
  withdrawalId: string,
): Promise<void> => {
  await request({
    method: 'post',
    url: `/admin/users/${userId}/withdrawals/${withdrawalId}`,
  });

  return;
};

export const updateUserWithdrawal = async (
  userId: string,
  withdrawalId: string,
  data: Partial<Withdrawal>,
): Promise<void> => {
  await request({
    method: 'put',
    url: `/admin/users/${userId}/withdrawals/${withdrawalId}`,
    data,
  });

  return;
};

export const updateUser = async (
  userId: string,
  update: Partial<User>,
): Promise<User> => {
  const { data } = await request({
    method: 'patch',
    url: `/admin/users/${userId}`,
    data: update,
  });

  return data;
};
