import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { groupItemsByDate } from 'tools';
import DonationCard from 'view/components/DonationCard';
import { Donation } from 'types/donations';
import Loader from 'view/components/Loader';
import { useTranslation } from 'react-i18next';

type DonationsListProps = {
  donations: Donation[];
  fetchNextDonations: () => void;
  allDonationsLoaded: boolean;
};

export default function DonationsList({
  donations,
  fetchNextDonations,
  allDonationsLoaded,
}: DonationsListProps) {
  const { t } = useTranslation();
  if (!donations?.length) {
    return <Typography variant="body2">{t('No donations yet.')}</Typography>;
  }

  const donationsGroupedByDate = groupItemsByDate(donations);

  const sortDonationsByDate = (donations: string[]) => {
    return [
      ...donations.filter((date) => date === 'today'),
      ...donations.filter((date) => date === 'yesterday'),
      ...donations.filter((date) => date === 'last week'),
      ...donations.filter(
        (date) =>
          date !== 'today' && date !== 'yesterday' && date !== 'last week',
      ),
    ];
  };

  const handleSummaryName = (date: string) => {
    if (['today', 'yesterday', 'last week'].includes(date)) {
      return date;
    }
    return format(new Date(date), 'MMMM, d');
  };

  return (
    <InfiniteScroll
      dataLength={donations.length}
      next={fetchNextDonations}
      hasMore={allDonationsLoaded}
      loader={
        <Box my={2}>
          <Loader />
        </Box>
      }
      style={{ overflow: 'visible' }}
    >
      <Box mb={5}>
        {sortDonationsByDate(Object.keys(donationsGroupedByDate))
          .sort((a, b) => new Date(b).getTime() - new Date(a).getTime())
          .map((dateName: string) => (
            <Box mb={2} key={dateName}>
              <Typography variant="subtitle1" mb={0.5}>
                {handleSummaryName(dateName)}
              </Typography>
              {donationsGroupedByDate[dateName]
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .map((donation) => (
                  <DonationCard key={donation._id} donation={donation} />
                ))}
            </Box>
          ))}
      </Box>
    </InfiniteScroll>
  );
}
