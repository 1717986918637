import { Currency } from 'services/api/types';
import { PaymentMethodTypes } from 'types/users';

export type Withdrawal = {
  _id: string;
  amount: number;
  currency: Currency;
  paymentMethod: PaymentMethodTypes;
  createdAt: Date;
  card: string;
  status: WithdrawalStatus;
};

export enum WithdrawalStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}
