export default function TimesIcon() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="5 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9099 11.9999L18.2099 7.70994C18.3982 7.52164 18.504 7.26624 18.504 6.99994C18.504 6.73364 18.3982 6.47825 18.2099 6.28994C18.0216 6.10164 17.7662 5.99585 17.4999 5.99585C17.2336 5.99585 16.9782 6.10164 16.7899 6.28994L12.4999 10.5899L8.20994 6.28994C8.02164 6.10164 7.76624 5.99585 7.49994 5.99585C7.23364 5.99585 6.97824 6.10164 6.78994 6.28994C6.60164 6.47825 6.49585 6.73364 6.49585 6.99994C6.49585 7.26624 6.60164 7.52164 6.78994 7.70994L11.0899 11.9999L6.78994 16.2899C6.69621 16.3829 6.62182 16.4935 6.57105 16.6154C6.52028 16.7372 6.49414 16.8679 6.49414 16.9999C6.49414 17.132 6.52028 17.2627 6.57105 17.3845C6.62182 17.5064 6.69621 17.617 6.78994 17.7099C6.8829 17.8037 6.9935 17.8781 7.11536 17.9288C7.23722 17.9796 7.36793 18.0057 7.49994 18.0057C7.63195 18.0057 7.76266 17.9796 7.88452 17.9288C8.00638 17.8781 8.11698 17.8037 8.20994 17.7099L12.4999 13.4099L16.7899 17.7099C16.8829 17.8037 16.9935 17.8781 17.1154 17.9288C17.2372 17.9796 17.3679 18.0057 17.4999 18.0057C17.632 18.0057 17.7627 17.9796 17.8845 17.9288C18.0064 17.8781 18.117 17.8037 18.2099 17.7099C18.3037 17.617 18.3781 17.5064 18.4288 17.3845C18.4796 17.2627 18.5057 17.132 18.5057 16.9999C18.5057 16.8679 18.4796 16.7372 18.4288 16.6154C18.3781 16.4935 18.3037 16.3829 18.2099 16.2899L13.9099 11.9999Z"
        fill="currentColor"
      />
    </svg>
  );
}
