import React, { useState } from 'react';
import CopySuccessIcon from 'view/components/icons/CopySuccess';
import { copyToClipboard } from 'tools';
import CopyIcon from 'view/components/icons/Copy';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

type CopyButtonProps = {
  value: string;
};

export default function CopyButton({ value }: CopyButtonProps) {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClick = () => {
    setIsCopied(true);
    copyToClipboard(value);

    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <Box>
      <IconButton color="primary" onClick={onCopyClick}>
        {isCopied ? <CopySuccessIcon /> : <CopyIcon />}
      </IconButton>
    </Box>
  );
}
