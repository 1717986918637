import React from 'react';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const StyledInput = styled(TextField)`
  & .MuiOutlinedInput-root,
  & .MuiInputLabel-root {
    font-size: 0.875rem;
    font-weight: 500;
  }

  & .MuiOutlinedInput-root {
    border-radius: 0.5rem;
  }
`;

type FormInputProps = {
  name: string;
  label: string;
  value?: string;
  error?: boolean;
  select?: boolean;
  inputProps?: object;
  control: Control<any, any>;
  rows: number;
  helperText?: React.ReactElement | string;
  variant?: 'filled' | 'outlined';
  type?: React.InputHTMLAttributes<unknown>['type'];
  fullWidth?: boolean;
  disabled?: boolean;
  maxSymbols?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputRef?: React.Ref<any>;
  small?: boolean;
  sx?: SxProps<Theme>;
};

export default function TextArea({
  variant = 'outlined',
  maxSymbols = 256,
  rows,
  inputProps,
  label,
  name,
  value,
  error,
  helperText,
  control,
  type,
  disabled,
  fullWidth = true,
  onChange,
  inputRef,
  select = false,
  sx = {},
  ...props
}: FormInputProps) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        return (
          <Box style={{ position: 'relative' }}>
            <StyledInput
              {...props}
              {...field}
              multiline
              rows={rows}
              name={field.name}
              id={name}
              type={type}
              error={error}
              variant={variant}
              inputProps={inputProps}
              margin="none"
              select={select}
              helperText={helperText}
              fullWidth={fullWidth}
              disabled={disabled}
              label={label}
              value={field.value}
              inputRef={inputRef}
              sx={sx}
            />
            <Box style={{ position: 'absolute', right: '1rem' }}>
              <Typography
                variant="subtitle1"
                sx={{ lineHeight: '16px', marginTop: '3px', fontWeight: 500 }}
              >
                {field.value.length}/{maxSymbols}
              </Typography>
            </Box>
          </Box>
        );
      }}
    />
  );
}
