import styled from '@emotion/styled';
import MuiAvatar from '@mui/material/Avatar';
import { black4, black71, primary } from 'view/theme/colors';
import useTheme from 'hooks/theme';

export const StyledAvatar = styled(MuiAvatar)(() => {
  const { isDarkMode } = useTheme();

  return {
    backgroundColor: primary,
    width: '3.5rem',
    height: '3.5rem',
    border: `1px solid ${isDarkMode ? black71 : black4}`,
  };
});

type AvatarProps = {
  contentCreatorName: string;
  size?: number;
  src?: string;
};

export default function Avatar({
  contentCreatorName,
  size = 3.5,
  src,
}: AvatarProps) {
  const sizeInRem = `${size}rem`;
  const initials = <>{contentCreatorName?.[0]}</>;

  return (
    <StyledAvatar
      src={src}
      alt={contentCreatorName}
      style={{
        height: sizeInRem,
        width: sizeInRem,
        fontSize: `${size / 3}rem`,
      }}
    >
      {initials}
    </StyledAvatar>
  );
}
